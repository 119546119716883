import React, { useEffect, useState } from 'react'

import './Product.scss';
import ic_cart_white from '../../assets/images/icons/ic_cart_white.png'
import favoriteIcon from '../../assets/images/icons/favoriteIcon.png'
import selectedWishlist from '../../assets/images/icons/favoriteIconselected.png'
import ic_speedy from '../../assets/images/icons/ic_speedy.svg'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { addToCart, toggleWishlist } from '../../services/apiCalls';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getCartPage } from '../../redux/cartRedux';
import { useNavigate } from 'react-router-dom';
import { setLoginPopupStatusThunk } from '../../redux/login';
import { Spinner } from 'react-activity';

function Product({ productData, handle = "" }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useStore();
    let [qty, setQty] = useState(0);
    let [wishlist, setWishlist] = useState(0);
    const [addLoader, setAddLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const { cartpageBilling, cartpageItems } = useSelector((state) => state.cart);
    const addToCartClick = async (e, product) => {
        e.stopPropagation();
        setAddLoader(true)
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "1",
            "flag": "1"
        }
        const addtoCart = await addToCart(data)
        if (addtoCart.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage());
            setQty(1)
            toast.success("Product Added to Cart", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,

            });
            window.dataLayer.push(
                {
                    'event': 'add_to_cart',
                    'ecommerce': {
                        "currency": "AED",
                        "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                        "items": cartpageItems,
                    }
                }
            );
        } else {
            setAddLoader(false)
            toast.error(addtoCart.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const increamentCartAction = async (e, product) => {
        e.stopPropagation();
        setAddLoader(true)
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "1",
            "flag": "1"
        }
        const qtyIncrement = await addToCart(data)
        qty = qty + 1
        if (qtyIncrement.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage());
            setQty(qty)
        } else {
            setAddLoader(false)
            toast.error(qtyIncrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    };
    const decreamentCartAction = async (e, product) => {
        e.stopPropagation();
        setRemoveLoader(true)
        const data = {
            "product_id": product.id,
            "qty": 1,
            "mrp": product.mrp,
            "selling_price": product.selling_price,
            "action": "2",
            "flag": "1"
        }
        const qtyDecrement = await addToCart(data)
        qty = qty - 1
        if (qtyDecrement.data.code === 1) {
            dispatch(getCartPage());
            setQty(qty)
            setRemoveLoader(false)
            if (qty === 0) {
                toast.error("Product Removed from Cart", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,

                });
                window.dataLayer.push(
                    {
                        'event': 'remove_from_cart',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                            "items": cartpageItems,
                        }
                    }
                );
            }
        } else {
            setRemoveLoader(false)
            toast.error(qtyDecrement.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }

    };
    const wishlistClick = async (e, id) => {
        e.stopPropagation();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            const data = {
                "product_id": id
            }
            const addWishlist = await toggleWishlist(data)
            if (addWishlist.data.code === 1) {
                setWishlist(!wishlist)
                toast.success(addWishlist.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,

                });
            } else {
                toast.error(addWishlist.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,

                });
            }
        }

    }
    useEffect(() => {
        setQty(parseInt(productData.qty, 10))
        setWishlist(parseInt(productData.is_user_wishlist, 10))
    }, []);
    const gotoProductDetailsPage = (e, pageName = '') => {
        e.preventDefault();
        navigate(handle + "/" + pageName)
    }
    return (
        <>
            <div className="product_item" onClick={(e) => { gotoProductDetailsPage(e, productData.handle) }}>
                <div className='product'>
                    <div className='top_section'>
                        {
                            productData.product_discount !== "0" &&
                            <div className='discount_label'>
                                <span>{productData.product_discount}% OFF</span>
                            </div>
                        }
                        {
                            productData.available_offer !== null &&
                            <div className='discount_label'>
                                <span>{productData.available_offer.offer_code}</span>
                            </div>
                        }
                        <div className='wishlist ml-auto' onClick={(e) => {
                            wishlistClick(e, productData.id)
                        }}>
                            <img
                                alt=""
                                src={wishlist ? selectedWishlist : favoriteIcon}
                                width="26"
                                height="auto"
                                className="d-inline-block align-end"
                                style={{ filter: wishlist && "unset", }}
                            />
                        </div>
                    </div>
                    {productData.product_images.map((element, index) => {
                        return (
                            <div className="product_image" key={index}>
                                <img src={element.file} alt="Sample Image" height="210" />
                            </div>
                        );
                    })}
                    <div className="product_detail">
                        {
                            productData.is_speedy === 1 &&
                            <div className='speedy'>
                                <img
                                    alt=""
                                    src={ic_speedy}
                                    width="60"
                                    height="auto"
                                    className="d-inline-block align-end"
                                />
                            </div>
                        }
                        <span className='product_name'>{productData.name}</span>
                        <div className='product_price_cart'>
                            <div className="product_price_wrapper">
                                <div className='price'>
                                    <strong>AED {productData.selling_price}</strong>
                                    {
                                        parseFloat(productData.mrp.replace(",", "")) > parseFloat(productData.selling_price.replace(",", "")) &&
                                        <p className='mrp font-light line-through'>AED {productData.mrp}</p>
                                    }
                                </div>
                            </div>
                            {
                                qty === 0 ? <div className='actions_wrapper'>
                                    {
                                        addLoader ? <Spinner /> : <button
                                            name="Add to cart"
                                            className="addtocart"
                                            onClick={(e) => {
                                                addToCartClick(e, productData)
                                            }}
                                        >
                                            <span className='sr-only'>
                                                Add to cart
                                            </span>
                                            <img
                                                alt=""
                                                src={ic_cart_white}
                                                width="24"
                                                height="auto"
                                                className="d-inline-block align-end max-md:w-4"
                                            />
                                        </button>
                                    }
                                </div> : <div className='flex'>
                                    {
                                        removeLoader ? <Spinner /> : <button
                                            className='rounded-full w-7 h-7 max-sm:w-6 max-sm:h-6 bg-transparent border-black border-1 p-0 text-base text-black inline-flex items-center justify-center'
                                            onClick={(e) => {
                                                decreamentCartAction(e, productData)
                                            }}>-
                                        </button>
                                    }
                                    <p className='inline-flex items-center justify-center h-7 w-7 max-sm:w-5 max-sm:h-6 text-center'>{qty}</p>
                                    {
                                        addLoader ? <Spinner /> : <button
                                            className='rounded-full w-7 h-7 max-sm:w-6 max-sm:h-6 bg-transparent border-black border-1 p-0 text-base text-black inline-flex items-center justify-center'
                                            onClick={(e) => {
                                                increamentCartAction(e, productData)
                                            }}>+
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product;
