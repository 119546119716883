import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCategoryApi,
  getAllCategoryProducts,
  getCategoryCollection,
} from "../services/apiCalls";

export const getCategory = createAsyncThunk(
  "Category/getCategory",
  async (category) => {
    const res = await getCategoryCollection(category.handle, category.id);
    return res.data;
  }
);
export const getCategoryProduct = createAsyncThunk(
  "Category/getCategoryProduct",
  async (requestData) => {

    const res = await getAllCategoryProducts(requestData);
    return res.data;
  }
);
export const getCategoryProductMore = createAsyncThunk(
  "Category/getCategoryProductMore",
  async (requestData) => {

    const res = await getAllCategoryProducts(requestData);
    return res.data;
  }
);
export const getAllCategory = createAsyncThunk(
  "Category/getAllCategory",
  async () => {
    const res = await getAllCategoryApi();
    return res.data;
  }
);

const INITIAL_STATE = {
  categoryData: [],
  categoryBannerImageData: [],
  allCategoryList: [],
  loader: false,
  categoryProducts: [],
  loadmore: false,
};

const categorySlice = createSlice({
  name: "category",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state, action) => {
        console.log("pendning");
        state.loader = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        if (action.payload.code === 1) {
          console.log("payload", action.payload);
          state.categoryData = action.payload.category_data_list;
          state.categoryBannerImageData = action.payload.category_banner_image;
        } else {
          state.categoryData = [];
          state.categoryBannerImageData = [];
        }
        state.loader = false;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.loader = false;
        console.log("error api issue");
      })
      .addCase(getCategoryProduct.pending, (state, action) => {
        console.log("pendning");
      })
      .addCase(getCategoryProduct.fulfilled, (state, action) => {
        if (action.payload.code === 1) {
          if (action.payload.result.length === 24) {
            state.loadmore = true;
          }
          else {
            state.loadmore = false;
          }

          state.categoryProducts = action.payload.result;
        }
        else {
          state.categoryProducts = [];
        }
      })
      .addCase(getCategoryProduct.rejected, (state, action) => {
        console.log("error api issue");
      })
      .addCase(getCategoryProductMore.pending, (state, action) => {
        console.log("pendning");
      })
      .addCase(getCategoryProductMore.fulfilled, (state, action) => {
        if (action.payload.code === 1) {
          if (action.payload.result.length === 24) {
            state.loadmore = true;
          }
          else {
            state.loadmore = false;
          }

          state.categoryProducts.push(...action.payload.result);
        }
        else {
          if (state.categoryProducts.length === 0) {

            state.categoryProducts = [];
          }
        }
      })
      .addCase(getCategoryProductMore.rejected, (state, action) => {
        console.log("error api issue");
      })
      .addCase(getAllCategory.pending, (state, action) => {
        console.log("pendning");
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        if (action.payload.code === 1) {
          console.log("payload", action.payload);
          state.allCategoryList = action.payload.result;
        } else {
          state.allCategoryList = [];
        }
        console.log("completed");
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        console.log("error api issue");
      });
  },
});

export default categorySlice.reducer;
