import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button, Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getOrderDetails, getAllReturnReason} from "../../redux/order";
import Modal from "react-bootstrap/Modal";
import {FaStar} from "react-icons/fa";
import {toast} from "react-toastify";
import {customerOrderCancel} from "../../redux/profile";
import {cancelOrderApiCall, createOrderRetunApi} from '../../services/apiCalls';
import {TbPhotoPlus} from 'react-icons/tb';
import {MdClear} from 'react-icons/md';

function ViewOrder() {
    const {state} = useLocation();
    const dispatch = useDispatch();
    const {orderDetails, orderProducts, returnReason} = useSelector((state) => state.order);
    // const [returnProductList, setReturnProductList] = useState(Array(orderProducts !== undefined ? orderProducts.length : 0).fill({ no: -1 }));
    const [returnProductList, setReturnProductList] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        const data = {
            "order_id": state.orderId
        }
        dispatch(getOrderDetails(data))
        dispatch(getAllReturnReason())
    }, [])

    useEffect(() => {
        if (orderProducts !== undefined) {
            setReturnProductList(Array(orderProducts.length).fill({no: -1}))
        }
    }, [orderProducts])

    const isTab = window.innerWidth <= 991;

    const [returnPop, setReturnPop] = useState(false);

    const cancelorderButton = async (orderId, flag) => {
        const paramData = {
            "order_id": orderId,
            "flag": flag,
            "from": "order"
        }

        const cancelOrder = await cancelOrderApiCall(paramData)
        if (cancelOrder.data.code === 1) {
            const data = {
                "order_id": state.orderId
            }
            dispatch(getOrderDetails(data))
            toast.success("Order Cancelled Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            toast.error(cancelOrder.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const returnOrderButtonClick = async () => {
        let itemList = []
        returnProductList.map((product) => {
            if (product.no != -1) {
                itemList.push(product)
            }
        })
        const requestData = {
            "order_id": orderDetails.id,
            "items": itemList,
        };
        const createOrderReturn = await createOrderRetunApi(requestData)
        if (createOrderReturn.data.code === 1) {
            toast.success(createOrderReturn.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            const data = {
                "order_id": state.orderId
            }
            dispatch(getOrderDetails(data))
            setReturnProductList([])
            setReturnPop(false)
        } else {
            toast.error(createOrderReturn.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const handleCheckboxChange = (index, value) => {
        const updatedReturnProductList = [...returnProductList];
        if (value === true) {
            updatedReturnProductList[index] = {
                no: index,
                deliveryId: orderProducts.deliveryId || "",
                productId: orderProducts[index].productId || "",
                reasonId: '1',
                reason: reasonFromId('1'), // Implement reasonFromId
                qty: 1,
            };
        } else {
            updatedReturnProductList[index] = {
                no: -1,
                productId: "",
                reason: null,
                reasonId: null,
                proof: null,
                qty: null,
                deliveryId: null,
            };
        }
        setReturnProductList(updatedReturnProductList);
    };

    const handleReasonChange = (index, reasonId) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            reasonId,
            reason: reasonFromId(reasonId), // Implement reasonFromId
        };
        setReturnProductList(updatedReturnProductList);
    };

    const handleQtyChange = (index, qty) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            qty,
        };
        setReturnProductList(updatedReturnProductList);
    };

    const uploadProductImage = (index, e) => {
        if (e !== null) {
            if (e.target.files && e.target.files.length > 0) {
                const updatedReturnProductList = [...returnProductList];
                updatedReturnProductList[index] = {
                    ...returnProductList[index],
                    proof: e.target.files[0],
                };
                setReturnProductList(updatedReturnProductList);
            }
        } else {
            const updatedReturnProductList = [...returnProductList];
            updatedReturnProductList[index] = {
                ...returnProductList[index],
                proof: e,
            };
            setReturnProductList(updatedReturnProductList);
        }
    }

    const handleCommentChange = (index, value) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            reason: value,
        };
        setReturnProductList(updatedReturnProductList);
    };

    const reasonFromId = (id) => {
        const index = returnReason.findIndex(element => element.id === id);
        return index !== -1 ? returnReason[index].reasonCode : '';
    }

    return (
        <Container className='max-md:px-0'>
            {isTab ? (
                <div className='mob my-order-details-wrapper'>
                    {
                        <div className='my-order-details-container'>
                            {

                                orderDetails.delivery_date ?
                                    <div>
                                        <div className='order-id-wrapper'>
                                            <div className='order-id'>
                                                <p className='text-[25px] font-black'>DOC{orderDetails.id}</p>
                                                <p className='text-[15px] font-light'>Placed
                                                    On {orderDetails.order_date}</p>
                                            </div>
                                        </div>
                                        <div className='order-details'>
                                            <div className='order-details-info'>
                                                <div className='order-summary'>
                                                    <p className='pb-2 text-start text-xl font-medium border-b max-md:px-3 max-md:text-base flex justify-between'>
                                                        <span>Order Number#</span>
                                                        <span
                                                            className='text-sm font-medium'>DOC{orderDetails.id}</span>
                                                    </p>
                                                    <div
                                                        className='fieldset text-sm py-2 border-solid border-[#e5e7eb] border-x-0 border-y-0 border-b md:px-4 mb-2 max-md:px-3'>
                                                        <div className='field'>
                                                            <label>Subtotal </label>
                                                            <span> AED {orderDetails.items_total}</span>
                                                        </div>
                                                        <div className='field'>
                                                            <label>Shipping </label>
                                                            <span> AED {orderDetails.shipping_charge}</span>
                                                        </div>
                                                    </div>
                                                    <strong
                                                        className='font-medium text-xl max-md:px-3 max-md:text-sm max-md:flex max-md:justify-between max-md:mb-10'>Total
                                                        Amount <span>{orderDetails.total_payable}</span></strong>
                                                </div>
                                                <div className='delivery-Address'>
                                                    <h3>Address</h3>
                                                    <p>{orderDetails.f_name} {orderDetails.l_name}<br/> {orderDetails.building_name},{orderDetails.full_address}
                                                    </p>
                                                    <p className='mobile'>{orderDetails.mobile}</p>
                                                </div>
                                            </div>
                                            <div className='order-items'>
                                                <div className='mt-3 mb-4 text-sm text-gray-500'>Purchace Details</div>
                                                {
                                                    orderProducts.map((productOrdered, productindex) => (
                                                        <div className='order-item' key={productindex}>
                                                            <div className='ordered-product-detail'>
                                                                {
                                                                    productOrdered.product_images.map((productImage) => (
                                                                        <div
                                                                            className='ordered-product-image-detail'>
                                                                            <img src={productImage.file} alt=''
                                                                                 width={102}
                                                                                 height={102}/>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='ordered-product-detail-info'>
                                                                <p className='name'>{productOrdered.product_name}</p>
                                                                <p className='price'>AED {productOrdered.total_price}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {

                                                <div className='flex justify-between order-status-bottom'
                                                >
                                                    <p className={orderDetails.order_status}>{orderDetails.order_status}</p>
                                                    {
                                                        orderDetails.can_order_cancel === 1 &&
                                                        <div>
                                                            <a className='cancleorder'
                                                               onClick={() => cancelorderButton(orderDetails.id, "1")}>cancel
                                                                Order</a>
                                                        </div>
                                                    }
                                                    {
                                                        orderDetails.is_returnable === 1 &&
                                                        <div>
                                                            <a className='cancleorder'
                                                               onClick={() => setReturnPop(true)}>Return Order</a>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                <Modal className='select-return-products' show={returnPop}
                                                       fullscreen={true}
                                                       onHide={() => setReturnPop(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Select Return Products</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className='fieldset'>
                                                            <ul className='order-items'>
                                                                {
                                                                    orderProducts.map((product, i) => (
                                                                        <li className='order-item flex flex-column gap-2 items-start'
                                                                            key={i}>
                                                                            <div className='order-item-info w-full flex gap-2'>
                                                                                <img
                                                                                    src={(product.product_images !== undefined && product.product_images.length > 0) ? product.product_images[0].file : ''}
                                                                                    alt=""
                                                                                    className='w-16 h-16 rounded-xl'
                                                                                />
                                                                                <div className='order-item-details block w-full'>
                                                                                    <div
                                                                                        className='flex flex-column pr-5 relative gap-2'>
                                                                                        <span>{product.product_name}</span>
                                                                                        <span
                                                                                            className='font-semibold'>AED {product.total_price || ''}</span>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={returnProductList.some(item => item.no === i)}
                                                                                            onChange={e => handleCheckboxChange(i, e.target.checked)}
                                                                                            className='mt-2 absolute top-0 right-0'
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {returnProductList.some(item => item.no === i) && (
                                                                                <div
                                                                                    className='w-full'>
                                                                                    <div
                                                                                        className='flex flex-wrap justify-between'>
                                                                                        <select
                                                                                            value={returnProductList[i].reasonId}
                                                                                            onChange={e => handleReasonChange(i, e.target.value)}
                                                                                            className='bg-transparent p-2 border w-full rounded-md text-xs'
                                                                                        >
                                                                                            {returnReason.map((reason) => (
                                                                                                <option
                                                                                                    className='text-xs'
                                                                                                    key={reason.id}
                                                                                                    value={reason.id}>
                                                                                                    {reason.reason_code}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                        <div
                                                                                            className='inline-flex items-center gap-2 max-md:mt-4 p-2'>
                                                                                            <span
                                                                                                className='text-xs'>Qty</span>
                                                                                            <select
                                                                                                value={returnProductList[i].qty}
                                                                                                onChange={e => handleQtyChange(i, e.target.value)}
                                                                                                className='w-auto min-w-[80px] bg-transparent px-2 text-xs'
                                                                                            >
                                                                                                {Array.from({length: product.qty}).map((_, indexQty) => (
                                                                                                    <option
                                                                                                        className='text-xs'
                                                                                                        key={indexQty}
                                                                                                        value={indexQty + 1}>
                                                                                                        {`${indexQty + 1}`}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                        {returnProductList[i].reasonId === '2' || returnProductList[i].reasonId === '3' ? (
                                                                                            returnProductList[i].proof ? (
                                                                                                <div className='inline-flex items-center gap-1 max-md:mt-4'>
                                                                                                    <span className='text-xs text-gray-500 max-w-[140px] whitespace-nowrap text-ellipsis overflow-hidden'>{URL.createObjectURL(returnProductList[i].proof)}</span>
                                                                                                    <button
                                                                                                        className='bg-transparent p-0 border-none focus:border-none text-xl text-gray-400 h-auto'
                                                                                                        onClick={() => uploadProductImage(i, null)}>
                                                                                                        <span
                                                                                                            className='sr-only'>Clear</span>
                                                                                                        <MdClear/>
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <label
                                                                                                    htmlFor='file-upload-product-pic'
                                                                                                    className='add-photo w-full h-auto ml-auto relative mt-3 overflow-hidden cursor-pointer bg-transparent border-none text-xl text-gray-400 flex items-center gap-2'>
                                                                                            <span
                                                                                                className='text-xs'>Add a Photo</span>
                                                                                                    <TbPhotoPlus/>
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        id="file-upload-product-pic"
                                                                                                        name="file_upload_profile_pic"
                                                                                                        className='opacity-0 absolute inset-0 z-0 w-full h-full cursor-pointer'
                                                                                                        onChange={(e) => {
                                                                                                            uploadProductImage(i, e);
                                                                                                        }}
                                                                                                    />
                                                                                                </label>
                                                                                            )
                                                                                        ) : null}
                                                                                        {returnProductList[i].reasonId === '5' && (
                                                                                            <textarea
                                                                                                type="text"
                                                                                                value={returnProductList[i].reason}
                                                                                                onChange={e => handleCommentChange(i, e.target.value)}
                                                                                                placeholder="Comment"
                                                                                                className='comment max-md:mt-4 rounded-md text-xs placeholder:text-xs w-full'
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                        <button className='w-full mt-3 bg-black border-black'
                                                                onClick={returnOrderButtonClick}>Return Order
                                                        </button>
                                                    </Modal.Body>
                                                </Modal>
                                            }
                                        </div>
                                    </div> : <></>
                            }
                            <div>

                            </div>
                        </div>
                    }
                </div>
            ) : (
                <div>
                    <div className='font-medium mb-4'>Order Id: {orderDetails.order_no}</div>
                    <div className='font-medium sr-only'>Subtotal:AED {orderDetails.items_total}</div>
                    <div className='font-medium sr-only'>shipping: AED {orderDetails.shipping_charge}</div>
                    {orderProducts.map((product) => {
                        return (
                            <>
                                <div className='flex items-center'>
                                    <div className='sr-only'>delivery_id:{product.delivery_id}</div>
                                    {product.product_images.map((image) => {
                                        return (
                                            <>
                                                <div>
                                                    <img
                                                        alt=''
                                                        src={image.file}
                                                    />
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div className='text-base font-medium'>{product.product_name}</div>
                                </div>
                            </>
                        );
                    })}
                </div>
            )}
        </Container>
    )
}

export default ViewOrder