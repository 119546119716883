import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  getWishlistProductAPI } from "../services/apiCalls";

export const getwishlist = createAsyncThunk("Wishlist/getWishlist", async () => {
    const res = await getWishlistProductAPI();
    return res.data;
})

const INITIAL_STATE = {
    wishlist: [],
    loader: false,
}

const wishSlice = createSlice({
    name: "wishlist",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getwishlist.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getwishlist.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.wishlist = action.payload.result;
                    
                }
                else{
                    state.wishlist =[]
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getwishlist.rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue")
            })
    }
})

export default wishSlice.reducer;