import React, { useState } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import BMI from '../BMI/BMI';

import './UserEngagements.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useStore } from 'react-redux';
import { setLoginPopupStatusThunk } from '../../../redux/login';



function UserEngagements({ userEngagementData }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useStore();
    const [showBMIPopup, setShowBMIPopup] = useState(false);
    const handleDataReceived = (data) => {
        handleClick();
    };
    const handleClick = () => {
        setShowBMIPopup(!showBMIPopup);
    };
    const getUrl = (e,data) => {
        e.preventDefault()
        if (data === "Upload Prescription") {
            if (store.getState().login.isUserLoggedIn === false) {
                dispatch(setLoginPopupStatusThunk(true));
            } else {
                navigate('/uploadPage');
            }
          
        }
        if (data === "Health Advice") {
            navigate('/blogs');
        }
    }
    return (
        <Container fluid>
            <Container>
                <Row className='user_engagements items'>
                    {userEngagementData.map((element, index) => {
                        if (element.name === "Dosage Reminder") {
                            return
                        }
                        if (element.name === "BMI Check") {
                            return (
                                <Col className='item cursor-pointer' xs={3} md={3} key={index}>
                                    <a onClick={handleClick}>
                                        <Image
                                            src={element.image}
                                            width="120"
                                            height="auto"
                                            roundedCircle
                                        />
                                        <Image
                                            src={element.hover_image}
                                            width="120"
                                            height="auto"
                                            className='hover_image'
                                            roundedCircle
                                        />
                                        <span>{element.name}</span>
                                    </a>
                                </Col>
                            );
                        }
                        return (
                            <Col className='item cursor-pointer' xs={3} md={3} key={index}>

                                <Link to={element.name === "Pharmacist on Call" && "tel:80036242"} onClick={
                                    (e) => {
                                        if(element.name !== "Pharmacist on Call"){
                                            getUrl(e,element.name)
                                        }
                                    }}>
                                    <Image
                                        src={element.image}
                                        width="120"
                                        height="auto"
                                        roundedCircle
                                    />

                                    <Image
                                        src={element.hover_image}
                                        width="120"
                                        height="auto"
                                        className='hover_image'
                                        roundedCircle
                                    />
                                    <span>{element.name}</span>
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
                {showBMIPopup && <BMI onDataReceived={handleDataReceived} />}
            </Container>
        </Container>
    );
}

export default UserEngagements;

