import React from 'react'
/* import Product from '../ProductListing/Product/Product' */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHref } from 'react-router-dom';
import { getHandleDetailsThunk } from './../../redux/handleSlug';

import NotFoundPage from '../NotFound/NotFound';

import './HandlePage.scss';
import ProductListingPage from '../ProductListingPage/ProductListingPage';
import ProductDetailsPage from '../ProductDetailsPage/ProductDetailsPage';
import CategoryPage from '../CategoryPage/CategoryPage';
import MainLoader from '../Common/Loaders/MainLoader';


function HandlePage() {
    const paramName = useHref();
    const myArray = paramName.split("/");
    const targetHandle = myArray[myArray.length - 1];
    const dispatch = useDispatch();
	const { handleData, handleTypeData, loader } = useSelector((state) => state.handleSlug);
	useEffect(() => {
		dispatch(getHandleDetailsThunk(targetHandle))
		return
	}, [paramName]);
    const handleSlugComponentResolver = () => {
        if (
            (handleData !== undefined) && (Object.keys(handleData).length > 0) 
            && (handleTypeData !== undefined) && (Object.keys(handleTypeData).length > 0)
        ) {
            switch(handleTypeData.code) {
                case 'categories':
                    return (
                        <CategoryPage category={{ id: handleData.id, handle: targetHandle }} />
                    )
                case 'sub_categories':
                case 'featured_list':
                case 'collections':
                case 'brands':
                    return (
                        <ProductListingPage subCategory={{ id: handleData.id, handle: targetHandle, type: handleTypeData.code }} />
                    )
                case 'products':
                    return (
                        <ProductDetailsPage product={{ id: handleData.id, handle: targetHandle }} />
                    )
                default:
                    return (
                        <NotFoundPage />
                    )
            }
        } else {
            return (
                <NotFoundPage />
            )
        }
    };
  return (
    <>
        { loader ? <MainLoader size={50}/> : 
        handleSlugComponentResolver() }
    </>
  );
}

export default HandlePage;

