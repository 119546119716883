import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

import { getEmiratesData, setAddressData } from '../../redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getUser } from '../../Constants/storedValues';

function AddNewAddress() {
    const user = getUser();
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            mobile: user.mobile,
        },
        mode: "onChange"
    });
    const { setNewAddress } = useSelector((state) => state.profile);
    const { getEmiratesList } = useSelector(state => state.profile);


    const [isAddAddress, setIsAddAddress] = useState(false);
    const dispatch = useDispatch();
    const pathURL = '/myProfile';
    const listAddressURL = '/my-address';
    const navigate = useNavigate();
    const [enabled, setEnabled] = useState(false);

    const onSubmit = (data) => {


        const formattedData = { ...data };

        if (data.is_default === true) {
            formattedData.is_default = 1;
        }
        else if (data.is_default === false) {
            formattedData.is_default = 0;

        }
        dispatch(setAddressData(formattedData));
        toast.success("New Address Added", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,

        });


    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsAddAddress(setNewAddress);

        if (isAddAddress) {
            navigate(pathURL + listAddressURL);
            setIsAddAddress(false);

        }
    }, [setNewAddress]);
    useEffect(() => {
        dispatch(getEmiratesData());

    }, []);

    return (
        <div>
            <h2 className='text-3xl font-medium md:mb-12'>New Address</h2>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between items-center mb-4'>
                        <div className='inline-flex'>
                            <label className='sr-only'>Address Type</label>
                            <div className='flex gap-6 address-type'>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input defaultChecked type="radio" value={1} {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Home</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                value={2}  {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Work</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                value={3}  {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Other</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="inline-flex">
                            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                                <input
                                    {...register("is_default")}
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={enabled}
                                    readOnly
                                />
                                <div
                                    onClick={() => {
                                        setEnabled(!enabled);
                                    }}
                                    className="w-11 h-6 outline outline-1 outline-black border-black rounded-full  peer-focus:ring-black-300  peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-black after:border-black after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-400"
                                ></div>
                                <span className="ml-2 text-base font-light text-gray-900">
                                    Set as default
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        <label className='sr-only'>First Name</label>
                        <input className={errors.f_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'}
                            placeholder={errors.f_name ? 'First Name Required' : "First Name"} {...register("f_name", { required: true })} />
                        <label className='sr-only'>Last Name</label>
                        <input className={errors.l_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'}
                            placeholder={errors.l_name ? 'Last Name Required' : 'Last Name'} {...register("l_name", { required: true })} />
                        <label className='sr-only'>Address</label>
                        <input className={errors.full_address ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2'}
                            placeholder={errors.full_address ? 'Address Required' : 'Address'} {...register("full_address", { required: true })} />
                        <label className='sr-only'>Apartment</label>
                        <input className={errors.building_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2'}
                            placeholder={errors.building_name ? 'Apartment Name Required' : 'Apartment'} {...register("building_name", { required: true })} />
                        <label className='sr-only'>City</label>
                        <input className={errors.city ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'}
                            placeholder='City'  {...register("city", { required: true })} />
                        <label className='sr-only'>Emirate ID</label>
                        <select
                            className='h-[50px] rounded-[25px] px-4 placeholder:font-light bg-white' {...register("emirate_master_id", { required: true })} >
                            {getEmiratesList.map((data, index) => (
                                <option key={index} value={data.id}>{data.name}</option>
                            ))}
                        </select>
                        <div className='flex w-full col-span-2 gap-3'>
                            <div className='flex w-full gap-2'>
                                <label className='sr-only'>Phone</label>
                                <span
                                    className='h-[50px] rounded-[25px] placeholder:font-light bg-white inline-flex justify-center items-center px-8'>+971</span>
                                <input

                                    className={errors.building_name ? 'error block w-full h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'block w-full h-[50px] rounded-[25px] px-4 placeholder:font-light'}
                                    placeholder={errors.building_name ? 'Contact Number Required' : 'Contact'} {...register("mobile", { required: true })} />
                                <input hidden {...register("other_address_type_name")} />
                            </div>
                            <input className='bg-[#41BDDE] rounded-full text-white px-3' value="Save Address"
                                type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddNewAddress;
