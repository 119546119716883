import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

// Custom
import logo from "./images/logo.svg";
import Searchicon from "./images/ic_search.png";
import Account from "./images/account.svg";
import heart from "./images/heart.png";
import Mycart from "./images/ic_cart.svg";
import icon_arrow_down from "./images/icon_arrow_down.png";
import hamburger from "./images/hamburger.png";
import percentIcon from "./images/percentIcon.png";
import docibmobilelogo from "./images/docib-mobile-logo.png";
import guestmobile from "./images/guest-mobile.png"
import ic_document_white from "./images/ic_document_white.png";
import app_store_icon from "./images/app-store.png";
import "./Header.scss";
import { Link, NavLink } from "react-router-dom";
import LoginPopup from "./../Common/LoginPopup/LoginPopup";
import LocationMapPopup from "./../Common/LocationMapPopup/LocationMapPopup";
import {
    setLoggedInStatusThunk, setLoginPopupStatusThunk, setProfileDisplayNameThunk,
} from "./../../redux/login";
import {
    setLocationPopupStatusThunk, setAddressDisplayNameThunk,
} from "./../../redux/location";
import { getUser } from "./../../Constants/storedValues";
import { clearAllProduct, getAllProduct } from "../../redux/product";
import { getAllCategory } from "../../redux/category";
import { useNavigate } from "react-router-dom";
import { GoChevronRight } from "react-icons/go";
import { ToastContainer } from "react-toastify";
import { getCartPage } from "../../redux/cartRedux";

function Header() {
    const uploadPrescriptionURL = "/uploadPage";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchResRef = useRef();
    const store = useStore();
    const { isUserLoggedIn, isLoginPopUpOpen, profileName } = useSelector((state) => state.login);
    const { cartproductCount } = useSelector((state) => state.cart);
    const { isLocationMapPopUpOpen } = useSelector((state) => state.location);
    const { allProducts, showSearchResult } = useSelector((state) => state.product);
    const [searchkey, setSearchkey] = useState("");
    const [searchShow, setSearchShow] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    useEffect(() => {
        dispatch(setLoggedInStatusThunk());
        dispatch(setLoginPopupStatusThunk(false));
        dispatch(setLocationPopupStatusThunk(false));
        dispatch(setProfileDisplayNameThunk());
        dispatch(setAddressDisplayNameThunk());
        dispatch(getAllCategory());
        dispatch(getCartPage());
    }, []);
    const userLocationChange = (e) => {
        e.preventDefault();
        dispatch(setLocationPopupStatusThunk(true));
    };
    const userLoginCheck = (e, pageName = "") => {
        e.preventDefault();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            navigate(pageName);
        }
    };
    const searchResult = (e) => {
        setSearchkey(e.target.value.toString());
        const data = {
            search_keyword: e.target.value.toString(),
        };
        dispatch(getAllProduct(data));
    };
    const onProductClick = (e, pageName) => {
        e.preventDefault();
        dispatch(clearAllProduct())
        setSearchkey("");
        navigate(pageName);
    };
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    function handleUploadPrescriptionClick(e) {
        e.preventDefault();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            navigate(uploadPrescriptionURL);
        }
    }

    const handleKeyDown = (e) => {
        dispatch(clearAllProduct(true))
        if (e.key === "Enter") {
            e.preventDefault();
            dispatch(clearAllProduct(false))
            navigate("/search", { state: searchkey });
            setSearchkey("");
        }
    };

    const Cartpage = (e, pageName = "") => {
        navigate(pageName);
    };

    const searchIconClick = (e) => {
        if (searchkey !== "") {
            e.preventDefault();
            dispatch(clearAllProduct(false))
            navigate("/search", { state: searchkey });
            setSearchkey("");
        }
    }

    const handleFocus = () => {
        setIsFocused(true);
    };

    const onProfileImageClick = (e) => {
        e.preventDefault();
        if (store.getState().login.isUserLoggedIn === false) {
            dispatch(setLoginPopupStatusThunk(true));
        } else {
            navigate("/editprofile");
        }
    }

    const isTab = window.innerWidth <= 991;

    const user = getUser();

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsSticky(true);
            } else {
                setIsSticky(false);

            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchResRef.current && !searchResRef.current.contains(event.target)) {
                setIsFocused(false);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    return (<>
        <header className={`sticky-header ${isSticky ? 'sticky' : ''}`}>
            {isTab ? (<div className="mob-header mb-3">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="colored"
                />
                <div className='navbar-wrapper p-3'>
                    <div className="user-info flex justify-between items-start">
                        <div className='inline-block'>
                            <div className="image">
                                <Link to={"/"}>
                                    <img
                                        className="block max-w-[110px]"
                                        src={logo}
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className='lg:hidden'>
                            <div className='flex items-center justify-start gap-3'>
                                <div className='flex gap-2 items-center text-white'>
                                    <div className='block' onClick={onProfileImageClick}>
                                        <div className="text-sm text-right">
                                            <div>Hello</div>
                                            <strong>{user !== null ? user.fName : "User"}</strong>
                                        </div>
                                    </div>
                                    {/* <div className="image" onClick={onProfileImageClick}>
                                        <img
                                            className="h-[30px] w-[30px] flex-none rounded-full bg-gray-50"
                                            src={Object.keys(user).length > 0 ? user.photoUrl : guestmobile}
                                            alt=""
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search pt-2">
                        <div className="navbar">
                            <form className="flex justify-end search-wrapper">
                                <div className="search-input visible" ref={searchResRef}>
                                    <input
                                        className="form-control me-2"
                                        type="search"
                                        placeholder="Search for products"
                                        aria-label="Search"
                                        value={searchkey}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => {
                                            searchResult(e);
                                        }}
                                        onFocus={handleFocus}
                                    />

                                    {(allProducts.length > 0 && showSearchResult && isFocused) ? <div className="search_result">
                                        <ul>
                                            {allProducts.map((product, index) => {
                                                return (<li>
                                                    <Link
                                                        to=""
                                                        onClick={(e) => {
                                                            onProductClick(e, product.handle);
                                                        }}
                                                    >
                                                        {product.name}
                                                    </Link>
                                                </li>);
                                            })}
                                        </ul>
                                    </div> : <></>}
                                </div>
                                <div xs="auto"
                                    onClick={(e) => {
                                        searchIconClick(e)
                                    }}
                                    className="search-button w-10 h-10 bg-[#F6F6F6] rounded-full flex items-center justify-center active"
                                >
                                    <img
                                        alt=""
                                        src={Searchicon}
                                        width="20"
                                        height="24"
                                        className="d-inline-block align-top"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div
                    className="deliver-address container-fluid"
                    onClick={(e) => userLocationChange(e)}
                >
                    <div className="cursor-pointer">
                        <div className="address">
                            <div className='flex'>
                                <div className='flex gap-1 overflow-hidden text-ellipsis'>
                                    <span className="font-light whitespace-nowrap">Delivering to </span>
                                    <div className='overflow-hidden px-1 text-ellipsis'>
                                        <span
                                            className='whitespace-nowrap'>{store.getState().location.recentAddress}</span>
                                    </div>
                                </div>
                                <div className='inline-block'>
                                    <img
                                        alt=""
                                        src={icon_arrow_down}
                                        width="14"
                                        height="auto"
                                        className="d-inline-block align-end "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>) : (<div className="desktop-header">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="colored"
                    // toastStyle={{ backgroundColor: "#41BDDE" }}
                />
                <section className="top_header hidden">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <span>
                                    Free shipping on orders over AED 50 | Delivery within 24 -
                                    48 hours across UAE | DHA Approved Online Pharmacy
                                </span>
                                <span className="phone">
                                    Call : <strong>800DOCIB</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <div className="container">
                            <Link className="navbar-brand flex items-center" to={"/"}>
                                <span className="logo">
                                    <img
                                        alt=""
                                        src={logo}
                                        width="153"
                                        height="auto"
                                        className="d-inline-block align-top"
                                    />
                                </span>
                            </Link>
                            <div className="navbar-collapse flex" id="navbarSupportedContent" ref={searchResRef}>
                                <form className="d-flex search-wrapper">
                                    <button className="search">
                                        <img
                                            alt=""
                                            src={Searchicon}
                                            width="20"
                                            height="24"
                                            className="d-inline-block align-top"
                                        />
                                    </button>
                                    <input
                                        className="form-control me-2"
                                        type="search"
                                        placeholder="Search for products"
                                        aria-label="Search"
                                        value={searchkey}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => {
                                            searchResult(e);
                                        }}
                                        onFocus={handleFocus}
                                    />
                                    {(allProducts.length > 0 && showSearchResult && isFocused) ? <div className="search_result">
                                        <ul>
                                            {allProducts.map((product, index) => {
                                                return (<li>
                                                    <Link
                                                        to=""
                                                        onClick={(e) => {
                                                            onProductClick(e, product.handle);
                                                        }}
                                                    >
                                                        {product.name}
                                                    </Link>
                                                </li>);
                                            })}
                                        </ul>
                                    </div> : <></>}
                                </form>
                                <ul className="navbar-nav flex-row mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <span
                                            className="image"
                                            onClick={(e) => {
                                                userLoginCheck(e, "myProfile");
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src={Account}
                                                width="37"
                                                height="33"
                                                className="d-inline-block align-top"
                                            />
                                        </span>
                                        <span className="label">{profileName}</span>
                                        {/* </Link> */}
                                    </li>
                                    <li className="nav-item">
                                        <span
                                            className="image"
                                            onClick={(e) => userLoginCheck(e, "myWishlist")}
                                        >
                                            <img
                                                alt=""
                                                src={heart}
                                                width="35"
                                                height="30"
                                                className="d-inline-block align-top"
                                            />
                                        </span>
                                        <span className="label">Wishlist</span>
                                    </li>
                                    <li className="nav-item">
                                        <span
                                            className="image relative"
                                            onClick={(e) => Cartpage(e, "cartPage")}
                                        >
                                            <img
                                                alt=""
                                                src={Mycart}
                                                width="34"
                                                height="32"
                                                className="d-inline-block align-top"
                                            />
                                            <span
                                                className="rounded-full w-5 h-5 bg-black flex items-center justify-center absolute -top-0 -right-2">
                                                {cartproductCount !== undefined ? cartproductCount : "0"}
                                            </span>
                                        </span>
                                        <span className="label">Cart</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="deliver-address container-fluid">
                    <div className="container cursor-pointer">
                        <div className='message text-xs inline-flex items-center'>
                            <div className='inline-block'>Enhance shopping, get our app</div>
                            <div className='playstore-icon inline-block w-4 h-4 ml-4'>
                                <Link to={"https://play.google.com/store/apps/details?id=com.docibpharmacy.app"}
                                    target={"_blank"}>
                                    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M48 59.49v393a4.33 4.33 0 0 0 7.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0 0 48 59.49ZM345.8 174 89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32ZM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95ZM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50Z" />
                                    </svg>
                                </Link>
                            </div>
                            <div className='w-[1px] pl-[1px] pt-3 ml-3 h-full bg-black inline-block'></div>
                            <div className='playstore-icon inline-block w-4 h-4 ml-3'>
                                <Link to={"https://apps.apple.com/us/app/docib-pharmacy/id1625636039"}
                                    target={"_blank"}>
                                    <img src={app_store_icon} />
                                </Link>
                            </div>
                            <div className='inline-block ml-3'>Delivery within 24 - 48 hours across UAE</div>
                        </div>
                        <div className='location flex items-center' onClick={(e) => userLocationChange(e)}>
                            <div className="address">
                                <strong>Delivering to:</strong>
                                <span>{store.getState().location.recentAddress}</span>
                            </div>
                            <div className="icon">
                                <img
                                    alt=""
                                    src={icon_arrow_down}
                                    width="12"
                                    height="auto"
                                    className="d-inline-block align-end "
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="header_bottom">
                    <div className="container">
                        <div
                            className="category"
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                        >
                            <span className="icon">
                                <img
                                    alt=""
                                    src={hamburger}
                                    width="24"
                                    height="auto"
                                    className="d-inline-block align-top"
                                />
                            </span>
                            <a className="font-light" href="#">
                                Shop by Category
                            </a>
                            {isHovering && <Submenu />}
                        </div>
                        {/* <div className="offer">
                            <span className="icon">
                                <img
                                    alt=""
                                    src={percentIcon}
                                    width="16"
                                    height="auto"
                                    className="d-inline-block align-end"
                                />
                            </span>
                            <span className="label">Offers</span>
                        </div> */}
                        <div className="file_upload" onClick={(e) => handleUploadPrescriptionClick(e)}>
                            <a href="">
                                <span className="icon">
                                    <img
                                        alt=""
                                        src={ic_document_white}
                                        width="16"
                                        height="auto"
                                        className="d-inline-block align-end"
                                    />
                                </span>
                                <span>
                                    Upload Prescription
                                </span>
                            </a>
                        </div>
                    </div>
                </section>
            </div>)}
        </header>

        {isLoginPopUpOpen && <LoginPopup />}

        {isLocationMapPopUpOpen && <LocationMapPopup />}
    </>);
}

function Submenu() {
    const { allCategoryList } = useSelector((state) => state.category);
    const [catIndex, setCatIndex] = useState(-1);
    const handleMouseOver = (index) => {
        setCatIndex(index)
    };

    return (<ul className="nav-submenu">
        {allCategoryList.map((element, index) => {
            return (<li className="nav-submenu-item" key={index}>
                <div
                    className="category-item"
                    onMouseOver={() => {
                        handleMouseOver(index)
                    }}
                >
                    <Link to={element.handle}>
                        <p className="flex p-[1px] px-2 rounded-full hover:bg-[#f2f2f2] hover:text-[#41BDDE] items-center justify-between text-sm cursor-pointer whitespace-nowrap">
                            {element.name}
                            <GoChevronRight className="text-lg" />
                        </p>
                    </Link>
                </div>
                <SubCategoryMenu subCategory={catIndex === index && element.sub_categories}
                    categoryHandle={element.handle} />
            </li>);
        })}
    </ul>);
}

function SubCategoryMenu({ subCategory, categoryHandle }) {
    return (
        <div className="nav-subcategorymenu absolute left-full top-0 ml-[1px] min-h-full">

            {
                subCategory !== false &&
                <>
                    <ul className='grid grid-rows-5 grid-flow-col gap-3 p-3 bg-white'>
                        {
                            subCategory.map((element, index) => {
                                return (
                                    <li className="nav-subcategorymenu-item inline-block whitespace-nowrap" key={index}>
                                        <Link to={categoryHandle + "/" + element.handle}>
                                            <p>{element.name}</p>
                                        </Link>
                                    </li>
                                );
                            })
                        }
                        <li className="nav-subcategorymenu-item inline-block whitespace-nowrap">
                            <Link to={categoryHandle}>View All</Link>
                        </li>
                    </ul>
                </>
            }

        </div>);
}

export default Header;
