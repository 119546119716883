import {useDispatch, useSelector, useStore} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom'
import ic_payment_method from "../../assets/images/icons/ic_payment_method.svg";
import ic_cart_blue from "../../assets/images/icons/ic_cart_blue.svg";
import React, {useEffect, useState} from "react";
import CartProduct from '../../Components/Product/CartProduct';
import {addToCart, applyCoupenApi} from '../../services/apiCalls';
import {cartTabIndexChange, getCartPage, getCartPagePrecription} from '../../redux/cartRedux';
import {toast} from 'react-toastify';
import {BsChevronRight} from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import postpay_image from '../../assets/images/icons/postpay.png'
import payment_options from '../../assets/images/payment_options.png'
import {setLoginPopupStatusThunk} from '../../redux/login';
import {getAddressData} from '../../redux/profile';

function Cart() {
    const {cartpageBilling} = useSelector((state) => state.cart);
    const {cartpageItems} = useSelector((state) => state.cart);
    const {cartproductCount} = useSelector((state) => state.cart);
    const {userAddress} = useSelector((state) => state.cart);
    const {selectedAddress} = useSelector((state) => state.profile);
    const {isUserLoggedIn} = useSelector((state) => state.login);
    const [coupen, setCoupen] = useState("");
    const store = useStore();
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/');
    }
    useEffect(() => {
        dispatch(cartTabIndexChange("1"))
    }, [])
    useEffect(() => {
        if (store.getState().login.isUserLoggedIn === true) {
            dispatch(getCartPage())
            dispatch(getAddressData())
        }
    }, [isUserLoggedIn])
    const proceedAction = (e) => {
        if (cartproductCount !== 0) {
            e.preventDefault();
            if (store.getState().login.isUserLoggedIn === false) {
                dispatch(setLoginPopupStatusThunk(true));
            } else {
                navigate('/checkout', {state: {flag: "1"}});
            }
        } else {
            toast.error("Cart is empty", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    }
    let [qty, setQty] = useState(0);
    const dispatch = useDispatch();

    const onApplyCoupenClick = async () => {
        const data = {
            'coupon_code': coupen, 'flag': "1",
        }
        const applyCoupen = await applyCoupenApi(data);
        if (applyCoupen.data.code === 1) {
            dispatch(getCartPage())
            toast.success("Coupon applied succesfully", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        } else {
            toast.error("Coupon not found", {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            });
        }
    }


    const isTab = window.innerWidth <= 991;

    return (<div>
        {isTab ? (<div className='mob pb-12'>
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    {
                        cartproductCount !== 0 ?
                            <div
                                className=' w-full border-b-8 border-gray-100 items-center pb-[6px] justify-between pt-[10px] border-b max-md:px-4 max-md:py-3 border-t-8 border-gray-100'>
                                <div className='flex justify-between w-full'>
                                    <h1 className='text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold'>Delivery
                                        Address</h1>
                                    {selectedAddress.id !== "new" ?
                                        <Link className='max-md:text-sm max-md:font-medium text-btn-color'
                                              to={"/my-address"}>Change</Link> :
                                        <Link className='max-md:text-sm max-md:font-medium text-btn-color' to="/add-address">Add</Link>}
                                </div>
                                {selectedAddress.id !== "new" && <div
                                    className='flex flex-col gap-1 max-md:px-0 max-md:py-3 border-t-1 text-xs font-normal'>
                                    <p className='text-sm'>{selectedAddress.f_name} {selectedAddress.l_name}</p>
                                    <p>{selectedAddress.full_address}</p>
                                    <p>{selectedAddress.city},{selectedAddress.emirate_name}</p>
                                    <p>{selectedAddress.mobile}</p>
                                </div>}
                            </div> : <></>

                    }

                    <div className="lg:w-4/6 md:w-2/3 w-full pr-10 md:pr-6 bg-white max-md:px-4 max-md:pt-4"
                         id="scroll">
                        {cartproductCount !== 0 ? <ul className='inline-block'>
                            {cartpageItems.map((products, index) => (
                                <CartProduct key={index} product={products}/>))}
                            <div className='bg-white py-3 rounded-[15px] mt-4 max-md:flex'>
                                <input
                                    type="text"
                                    name="message"
                                    id="message"
                                    className="w-full rounded-md border resize-none max-md:px-3 placeholder:font-normal text-sm placeholder:text-sm flex items-center max-md:rounded-tr-none max-md:rounded-br-none"
                                    placeholder='Gift Card / Discount Coupon'
                                    defaultValue={coupen}
                                    onChange={(e) => {
                                        setCoupen(e.target.value)
                                    }}
                                />
                                <button
                                    className='flex items-center justify-center md:mt-2 max-md:mt-0 ml-auto bg-black border-black font-light text-sm gap-x-3 max-md:rounded-tr-md max-md:rounded-br-md rounded-tl-none rounded-bl-none px-6 h-[40px]'
                                    onClick={onApplyCoupenClick}>
                                    Apply
                                </button>
                            </div>
                        </ul> : <div className='py-16 text-center'>
                            <p className='mb-3 text-sm'>Your cart is empty</p>
                            <button className='w-full max-w-[500px] text-sm' onClick={goBack}>Start
                                Shopping
                            </button>
                        </div>}
                    </div>
                    <div
                        className="lg:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px] max-md:rounded-none max-md:py-0">
                        {(cartproductCount !== 0) ? <div
                            className="flex flex-col px-8 pb-8 justify-between max-md:bg-white max-md:px-0 max-md:pb-0">
                            <div className='pb-[20px] max-md:pb-4'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Order
                                        Total</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.items_total}</p>
                                </div>
                                {cartpageBilling.hasOwnProperty("total_offer_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">-AED {cartpageBilling.total_offer_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("total_coupon_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">-AED {cartpageBilling.total_coupon_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.vat_amount}</p>
                                </div>}
                                <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none text-green-500 max-md:text-sm">{cartpageBilling.shipping_charge > 0 ? cartpageBilling.shipping_charge : "FREE"}</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b max-md:px-4 max-md:py-3">
                                    <p className="text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800 max-md:text-base max-md:font-semibold">AED {cartpageBilling.total_payable}</p>
                                </div>
                                {/* <div
                                    className='flex items-center pb-[6px] justify-between pt-[10px] border-b max-md:px-4 max-md:py-3 border-t-8 border-gray-100'>
                                    <h1 className='text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold'>Delivery
                                        Address</h1>
                                    {selectedAddress.id !== "new" ?
                                        <Link className='max-md:text-sm max-md:font-medium text-btn-color'
                                            to={"/my-address"}>Change</Link> : <Link to="" onClick={(e) => {
                                                userLoginCheck(e, "/add-address")
                                            }}>Add</Link>}
                                </div>
                                {selectedAddress.id !== "new" && <div
                                    className='flex flex-col gap-1 max-md:px-4 max-md:py-3 border-t-1 text-xs font-normal'>
                                    <p className='text-sm'>{selectedAddress.f_name} {selectedAddress.l_name}</p>
                                    <p>{selectedAddress.full_address}</p>
                                    <p>{selectedAddress.city},{selectedAddress.emirate_name}</p>
                                    <p>{selectedAddress.mobile}</p>
                                </div>} */}
                                <div className='pt-[10px] pb-[30px] border-t-8 border-gray-100'>
                                    <img src={payment_options} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto hidden'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={goBack}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div> : <div
                            className="flex flex-col px-8 pb-8 justify-between max-md:bg-white max-md:px-0 max-md:pb-0">
                            <div className='max-md:pb-4'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Order
                                        Total</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED 0</p>
                                </div>
                                {<div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED
                                        0</p>
                                </div>}
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">0</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t max-md:px-4 max-md:py-3">
                                    <p className="text-[25px] font-light leading-normal text-gray-800  max-md:text-base max-md:font-semibold">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800 max-md:text-base max-md:font-semibold">AED
                                        0</p>
                                </div>
                                <div className='pt-[10px] pb-[30px] border-t-8 border-gray-100'>
                                    <img src={payment_options} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto hidden'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={goBack}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>) : (<div className='pb-12 desktop'>
            <div className="z-10 transform translate-x-0 transition ease-in-out duration-700">
                <div className="flex md:flex-row flex-col justify-end items-start" id="cart">
                    <div
                        className="lg:w-4/6 w-full pr-10 md:pr-6 bg-white max-md:pr-0 max-md:px-3"
                        id="scroll">
                        {cartproductCount !== 0 ? <div>
                            {cartpageItems.map((products, index) => (
                                <CartProduct key={index} product={products}/>))}
                        </div> : <div className='pt-16 text-center'>
                            <p className='mb-3 text-sm'>Your cart is empty</p>
                            <button className='w-full max-w-[500px] text-sm' onClick={goBack}>Start
                                Shopping
                            </button>
                        </div>}
                    </div>
                    <div
                        className="xl:w-2/6 md:w-1/3 w-full bg-gray-100 rounded-[30px] max-md:rounded-none max-md:py-0">
                        {(cartproductCount !== 0) ? <div
                            className="flex flex-col px-8 py-14 pb-8 justify-between max-md:bg-white max-md:px-0">

                            <div className='pb-[20px] max-md:pb-4'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800 max-md:text-lg max-md:p-4 max-md:py-3 border-b border-t-8 border-gray-100">Order
                                    Summary</p>
                                <div
                                    className="flex items-center justify-between pt-6 font-light max-md:px-4 max-md:pt-3">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Order
                                        Total</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.items_total}</p>
                                </div>
                                {cartpageBilling.hasOwnProperty("total_offer_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.total_offer_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("total_coupon_discount") && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.total_coupon_discount}</p>
                                </div>}
                                {cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 && <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">AED {cartpageBilling.vat_amount}</p>
                                </div>}
                                {/* <div
                                    className="flex items-center justify-between pt-[10px] font-light max-md:px-4">
                                    <p className="text-base leading-none text-gray-800 max-md:text-sm">Shipping</p>
                                    <p className="text-base leading-none max-md:text-sm text-green-500">{cartpageBilling.shipping_charge > 0 ? cartpageBilling.shipping_charge : "FREE"}</p>
                                </div> */}
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b max-md:px-4 max-md:py-3">
                                    <p className="text-[25px] font-light leading-normal text-gray-800  max-md:text-lg max-md:font-semibold">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800 max-md:text-lg max-md:font-semibold">AED {cartpageBilling.total_payable}</p>
                                </div>
                                <div
                                    className='emi text-xl max-md:text-xs max-md:block max-md:w-full max-md:pt-2'>
                                    <div className='flex text-base'>
                                        {cartpageBilling.total_payable !== undefined && <>
                                            <div
                                                className='emi flex items-center flex-wrap text-sm font-light max-md:text-xs max-md:block max-md:w-full max-md:pt-2 pt-2'>
                                                <span
                                                    className="font-semibold pr-1 text-base">AED {(parseFloat(cartpageBilling.total_payable.replace(",", "")) / 3).toFixed(2)} </span>
                                                <span> Pay in 3 interest-free payments with</span>
                                                <img alt='postpay' src={postpay_image}/>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                                <div className='pt-[10px] pb-[30px]'>
                                    <img src={ic_payment_method} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={goBack}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div> : <div
                            className="flex flex-col  px-8 py-14 pb-8 justify-between">

                            <div className='pb-[20px]'>
                                <p className="text-[25px] font-medium leading-9 text-gray-800">Order
                                    Summary</p>
                                <div className="flex items-center justify-between pt-6 font-light">
                                    <p className="text-base leading-none text-gray-800">Order
                                        Total</p>
                                    <p className="text-base leading-none text-gray-800">AED 0</p>
                                </div>
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Offer
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Coupon
                                        Discount</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                {<div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Estimated
                                        VAT %</p>
                                    <p className="text-base leading-none text-gray-800">AED
                                        0</p>
                                </div>}
                                <div
                                    className="flex items-center justify-between pt-[10px] font-light">
                                    <p className="text-base leading-none text-gray-800">Shipping</p>
                                    <p className="text-base leading-none text-gray-800">0</p>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                    <p className="text-[25px] font-light leading-normal text-gray-800">Total
                                        Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED
                                        0</p>
                                </div>
                                <div className='pt-[10px] pb-[30px]'>
                                    <img src={ic_payment_method} alt={'payment method'}/>
                                </div>
                                <div
                                    className='block text-center justify-center flex-column gap-[20px] w-auto'>
                                    <button onClick={(e) => {
                                        proceedAction(e)
                                    }}
                                            className="text-base w-auto text-center leading-none fw-light border focus:outline-none text-white h-[36px] inline-flex gap-3 justify-center items-center">
                                        Proceed to checkout
                                        <BsChevronRight/>
                                    </button>
                                    <button onClick={goBack}
                                            className=" mt-[20px] text-base w-auto text-center leading-none fw-light border border-black focus:outline-none text-black h-[36px] inline-flex justify-center items-center gap-[12px] bg-transparent">
                                        <img src={ic_cart_blue} alt={'cart'}/>
                                        Continue Shopping
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>)}
    </div>)
}

export default Cart