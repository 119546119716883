import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTermscondition } from '../../redux/customerservices';
import { Container } from 'react-bootstrap';

function TermsCondition() {

  const dispatch = useDispatch();
  const { Terms } = useSelector((state) => state.customerServices);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTermscondition())
    return
  }, []);
  return (
    <Container>
      <div>


        {Terms.map((details) => {
          return (
            <div dangerouslySetInnerHTML={{ __html: details.description }} />
          );
        })}

      </div>
    </Container>

  )
}

export default TermsCondition
