import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useDispatch, useSelector, connect, useStore} from 'react-redux';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {initializeApp} from "firebase/app";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    getAuth,
    signInWithPopup,
    OAuthProvider,
    signOut
} from "firebase/auth";
import {useForm} from "react-hook-form";
import OTPInput from 'react-otp-input';

import './LoginPopup.scss';
import {firebaseConfig} from "./../../../Constants/firebase";
import {mobileOtpResendSeconds} from "./../../../Constants/constant";
import {
    setCustomerToken, getCustomerToken, clearCustomerToken,
    setIsLogin, getIsLogin, clearIsLogin,
    setUniqueId, getUniqueId, clearUniqueId,
    setUser, getUser, clearUser
} from "./../../../Constants/storedValues";
import {
    setLoggedInStatusThunk,
    setLoginPopupStatusThunk,
    processUserLoginThunk,
    processUserRegistrationThunk,
    processUserSendOtpThunk,
    processUserVerifyOtpThunk,
    getUserProfileDetailsThunk
} from "./../../../redux/login";

import ic_google from '../../../assets/images/icons/ic_google.png'
import ic_facebook from '../../../assets/images/icons/ic_facebook.png'
import { BsChevronRight } from 'react-icons/bs';

export default function LoginPopup({loginProps}) {
    const dispatch = useDispatch();
    const store = useStore();
    const isUserLoggedIn = useSelector((state) => state.login.isUserLoggedIn);
    const isLoginPopUpOpen = useSelector((state) => state.login.isLoginPopUpOpen);
    const [shouldLoginPopupShow, setShouldLoginPopupShow] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loginPopupStep, setLoginPopupStep] = useState(1);
    const [hasResendOtpTimeElapsed, setHasResendOtpTimeElapsed] = React.useState(false);
    const [resendOtpEnableSeconds, setResendOtpEnableSeconds] = React.useState(mobileOtpResendSeconds);
    const resendOtpSecsRef = useRef(mobileOtpResendSeconds);
    const [mobileOtp, setMobileOtp] = useState('');
    const [mobileMaskedText, setMobileMaskedText] = useState('xxxxxxxxxx');
    const mobileMaskCount = 6;
    const tempAuthTokenRef = useRef('');
    const firebaseApp = initializeApp(firebaseConfig);
    const firebaseAuth = getAuth(firebaseApp);
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');
    const loginType = {'mobile': 'Mobile', 'google': 'Google', 'fb': 'Facebook', 'apple': 'Apple'};
    const registerOptions = {
        mobileNumber: {
            required: "Mobile Number is required",
            pattern: {
                value: /\d+/,
                message: "Mobile Number must have number only."
            },
            minLength: {
                value: 9,
                message: "Mobile Number must have at least 9 characters"
            },
            maxLength: {
                value: 11,
                message: "Mobile Number must not exceed 11 characters"
            }
        },
        firstName: {
            required: "First Name is required",
            minLength: {
                value: 3,
                message: "First Name must have at least 3 characters"
            },
        },
        lastName: {
            required: "Last Name is required",
            minLength: {
                value: 1,
                message: "Last Name must have at least 1 characters"
            },
        },
        userEmail: {
            required: "E-Mail Id is required",
            pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "E-Mail Id is invalid."
            },
        },
    };

    const handleMobileNumberForm = (data) => signInWithMobileNumber(data);
    const handleMobileNumberError = (errors) => {
        console.log('Mobile Phone Form Submit Error : ', errors);
    };
    const signInWithMobileNumber = async (data) => {
        try {
            await processLoginCall({
                loginId: data.mobileNumber,
                loginType: loginType.mobile,
            });
        } catch (err) {
            console.error('Mobile Number Login Error: ', err);
        }
    };

    const handleMobileOtpForm = (data) => processMobileOtpVerification(data);
    const handleMobileOtpError = (errors) => {
        console.log('Mobile Phone Form OTP Submit Error : ', errors);
    };
    const processMobileOtpVerification = async (data) => {
        const requestData = {"otp": mobileOtp};
        const thunkArgs = {'requestData': requestData, 'token': tempAuthTokenRef.current};
        await dispatch(processUserVerifyOtpThunk(thunkArgs));
        let currentVerifyData = store.getState().login.verifyOtpData;
        if (Object.keys(currentVerifyData).length > 0) {
            if (currentVerifyData["result"]["is_profile_completed"] === '1') {
                setCustomerToken(tempAuthTokenRef.current);
                clearUniqueId();
                setIsLogin(true);
                await processUserProfileDetails();
                setLoginPopupOpen(false);
            } else {
                setLoginPopupStep(current => 3);
            }
        }
    };

    const handleMobileRegistrationForm = (data) => handleUserRegistrationAction(data);
    const handleMobileRegistrationError = (errors) => {
        console.log('Mobile Phone Registration Form Submit Error : ', errors);
    };
    const handleUserRegistrationAction = async (data) => {
        await processUserRegistration(data.firstName, data.lastName, data.userEmail);
        setCustomerToken(tempAuthTokenRef.current);
        clearUniqueId();
        setIsLogin(true);
        await processUserProfileDetails();
        setLoginPopupOpen(false);
    };

    const setLoginPopupOpen = (value) => {
        setShouldLoginPopupShow(current => value);
        dispatch(setLoggedInStatusThunk());
        dispatch(setLoginPopupStatusThunk(value));
    };
    const signInWithGoogleSocialLogin = async () => {
        try {
            const res = await signInWithPopup(firebaseAuth, googleProvider);
            const user = res.user;
            const names = user.displayName.split(' ');
            await processLoginCall({
                loginId: user.uid,
                loginType: loginType.google,
                userData: {
                    "firstName": names[0],
                    "lastName": names[1],
                    "email": user.email,
                    "photo_url": user.photoURL
                }
            });
        } catch (err) {
            console.error('Google Login Error: ', err);
        }
    };
    const signInWithFacebookSocialLogin = async () => {
        try {
            const res = await signInWithPopup(firebaseAuth, facebookProvider);
            const user = res.user;
            const credential = FacebookAuthProvider.credentialFromResult(res);
            const accessToken = credential.accessToken;
            const names = user.displayName.split(' ');
            await processLoginCall({
                loginId: user.uid,
                loginType: loginType.fb,
                userData: {
                    "firstName": names[0],
                    "lastName": names[1],
                    "email": user.email,
                    "photo_url": user.photoURL
                }
            });
        } catch (err) {
            console.error('Facebook Login Error: ', err);
        }
    };
    const signInWithAppleSocialLogin = async () => {
        try {
            appleProvider.addScope('email');
            appleProvider.addScope('name');
            const res = await signInWithPopup(firebaseAuth, appleProvider);
            const user = res.user;
            const credential = OAuthProvider.credentialFromResult(res);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            const names = user.displayName.split(' ');
            await processLoginCall({
                loginId: user.uid,
                loginType: loginType.apple,
                userData: {
                    "firstName": names[0],
                    "lastName": names[1],
                    "email": user.email,
                    "photo_url": user.photoURL
                }
            });
        } catch (err) {
            console.error('Apple Login Error: ', err);
        }
    };
    const processLoginCall = async (requestData) => {
        let mapData = {
            "mobile": "",
            "facebook_id": "",
            "google_id": "",
            "photo_url": "",
            "email": "",
            "apple_id": "",
        };
        switch (requestData.loginType) {
            case loginType.fb:
                mapData["facebook_id"] = requestData.loginId;
                if (requestData.hasOwnProperty('userData')) {
                    mapData["photo_url"] = requestData.userData.photo_url;
                    mapData["email"] = requestData.userData.email;
                }
                break;
            case loginType.google:
                mapData["google_id"] = requestData.loginId;
                if (requestData.hasOwnProperty('userData')) {
                    mapData["photo_url"] = requestData.userData.photo_url;
                    mapData["email"] = requestData.userData.email;
                }
                break;
            case loginType.apple:
                mapData["apple_id"] = requestData.loginId;
                if (requestData.hasOwnProperty('userData')) {
                    mapData["email"] = requestData.userData.email;
                }
                break;
            case loginType.mobile:
                mapData["mobile"] = requestData.loginId;
                break;
            default:
                break;
        }
        await dispatch(processUserLoginThunk(mapData));
        let currentLoginData = store.getState().login.loginData;
        if (Object.keys(currentLoginData).length > 0) {
            const authToken = currentLoginData["token"];
            if (requestData.loginType === loginType.mobile) {
                tempAuthTokenRef.current = authToken;
                let mobileNumberValue = requestData.loginId;
                let mobileNumberMask = mobileNumberValue.slice(0, -mobileMaskCount) + Array(mobileMaskCount + 1).join('#');
                setMobileMaskedText(current => mobileNumberMask);
                setLoginPopupStep(current => 2);
                setHasResendOtpTimeElapsed(current => false);
                setResendOtpEnableSeconds(current => mobileOtpResendSeconds);
                resendOtpSecsRef.current = mobileOtpResendSeconds;
                setOtpResendTimeOut();
            } else {
                if (requestData.hasOwnProperty('userData') && (currentLoginData['is_profile_completed'] === '0')) {
                    const profileUser = requestData.userData;
                    await processUserRegistration(profileUser["firstName"], profileUser["lastName"], profileUser["email"]);
                }
                setCustomerToken(authToken);
                clearUniqueId();
                setIsLogin(true);
                await processUserProfileDetails();
                setLoginPopupOpen(false);
            }
        }
    };
    const resendMobileOtp = async () => {
        await dispatch(processUserSendOtpThunk(tempAuthTokenRef.current));
    }
    const resendMobileOtpButtonAction = () => resendMobileOtp();
    const processUserRegistration = async (firstName, lastName, email) => {
        const profileData = {'f_name': firstName, 'l_name': lastName, 'email': email};
        const thunkArgs = {'requestData': profileData, 'token': tempAuthTokenRef.current};
        await dispatch(processUserRegistrationThunk(thunkArgs));
    };
    const processUserProfileDetails = async () => {
        await dispatch(getUserProfileDetailsThunk());
        let currentProfileData = store.getState().login.profileData;
        if (Object.keys(currentProfileData).length > 0) {
            const userData = currentProfileData["result"][0];
            const userStorageData = {
                'id': userData['id'],
                'fName': userData['f_name'],
                'lName': userData['l_name'],
                'mobile': userData['mobile'],
                'email': userData['email'],
                'gender': userData['gender'],
                'dob': userData['dob'],
                'photoUrl': userData['photo_url'],
            };
            clearUser();
            setUser(userStorageData);
        }
    };
    const onLoginClose = () => setLoginPopupOpen(false);
    const setOtpResendTimeOut = () => {
        const otpResendTimeOutInterval = setInterval(() => {
            if (resendOtpSecsRef.current < 1) {
                clearInterval(otpResendTimeOutInterval);
                setHasResendOtpTimeElapsed(current => true);
            }
            setResendOtpEnableSeconds(current => current - 1);
            resendOtpSecsRef.current = resendOtpSecsRef.current - 1;
        }, 1000);
        return () => clearInterval(otpResendTimeOutInterval);
    };
    const mobileNumberLoginFormSectionResolver = () => {
        if (loginPopupStep === 1) {
            return (
                <div id="mobileNumberLoginFormSection">
                    <Row>
                        <Col xs={12}><span className='text-[28px] font-medium block mb-4'>Hello!</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='text-base block mb-4'><span>Create account</span></Col>
                    </Row>
                    <form onSubmit={handleSubmit(handleMobileNumberForm, handleMobileNumberError)}>
                        <Row className='m-0 gap-6 max-md:flex-nowrap max-md:gap-3'>
                            <div
                                className='rounded-full bg-white flex items-center justify-center text-gray-400 font-medium text-sm md:w-auto px-10 flex-1 max-md:px-4 max-md:h-[40px] max-md:max-w-[80px] md:max-w-[130px]'>
                                <span>+971</span>
                            </div>
                            <div className='md:w-auto p-0 flex-1 max-md:w-5/6'>
                                <input className='rounded-full bg-white px-6 border border-white max-md:h-[40px] max-md:max-w-full max-md:w-full'
                                       type="text"
                                       placeholder="Phone" {...register('mobileNumber', registerOptions.mobileNumber)}
                                       aria-invalid={errors.mobileNumber ? "true" : "false"}/>
                                <p>{errors.mobileNumber?.message}</p>
                            </div>
                        </Row>
                        <Row>
                            <Col className='flex justify-center items-center mt-3 pb-10 border-b border-[#DEDEDE] max-md:justify-end'>
                                <button className='bg-[#41BDDE] font-normal inline-flex items-center gap-x-2 max-md:px-4' type="submit">
                                    Continue
                                    <BsChevronRight/>
                                </button>
                            </Col>
                        </Row>
                    </form>
                </div>
            );
        } else if (loginPopupStep === 2) {
            return (
                <div id="mobileNumberOtpFormSection">
                    <Row>
                        <div className='w-full'><span className='text-4xl font-medium inline-block'>OTP Verification</span></div>
                    </Row>
                    <Row>
                        <div className='w-full'><span className='pt-8 pb-6 text-base font-light inline-block'>Enter the OTP you received to +971 {mobileMaskedText}</span></div>
                    </Row>
                    <form onSubmit={handleSubmit(handleMobileOtpForm, handleMobileOtpError)}>
                        <Row>
                            <Col xs={12} className='otp-verification'>
                                <OTPInput
                                    classname='rounded-md'
                                    inputStyle="inputStyle otp-input-custom-class"
                                    numInputs={4}
                                    onChange={setMobileOtp}
                                    renderSeparator={<span>-</span>}
                                    value={mobileOtp}
                                    placeholder={'----'}
                                    inputType={'text'}
                                    renderInput={(props) => <input {...props} />}
                                    shouldAutoFocus
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='flex justify-center items-center pt-6'>
                                <button className='submit-btn' type="submit">Verify</button>
                            </Col>
                        </Row>
                    </form>

                    <Row>
                        {(hasResendOtpTimeElapsed === true) ?
                            (
                                <Col xs={12} className='resend-otp'>
                                    <button type="button" onClick={resendMobileOtpButtonAction}>Resend OTP</button>
                                </Col>
                            ) : (
                                <Col xs={12}>
                                    <span> Resend OTP in {resendOtpEnableSeconds} seconds </span>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            );
        } else if (loginPopupStep === 3) {
            return (
                <div id="mobileNumberRegistrationFormSection">
                    <Row>
                        <Col xs={12} className='text-base block mb-4'><span>Create Account</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='text-sm block mb-3'><span>Fill the Profile details</span></Col>
                    </Row>
                    <form onSubmit={handleSubmit(handleMobileRegistrationForm, handleMobileRegistrationError)}>
                        <Row className='gap-4'>
                            <Col xs={12}>
                                <input type="text"
                                       className='rounded-full bg-white px-6 border border-white max-md:h-[40px] w-full max-md:max-w-full max-md:w-full'
                                       placeholder="First Name" {...register('firstName', registerOptions.firstName)}
                                       aria-invalid={errors.firstName ? "true" : "false"}/>
                                <p>{errors.firstName?.message}</p>
                            </Col>
                            <Col xs={12}>
                                <input type="text"
                                       className='rounded-full bg-white px-6 border border-white max-md:h-[40px] w-full max-md:max-w-full max-md:w-full'
                                       placeholder="Last Name" {...register('lastName', registerOptions.lastName)}
                                       aria-invalid={errors.lastName ? "true" : "false"}/>
                                <p>{errors.lastName?.message}</p>
                            </Col>
                            <Col xs={12}>
                                <input type="text"
                                       className='rounded-full bg-white px-6 border border-white max-md:h-[40px] w-full max-md:max-w-full max-md:w-full'
                                       placeholder="E-Mail Id" {...register('userEmail', registerOptions.userEmail)}
                                       aria-invalid={errors.userEmail ? "true" : "false"}/>
                                <p>{errors.userEmail?.message}</p>
                            </Col>
                        </Row>
                        <Row className='justify-center'>
                            <Col className='text-center mt-4' xs={12}>
                                <button className='bg-btn-color text-white min-w-[200px]' type="submit">Register</button>
                            </Col>
                        </Row>
                    </form>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    };
    return (
        <>
            <Popup className='login-popup' open={isLoginPopUpOpen} closeOnDocumentClick onClose={onLoginClose} modal>
                {onLoginClose => (
                    <div className="modal-inside">
                        <button className="close" onClick={onLoginClose}> &times; </button>
                        <div className="header"></div>
                        <div className="content">
                            {mobileNumberLoginFormSectionResolver()}
                        </div>
                        <div className="actions">
                            <Row>
                                <Col xs={12}><span>Create account using</span></Col>
                            </Row>
                            <Row className='justify-center pt-3'>
                                <div className='w-auto'>
                                    <button
                                        className="button w-[50px] h-[50px] rounded-full bg-white border-white p-[8px] max-md:w-[36px] max-md:h-[36px]"
                                        onClick={() => {
                                            console.log('Google Login Started');
                                            signInWithGoogleSocialLogin();
                                        }}>
                                        <img
                                            src={ic_google}
                                            alt='google'
                                        />
                                    </button>
                                </div>
                                <div className='w-auto'>
                                    <button
                                        className="button w-[50px] h-[50px] rounded-full bg-white border-white p-[8px] max-md:w-[36px] max-md:h-[36px]"
                                        onClick={() => {
                                            console.log('Facebook Login Started');
                                            signInWithFacebookSocialLogin();
                                        }}>
                                        <img
                                            src={ic_facebook}
                                            alt='google'
                                        />
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )
};

