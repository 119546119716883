import React, { useEffect, useState, useRef } from 'react'
import Product from '../Product/Product'
import ProductFilter from './ProductFilter/ProductFilter'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './ProductListingPage.scss';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector, connect, useStore } from 'react-redux';
import {
    getProductListFeaturedThunk,
    getProductListSubCategoryThunk,
    getProductListCollectionThunk,
    getProductListBrandThunk,
    getProductListSimilarThunk,
    getProductListOfferThunk,
    getProductListSearchThunk,
    getProductListFeaturedMoreThunk,
    getProductListSubCategoryMoreThunk,
    getProductListCollectionMoreThunk,
    getProductListSimilarMoreThunk,
    getProductListBrandMoreThunk,
    getProductListOfferMoreThunk,
    getProductListSearchMoreThunk
} from '../../redux/subCategory';
import MainLoader from '../Common/Loaders/MainLoader';
import MobileProductFilter from './ProductFilter/MobileProductFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useHref } from 'react-router-dom';
import { Spinner } from 'react-activity';


function ProductListingPage({ subCategory }) {
    const dispatch = useDispatch();
    const fullUrl = useHref();
    const urlSplit = fullUrl.split("/");
    const [showComponent, setShowComponent] = useState(false);
    const [show, setShow] = useState(false);
    const store = useStore();
    const {
        loader, loadMoreLoader, noProducts,
        featuredProducts, featuredInfo,
        categoryProducts, categoryInfo, subCategoryInfo,
        collectionProducts, collectionInfo,
        brandProducts, brandInfo,
        similarProducts, offerProducts, searchProducts,
        selectedFlags, selectedSortOrder, selectedFulfillment, selectedBrands,
        selectedMinPrice, selectedMaxPrice, selectedOffers,
        selectedCollections, selectedTags, finalFilterData, loadmore
    } = useSelector((state) => state.subCategory);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
    const selectedFilterValues = useRef({});
    let pageNumber = 1;
    useEffect(() => {
        window.scrollTo(0, 0);
        selectedFilterValues.current = {}
        fetchSelectedFilterProductList();
        return
    }, [subCategory]);

    const fetchSelectedFilterProductList = () => {

        if (Object.keys(selectedFilterValues.current).length === 0) {
            const requestData = {
                "page": pageNumber,
                "speedy": 0,
                "from_price": 0,
                "to_price": 3999,
                "tag_id": JSON.stringify([]),
                "offer_id": JSON.stringify([]),
            }
            if (subCategory.type === 'featured_list') {
                requestData['featured_list_id'] = subCategory.id;
                requestData['handle'] = subCategory.handle;
                requestData['flag'] = JSON.stringify([1]);
                requestData['brand_id'] = JSON.stringify([]);
            } else if (subCategory.type === 'sub_categories') {
                requestData['sub_category_id'] = subCategory.id;
                requestData['handle_sub_category'] = subCategory.handle;
                requestData['handle_category'] = findCategoryHandle();
                requestData['flag'] = JSON.stringify([1]);
                requestData['brand_id'] = JSON.stringify([]);
            } else if (subCategory.type === 'collections') {
                // requestData['collection_mapping_id'] = subCategory.id;
                requestData['handle'] = subCategory.handle;
                requestData['flag'] = JSON.stringify([1]);
                requestData['brand_id'] = JSON.stringify([]);
            } else if (subCategory.type === 'similar_products') {
                requestData['product_id'] = subCategory.id;
                requestData['filter_flag'] = JSON.stringify([1]);
                requestData['flag'] = 1;
                requestData['brand_id'] = JSON.stringify([]);
            } else if (subCategory.type === 'brands') {
                requestData['brand_id'] = subCategory.id;
                requestData['handle'] = subCategory.handle;
                requestData['flag'] = JSON.stringify([1]);
            } else if (subCategory.type === 'search') {
                requestData['search'] = subCategory.handle;
                requestData['flag'] = JSON.stringify([1]);
                requestData['brand_id'] = JSON.stringify([]);
            }
            selectedFilterValues.current = requestData;
        }
        processSelectedFilterProductList(selectedFilterValues.current);
    };

    const findCategoryHandle = () => {
        if (urlSplit.length > 2) {
            return urlSplit[1]
        } else {
            return ""
        }
    }

    const onChangeselectedFilterOptions = (newFiltersSelected) => {
        pageNumber = 1;
        newFiltersSelected['page'] = 1;
        selectedFilterValues.current = newFiltersSelected;
        processSelectedFilterProductList(newFiltersSelected);
    }

    const onLoadMoreProducts = (e) => {
        e.preventDefault()
        let presentPostData = selectedFilterValues.current;
        pageNumber = parseInt(presentPostData['page']);
        presentPostData['page'] = pageNumber + 1;
        selectedFilterValues.current = presentPostData;
        processSelectedFilterProductListMore(presentPostData);
    }

    const processSelectedFilterProductList = (requestData) => {
        if (subCategory.type === 'featured_list') {
            dispatch(getProductListFeaturedThunk(requestData))
        } else if (subCategory.type === 'sub_categories') {
            dispatch(getProductListSubCategoryThunk(requestData))
        } else if (subCategory.type === 'collections') {
            dispatch(getProductListCollectionThunk(requestData))
        } else if (subCategory.type === 'similar_products') {
            dispatch(getProductListSimilarThunk(requestData))
        } else if (subCategory.type === 'brands') {
            dispatch(getProductListBrandThunk(requestData))
        } else if (subCategory.type === 'offers') {
            dispatch(getProductListOfferThunk(requestData))
        } else if (subCategory.type === 'search') {
            dispatch(getProductListSearchThunk(requestData))
        }
    };

    const processSelectedFilterProductListMore = (requestData) => {
        if (subCategory.type === 'featured_list') {
            dispatch(getProductListFeaturedMoreThunk(requestData))
        } else if (subCategory.type === 'sub_categories') {
            dispatch(getProductListSubCategoryMoreThunk(requestData))
        } else if (subCategory.type === 'collections') {
            dispatch(getProductListCollectionMoreThunk(requestData))
        } else if (subCategory.type === 'similar_products') {
            dispatch(getProductListSimilarMoreThunk(requestData))
        } else if (subCategory.type === 'brands') {
            dispatch(getProductListBrandMoreThunk(requestData))
        } else if (subCategory.type === 'offers') {
            dispatch(getProductListOfferMoreThunk(requestData))
        } else if (subCategory.type === 'search') {
            dispatch(getProductListSearchMoreThunk(requestData))
        }
    };

    const { subcategories } = useSelector((state) => state.subCategory);

    // useEffect(() => {
    //     dispatch(getProductListSubCategoryThunk());
    //     return;
    // }, []);

    const breadCrumbFormate = (handle) => {
        const words = handle.split('-');
        const formattedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return formattedString
    }

    const processMainInfoArea = () => {
        let infoArray = [];
        if (subCategory.type === 'featured_list') {
            infoArray = featuredInfo;
        } else if (subCategory.type === 'sub_categories') {
            infoArray = categoryInfo;
        } else if (subCategory.type === 'collections') {
            infoArray = collectionInfo;
        } else if (subCategory.type === 'brands') {
            infoArray = brandInfo;
        }
        if (infoArray.length > 0) {
            return (
                <>
                    <Container>
                        <Row>
                            <Col className='max-lg:hidden'>
                                <ul className='flex gap-2 py-2 text-xs'>
                                    {
                                        urlSplit.map((split, index) => {
                                            if (index === 0) {
                                                return (
                                                    <li key={index}><Link to="/">Home <span><FontAwesomeIcon icon={faAngleRight} /></span></Link></li>
                                                )
                                            } else if ((urlSplit.length - 1) === index) {
                                                return (
                                                    <li key={index} className='opacity-50'>{breadCrumbFormate(split)}</li>
                                                )
                                            } else {
                                                return (
                                                    <li key={index}><Link to={split}>{breadCrumbFormate(split)} <span><FontAwesomeIcon icon={faAngleRight} /></span></Link></li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className='md:hidden'>
                        <Container>
                            <Row>
                                <Col className='max-md:px-0'>
                                    <div className="flex items-center justify-between">
                                        <h1 className="text-lg font-medium">{subCategory.type === 'collections' ? infoArray[0].title : infoArray[0].name}</h1>
                                        <div className='actions flex gap-3'>
                                            <button onClick={handleSubmit}
                                                className='flex gap-2 items-center px-0 h-auto bg-transparent text-sm font-light text-black border-0'>
                                                Filter
                                                <svg className='w-2.5' viewBox="0 0 22.168 24.42">
                                                    <path data-name="Path 46"
                                                        d="M1.38 0h19.289a1.5 1.5 0 0 1 1.135 2.47l-8.324 9.74v10.4l-5.22 1.806v-12.21L.302 2.23A1.376 1.376 0 0 1 1.38 0Z"
                                                        fill="#575756" />
                                                </svg>
                                            </button>
                                            <button onClick={handleSubmit}
                                                className='flex gap-2 items-center px-0 h-auto bg-transparent text-sm font-light text-black border-0'>
                                                Sort
                                                <svg className='w-3' viewBox="0 0 35.487 24.578">
                                                    <g data-name="Group 65" fill="none" stroke="#575756"
                                                        strokeLinecap="round" strokeWidth="5">
                                                        <path data-name="Line 6" d="M0 0h30.487"
                                                            transform="translate(2.5 2.5)" />
                                                        <path data-name="Line 7" d="M5 10.173h20.488"
                                                            transform="translate(2.5 2.5)" />
                                                        <path data-name="Line 8" d="M10.037 19.578h10.414"
                                                            transform="translate(2.5 2.5)" />
                                                    </g>
                                                </svg>

                                            </button>
                                        </div>
                                    </div>
                                    <ul className="flex gap-2 text-m whitespace-nowrap py-2 overflow-auto touch-auto subcategorylist">
                                        {subcategories.map((subcategorylist) => {
                                            return (
                                                <>
                                                    <li className="border border-black text-black rounded-full py-2 px-3 flex items-center text-sm font-light">
                                                        <Link to={subcategorylist.handle}>
                                                            {subcategorylist.name}
                                                        </Link>
                                                    </li>
                                                </>
                                            );
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container fluid className="Collection_page_banner max-md:hidden">
                        <Container>
                            <Row>
                                <Col className='md:hidden'>
                                    <div className="ltr">
                                        <h1 className="text-2xl ms-8">{subCategory.type === 'collections' ? infoArray[0].title : infoArray[0].name}</h1>
                                    </div>
                                    <ul className="flex gap-4  text-m whitespace-nowrap py-2">
                                        {subcategories.map((subcategorylist) => {
                                            return (
                                                <>
                                                    <li className=" bg-slate-200 text-black  rounded-lg  pl-2 pr-2">
                                                        <Link to={subcategorylist.handle}>
                                                            {subcategorylist.name}
                                                        </Link>
                                                    </li>
                                                </>
                                            );
                                        })}
                                    </ul>
                                </Col>
                                <Col>
                                    <img
                                        className="w-full"
                                        src={subCategory.type === 'collections' ? infoArray[0].image : infoArray[0].cat_banner_image}
                                        alt=""
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    };

    function handleSubmit() {
        setShow(true)
        setShowComponent(!showComponent);
    }

    const processMainProductListArea = () => {
        let listedProducts = [];
        if (subCategory.type === 'featured_list') {
            listedProducts = store.getState().subCategory.featuredProducts;
        } else if (subCategory.type === 'sub_categories') {
            listedProducts = store.getState().subCategory.categoryProducts;
        } else if (subCategory.type === 'collections') {
            listedProducts = store.getState().subCategory.collectionProducts;
        } else if (subCategory.type === 'similar_products') {
            listedProducts = store.getState().subCategory.similarProducts;
        } else if (subCategory.type === 'brands') {
            listedProducts = store.getState().subCategory.brandProducts;
        } else if (subCategory.type === 'offers') {
            listedProducts = store.getState().subCategory.offerProducts;
        } else if (subCategory.type === 'search') {
            listedProducts = store.getState().subCategory.searchProducts;
        }
        window.dataLayer.push(
            {
                'event': 'view_item_list',
                "ecommerce": {
                    "item_list_id": subCategory.id,
                    "item_list_name": subCategory.handle,
                    "items": listedProducts,
                }
            }
        );
        return (
            <>
                {listedProducts.map((product, i) => {
                    return (
                        <Col xs={12} className='px-0' key={i}>
                            <Product productData={product} handle={subCategory.handle} />
                        </Col>
                    );
                })}
            </>
        );
    };

    return (
        <>

            {

                loader ? <MainLoader size={50} /> :
                    <>
                        {processMainInfoArea()}
                        <Container fluid>
                            <Container>
                                <Row>
                                    <Col
                                        xs={12}
                                        md={3}
                                        lg={3}
                                        xxl={2}
                                        className="filter_main_wrapper max-md:px-0 max-md:hidden"
                                    >
                                        <ProductFilter
                                            target={{
                                                id: subCategory.id,
                                                handle: subCategory.handle,
                                                type: subCategory.type
                                            }}
                                            selectedFilterValues={selectedFilterValues.current}
                                            onChangeselectedFilterOptions={onChangeselectedFilterOptions}
                                        />
                                        {
                                            showComponent &&
                                            <MobileProductFilter
                                                openpopup={true}
                                                target={{
                                                    id: subCategory.id,
                                                    handle: subCategory.handle,
                                                    type: subCategory.type
                                                }}
                                                selectedFilterValues={selectedFilterValues.current}
                                                onChangeselectedFilterOptions={onChangeselectedFilterOptions}
                                            />
                                        }

                                    </Col>
                                    <Col
                                        xs={12}
                                        md={9}
                                        lg={9}
                                        xxl={10}
                                        className="product_grid lg:pl-12 max-md:px-0"
                                    >
                                        <Row
                                            className={
                                                "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 mx-0"
                                            }
                                        >
                                            {processMainProductListArea()}
                                        </Row>
                                        {
                                            noProducts &&
                                            <div className='flex justify-center items-center mx-auto mt-6 h-auto pt-2 text-sm'>
                                                <p>No Product Found</p>
                                            </div>
                                        }
                                        {
                                            loadmore &&
                                            <div className='flex justify-center items-center mx-auto mt-2 h-auto pt-2 text-xs'>
                                                {
                                                    loadMoreLoader ? <Spinner size={30}/> : <button onClick={onLoadMoreProducts} >Load More</button>
                                                }
                                            </div>

                                        }
                                    </Col>

                                </Row>
                            </Container>
                        </Container>
                    </>
            }
        </>
    );
}

export default ProductListingPage;