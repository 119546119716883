import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getOrderDetails} from '../../redux/order';
import {Container} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import order_confirm from '../../assets/images/order_confirm.png'
import icon_location from '../../assets/images/icons/icon_location.png'
import {useLocation} from 'react-router-dom';
import "./Ordercomplet.scss";

const OrderComplete = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {state} = useLocation();
    const {orderDetails, orderProducts} = useSelector((state) => state.order);
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("state in ordercomplete", state);
        console.log("id in ordercomplete", state.orderId);
        const data = {
            "order_id": state.orderId
        }
        dispatch(getOrderDetails(data))
        return
    }, [])
    useEffect(()=>{
        if(orderDetails.total_payable !== undefined){
            window.dataLayer.push(
                {
                    'event': 'purchase',
                    'ecommerce': {
                        "currency": "AED",
                        "transaction_id": null,
                        "value": orderDetails.total_payable !== undefined ? orderDetails.total_payable : 0,
                        "shipping": orderDetails.shipping_charge !== undefined ? orderDetails.shipping_charge : 0,
                        "coupon": orderDetails.total_coupon_discount !== undefined ? orderDetails.total_coupon_discount : 0,
                        "items": orderProducts,
                      },
                }
            );
        }
    },[orderDetails])
    const proceedAction = () => {
        navigate('/vieworder', {state: {orderId: state.orderId}});
    }

    return (<>
            <Container className='px-0'>
                {Object.keys(orderDetails).length > 0 &&
                    <div className='order-success-container text-center border-t pt-10'>
                        <div className='order-success-header'>
                            <div className='icon text-center inline-block'>
                                <img
                                    src={order_confirm}
                                    alt='order_confirm'
                                    width={60}
                                    height={60}
                                />
                            </div>
                            <p className='font-bold text-xl mt-2'>Order Placed Thanks!</p>
                            <p>A confirmation will send to your email</p>
                            <div className='inline-flex'>
                                <label>Order Id: </label>
                                <span>{orderDetails.order_no}</span>
                            </div>
                            <div className='block text-center'>
                                <button
                                    className='inline-block font-normal text-base text-center underline cursor-pointer md:mt-2 text-[#41BDDE] hover:text-[#41BDDE] h-auto bg-transparent border-0'
                                    onClick={proceedAction}>ViewOrder
                                </button>
                            </div>
                        </div>
                        <div className='text-start'>
                            <div className='order-summary max-md:mt-8'>
                                <p className='pb-2 text-start text-xl font-medium border-b max-md:px-3 max-md:text-base'>Order
                                    Summary</p>
                                <fieldset
                                    className='fieldset py-2 border-solid border-[#e5e7eb] border-x-0 border-y-0 border-b md:px-4 mb-2 max-md:px-3'>
                                    <div className='field'>
                                        <label>Subtotal </label>
                                        <span> AED {orderDetails.items_total}</span>
                                    </div>
                                    <div className='field'>
                                        <label>Shipping </label>
                                        <span> AED {orderDetails.shipping_charge}</span>
                                    </div>
                                </fieldset>
                                <strong
                                    className='font-medium text-xl max-md:px-3 max-md:text-sm max-md:flex max-md:justify-between max-md:mb-10'>Total
                                    Amount:AED <span>{orderDetails.total_payable}</span></strong>
                            </div>
                            {/* <div className='flex gap-2 items-center mt-2 px-3'>
                                <img
                                    src={icon_location}
                                    alt='icon_location'
                                    width={14}
                                    height={14}
                                />
                                <span className='font-medium'>Delivery</span>
                            </div>
                            <p className='mt-1 text-sm px-3'>Expect Your Order To Deliver By</p>
                            <p className='mt-8 font-medium px-3'>{orderProducts.delivery_date}</p> */}
                            <div className='flex justify-end items-center px-3'>
                                <button className='h-[44px] text-base max-md:w-full' onClick={() => {
                                    navigate('/');
                                }}>Continue Shopping
                                </button>
                            </div>
                        </div>
                    </div>}
            </Container>

        </>

    )
}

export default OrderComplete


