import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAboutus, getTermscondition } from '../../redux/customerservices';
import { Container } from 'react-bootstrap';

function Aboutus() {

    const dispatch = useDispatch();
    const { AboutUs } = useSelector((state) => state.customerServices);
  
    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(getAboutus())
      return
    }, []);

  return (
    <div>
       <Container>
      <div>
        <strong>
            About Us
        </strong>

        {
            AboutUs.map((aboutusData,index) =>(
                <div key={index}>
                     <div dangerouslySetInnerHTML={{ __html: aboutusData.description }}/>
                </div>
            )
            )
        }

        {/* {Terms.map((details) => {
          return (
            <div dangerouslySetInnerHTML={{ __html: details.description }} />
          );
        })} */}

      </div>
    </Container>
    </div>
  )
}

export default Aboutus
