import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { writeReview } from '../../../services/apiCalls';
import { FaStar } from 'react-icons/fa';

function Review({ id, showpopup }) {

  const [radio, setRadio] = useState(0);
  const [show, setShow] = useState(true);
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [fullscreen, setFullscreen] = useState(true);

  const stars = Array(5).fill(0)
  const [formData, setFormData] = useState({
    reviewfirstname: "",
    reviewlastname: "",
    reviewemail: "",
    reviewfeedback: ""
  })

  useEffect(() => {
    setShow(showpopup)
  }, []);

  const handlesubmit = async () => {

    if (formData.reviewfirstname === "" || formData.reviewlastname === "" || formData.reviewemail === "" || currentValue == 0) {
      toast.error("Please fill the required details", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setShow(true)
      return
    } else {
      const data = {
        "product_id": id,
        "name": formData.reviewfirstname + " " + formData.reviewlastname,
        "email": formData.reviewemail,
        "rating": currentValue,
        "review_body": formData.reviewfeedback,
        "review_title": "test"
      }
      const reviewData = await writeReview(data)
      setShow(false)
      if (reviewData.data.code === 1) {
        toast.success("Review Added successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Review Not added", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }



  const handleClick = value => {
    setCurrentValue(value)
  }

  const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"

  };

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const changedValue = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

  }
  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  return (
    <div>
      <Modal className='customer-review' show={show} fullscreen={fullscreen}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='customer-review-form'>
            <label>FirstName : </label>
            <input
              value={formData.reviewfirstname}
              onChange={changedValue}
              type="text"
              id="review-firstname"
              name="reviewfirstname"
              placeholder='FirstName'
              required

            />
            <label>LastName : </label>
            <input
              value={formData.reviewlastname}
              onChange={changedValue}
              type="text"
              id="review-lastname"
              name="reviewlastname"
              placeholder='LastName'
              required

            />
            <label>E-mail : </label>
            <input
              onChange={changedValue}
              value={formData.reviewemail}
              type="text"
              id="review-email"
              name="reviewemail"
              placeholder='Email'
              className='col-span-2'
              required

            />
            <div className='flex star-rating'>
              <span className='label'>Rating</span>
              <div className='star flex gap-2 py-2 px-3 bg-white rounded-full'>
                {stars.map((_, index) => {
                  return (
                    <FaStar
                      key={index}
                      size={24}
                      onClick={() => handleClick(index + 1)}
                      onMouseOver={() => handleMouseOver(index + 1)}
                      onMouseLeave={handleMouseLeave}
                      color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                      style={{
                        marginRight: 0,
                        cursor: "pointer"
                      }}
                      required
                    />
                  )
                })}
              </div>
            </div>
            <div className='feedback'>
              <span className='label'>FeedBack </span>
              <textarea
                value={formData.reviewfeedback}
                onChange={changedValue}
                name='reviewfeedback'
                placeholder='Write your comments here'
              />
            </div>

          </form>
          <div className='actions text-center'>
          <Button onClick={() => { handlesubmit() }} type='submit' className='h-36px'>Submit</Button  >
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Review
