import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCartPage } from '../../redux/cartRedux';
import { addToCart } from '../../services/apiCalls';
import { toast } from 'react-toastify';
import { Spinner } from 'react-activity';

const isTab = window.innerWidth <= 991;

function CartProduct({ product }) {
    const dispatch = useDispatch();
    const { cartpageBilling, cartpageItems } = useSelector((state) => state.cart);
    const [addLoader, setAddLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const increamentCartAction = async (cartProduct) => {
        setAddLoader(true)
        const data = {
            "product_id": cartProduct.product_id,
            "qty": 1,
            "mrp": cartProduct.mrp,
            "selling_price": cartProduct.selling_price,
            "action": "1",
            "flag": "1"
        }
        const addtocartData = await addToCart(data)
        if (addtocartData.data.code === 1) {
            setAddLoader(false)
            dispatch(getCartPage())
        } else {
            setAddLoader(false)
            toast.error(addtocartData.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    };

    const decreamentCartAction = async (cartProduct) => {
        setRemoveLoader(true)
        const data = {
            "product_id": cartProduct.product_id,
            "qty": 1,
            "mrp": cartProduct.mrp,
            "selling_price": cartProduct.selling_price,
            "action": "2",
            "flag": "1"
        }
        const addtocartData = await addToCart(data)
        if (addtocartData.data.code === 1) {
            if (cartProduct.qty === "1") {
                toast.error("Product Removed from Cart", {
                    position: toast.POSITION.TOP_RIGHT, autoClose: 2000,

                });
                window.dataLayer.push(
                    {
                        'event': 'remove_from_cart',
                        'ecommerce': {
                            "currency": "AED",
                            "value": cartpageBilling !== undefined ? cartpageBilling.total_payable : 0,
                            "items": cartpageItems,
                        }
                    }
                );
            }
            setRemoveLoader(false)
            dispatch(getCartPage())
        } else {
            setRemoveLoader(false)
            toast.error(addtocartData.data.msg, {
                position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
            })
        }
    };
    return (<>
        {isTab ?
            (<li className="mob flex items-center max-md:items-start max-lg:mb-6 last-of-type:mb-0">
                <div className="w-full max-w-[60px]">
                    {product.product_images.map((productImage, index) => {
                        return (<img
                            src={productImage.file}
                            alt className="w-full h-full object-center rounded-[30px] object-cover"
                            key={index} />)
                    })}
                </div>
                <div className="max-lg:px-3">
                    <div className="flex items-center justify-between w-full pt-1">
                        <p className="text-[20px] font-light leading-none text-gray-800 max-md:text-xs">{product.name}</p>
                    </div>
                    <div
                        className="flex items-center justify-start pt-10 pr-6 mt-auto max-md:pt-2 max-md:justify-start gap-x-2">
                        <p className="text-[25px] font-bold leading-none text-gray-800 max-md:text-xs">AED {product.selling_price}</p>
                        {parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                            <p className='cutoff-mrp max-md:text-xs text-xl line-through text-gray-400'>AED {product.mrp}</p>}
                    </div>
                </div>
                <div className='flex border-1 rounded-md border-btn-color max-md:items-center ml-auto'>
                    <span className='font-light mr-3 sr-only'>Quantity</span>
                    {
                        removeLoader ? <Spinner /> : <button
                            className='rounded-full w-7 h-7 bg-transparent border-none hover:border-none md:border p-0 text-base text-black max-md:border-0'
                            onClick={(e) => {
                                decreamentCartAction(product)
                            }}>-
                        </button>
                    }
                    <p className='inline-flex items-center justify-center w-9 h-7 text-center'>{product.qty}</p>
                    {
                        addLoader ? <Spinner /> : <button
                            className='rounded-full w-7 h-7 bg-transparent border-none hover:border-none md:border p-0 text-base text-black max-md:border-0'
                            onClick={(e) => {
                                increamentCartAction(product)
                            }}>+
                        </button>
                    }
                </div>
            </li>) :
            (<li className="desktop max-md:hidden flex items-center pt-6 max-md:items-start max-md:pt-0">
                <div className="w-1/6">
                    {product.product_images.map((productImage, index) => {
                        return (<img
                            src={productImage.file}
                            alt className="w-full h-full object-center rounded-[30px] object-cover"
                            key={index} />)
                    })}
                </div>
                <div className="md:pl-6 md:w-5/6 h-full">
                    <div className="flex items-center justify-between w-full pt-1">
                        <p className="text-[20px] font-light leading-none text-gray-800 max-md:text-xs">{product.name}</p>
                    </div>
                    <div
                        className="flex items-center justify-start pt-10 pr-6 mt-auto max-md:pt-2 max-md:justify-start gap-x-2">
                        <p className="text-[25px] font-bold leading-none text-gray-800 max-md:text-xs">AED {product.selling_price}</p>
                        {parseFloat(product.mrp.replace(",", "")) > parseFloat(product.selling_price.replace(",", "")) &&
                            <p className='cutoff-mrp max-md:text-xs text-xl line-through text-gray-400'>AED {product.mrp}</p>}
                    </div>
                </div>
                <div className='flex max-md:flex-col-reverse max-md:items-center'>
                    <span className='font-light mr-3'>Quantity</span>
                    {
                        removeLoader ? <Spinner /> : <button
                            className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                            onClick={(e) => {
                                decreamentCartAction(product)
                            }}>-
                        </button>
                    }
                    <p className='inline-flex items-center justify-center w-9 h-7 text-center'>{product.qty}</p>
                    {
                        addLoader ? <Spinner /> : <button
                            className='rounded-full w-7 h-7 bg-transparent border-black md:border p-0 text-base text-black max-md:border-0'
                            onClick={(e) => {
                                increamentCartAction(product)
                            }}>+
                        </button>
                    }
                </div>
            </li>)}
    </>)
}

export default CartProduct
