import React from 'react'
import { Link } from 'react-router-dom';

function PharmacyDeals({ deals }) {
    return (
        <div className='container-fluid'>
            <div className='container'>
                <h2 className='text-[25px] font-bold mb-6'>{deals.name}</h2>
                <div className='row cotegory_listing'>
                    <ul>
                        {deals.discover_more.map((element, index) => {
                            return (
                                <li key={index}>
                                    <Link to={element.redirect_url}>
                                        <img
                                            alt=""
                                            src={element.image}
                                            width="auto"
                                            height="auto"
                                            className="d-inline-block align-end"
                                        // onError={() => this.img.src = {error_image}}
                                        /></Link>
                                </li>
                            );
                        })}

                    </ul>

                </div>
            </div>
        </div>
    )
}

export default PharmacyDeals
