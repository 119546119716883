import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUserAddress, getAddressData } from '../../../redux/profile';
import { AiOutlinePlus } from 'react-icons/ai';
import { Menu } from '@headlessui/react'
import { editAddress } from '../../../services/apiCalls';
import { async } from '@firebase/util';

function MyaddressMobileview() {
    const { addressList, selectedAddress } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const addAddressURL = '/add-address';
    const editAddressURL = '/edit-address';

    const navigate = useNavigate();
    const [fullscreen, setFullscreen] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAddressValue, setselectedAddressValue] = useState(null);

    const handleAddAddressClick = (targetUrl) => {
        navigate(targetUrl);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        dispatch(getAddressData());


    };

    const handleConfirmDelete = (userID) => {
        dispatch(deleteUserAddress(userID));
        dispatch(getAddressData());
        handleCloseDeleteModal();
        toast.success("Address Deleted Successfully", {
            position: toast.POSITION.TOP_RIGHT, autoClose: 2000,
        });

    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAddressData());
    }, []);

    useEffect(() => {
        dispatch(getAddressData());


    }, [showDeleteModal])

    const sortedAddressList = addressList.slice().sort((a, b) => {
        if (a.is_default === "1" && b.is_default !== "1") return -1;
        if (a.is_default !== "1" && b.is_default === "1") return 1;
        return 0;
    });

    const changeSelectedAddress = async (address) => {
        if (address.is_default !== "1") {
            let reqData = JSON.parse(JSON.stringify(address));
            reqData['address_id'] = address.id;
            reqData['is_default'] = "1";
            const editAddressData = await editAddress(reqData)
            if(editAddressData.data.code === 1){
                dispatch(getAddressData());
            }
        }
    }

    return (<div className="container">
        <div className="flex justify-between">
            <h2 className="text-3xl font-medium sr-only">Addresses</h2>
            <button
                className="font-normal h-auto text-sm px-3.5 py-2.5 fixed bottom-24 right-6 z-50 flex gap-2 items-center"
                onClick={() => handleAddAddressClick(addAddressURL)}
            >
                <AiOutlinePlus className='text-xl' />
                Add
            </button>
        </div>
        <div className="text-xl font-medium mt-3.5 mb-2 sr-only">Default Address</div>
        <div className="mob address-items">
            {sortedAddressList.map((address, index) => {
                if (address.id !== 'new') {
                    const isDefaultAddress = address.is_default === "1";
                    const addressType = address.address_type === "1" ? 'Home' : address.address_type === "2" ? 'Office' : address.address_type === "3" ? 'Other' : "";

                    return (<div className={`address-item ${isDefaultAddress ? 'default-address' : ''}`} key={index} onClick={() => {
                        changeSelectedAddress(address)
                    }}>
                        <div>
                            <div className="address-type sr-only">
                                <span>{addressType}</span>
                            </div>
                            <div className="name">
                                <label>Name</label> <span>{`${address.f_name} ${address.l_name}`}</span>
                            </div>
                            <div className="address">
                                <label>Address</label>
                                <span>{`${address.building_name}, ${address.full_address}`}</span>
                            </div>
                            <div className="phone">
                                <label>Phone</label>
                                <span>Mobile : {address.mobile}</span>
                            </div>
                        </div>
                        <div className="actions flex-col justify-between mb-0">
                            <Menu>
                                <Menu.Button as='div'>
                                    <svg className='h-6' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
                                            fill-rule="evenodd" />
                                    </svg>
                                    <span className='sr-only'>More</span>
                                </Menu.Button>
                                <Menu.Items className='actions-body'>
                                    <Menu.Item>
                                        {({ active }) => (<Link
                                            className={`btn flex items-center gap-2 setasdefault ${active && ''}`}
                                        >
                                            <svg className='w-5' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="none" stroke="#000" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="M3.7 14.3 9.6 19 20.3 5" />
                                            </svg>

                                            Set as default
                                        </Link>)}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (<Link
                                            className={`${active && ''} btn`}
                                            to={{
                                                pathname: editAddressURL,
                                            }}
                                            state={{ existingData: address }}
                                        >
                                            Edit
                                        </Link>)}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (<button
                                            className={`${active && 'btn'} btn`}
                                            onClick={() => {
                                                setselectedAddressValue(address);
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            Delete
                                        </button>)}
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                            {
                                selectedAddress.id === address.id &&
                                <div className=''><svg class="w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.7 14.3 9.6 19 20.3 5"></path></svg></div>
                            }

                        </div>

                    </div>);
                }
                return null;
            })}
        </div>

        <Modal
            className="delete-address"
            show={showDeleteModal}
            fullscreen={fullscreen}
            onHide={handleCloseDeleteModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Delete Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the address?</p>
                <div className="actions">
                    <Button onClick={handleCloseDeleteModal}>Cancel</Button>
                    <Button onClick={() => handleConfirmDelete(selectedAddressValue.id)}>Confirm</Button>
                </div>
            </Modal.Body>
        </Modal>
    </div>);
}

export default MyaddressMobileview
