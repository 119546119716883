import {useDispatch} from 'react-redux';
import React, {useEffect, useState} from 'react'
import Cart from './Cart';
import {getCartPage, getCartPagePrecription} from '../../redux/cartRedux';
import Precriptioncart from './Precriptioncart';
import {Container} from "react-bootstrap";
import { getAddressData } from '../../redux/profile';

function CartPage() {
    const dispatch = useDispatch();
    const [togglestate, settogglestate] = useState("mycart");

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCartPage())
        dispatch(getAddressData())
    }, [])
    useEffect(() => {
        dispatch(getCartPagePrecription())
    }, [])

    const toggleTab = (toggleData) => {
        settogglestate(toggleData)
        console.log("gfdgdf", togglestate);
    }

    return (
        <>
            <Container className='max-md:px-0'>
                <div className='w-full flex items-center justify-center mb-8 max-md:mb-6'>
                    <button className={togglestate === "mycart" ?
                        "active block-tabs-cart bg-transparent rounded-0 border-x-0 hover:border-x-0 border-t-0 hover:border-t-0 border-b-1 border-cyan-300 text-[#41BDDE] px-4 max-md:text-sm max-md:h-auto max-md:py-2 max-md:w-1/2" :
                        "block-tabs-cart bg-transparent text-[gray] rounded-0 border-0 px-4 max-md:text-sm max-md:h-auto max-md:py-2 max-md:w-1/2"}
                            onClick={() => toggleTab('mycart')}>My Cart
                    </button>
                    <button className={togglestate === "precriptioncart" ?
                        "active block-tabs-cart bg-transparent rounded-0 border-x-0 hover:border-x-0 border-t-0 hover:border-t-0 border-b-1 border-cyan-300 text-[#41BDDE] px-4 max-md:text-sm max-md:h-auto max-md:py-2 max-md:w-1/2" :
                        "block-tabs-cart bg-transparent text-[gray] rounded-0 border-0 px-4 max-md:text-sm max-md:h-auto max-md:py-2 max-md:w-1/2"}
                            onClick={() => toggleTab('precriptioncart')}>Precription Cart
                    </button>
                </div>
                {
                    togglestate === "mycart" ?
                        <Cart/> : <Precriptioncart/>
                }
            </Container>
        </>
    )
}

export default CartPage
