import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cancelOrderApiCall, getOrderList, getAddress, getOrderViewDetails, addAddress, getEmirates, editAddress, deleteAddress, addPrecriptionDataApicall, getPrecriptionStatusApicall, getPrescriptionDetailsApicall, updateProfileData, getInterests, updateInterest, getUserNotificationsApi, getUserProfileDetailsApi } from "../services/apiCalls";


export const getMyOrder = createAsyncThunk("Order/getProfileOrder", async () => {
    const res = await getOrderList();
    return res.data;
})
export const getAddressData = createAsyncThunk("Profile/Address", async () => {
    const res = await getAddress();
    return res.data;
})

export const customerOrderCancel = createAsyncThunk("Order/cancleOrder", async (paramData) => {
    const res = await cancelOrderApiCall(paramData);
    return res.data;
})

export const getOrderDetails = createAsyncThunk("Order/getOrderDetails", async (orderId) => {
    const res = await getOrderViewDetails(orderId);
    return res.data;
})
export const setAddressData = createAsyncThunk("Profile/AddAddress", async (addressData) => {
    const res = await addAddress(addressData);
    return res.data;
})
export const getEmiratesData = createAsyncThunk("Profile/Emirates", async () => {
    const res = await getEmirates();
    return res.data;
})
export const addPrecriptionData = createAsyncThunk("Product/addPrescription", async (data) => {
    const res = await addPrecriptionDataApicall(data);
    console.log('data from redux ', data);
    return res.data;
})
export const getPrecriptionStatus = createAsyncThunk("Product/getPrescription", async () => {
    const res = await getPrecriptionStatusApicall();
    console.log("res ", res.data);
    return res.data;
})
export const setEditedAddressData = createAsyncThunk("Profile/EditAddress", async (data) => {
    const res = await editAddress(data);
    return res.data;
})
export const deleteUserAddress = createAsyncThunk("Profile/DeleteAddress", async (userID) => {
    const res = await deleteAddress(userID);
    console.log("Result ", res);
    return res.data;
})

export const getPrescriptionDetails = createAsyncThunk("Profile/getPrescriptionDetails", async (id) => {
    const res = await getPrescriptionDetailsApicall(id);
    console.log("Result ", res.data);
    return res.data;
})

export const getUserInteres = createAsyncThunk("Profile/UserInterests", async () => {
    const res = await getInterests();
    console.log("Result ", res);
    return res.data;
})
export const updateInterestData = createAsyncThunk("Profile/UpdateUserInterests", async (data) => {
    const res = await updateInterest(data);
    return res.data;
})

export const getUserNotifications = createAsyncThunk("Profile/UserNotifications", async (data) => {
    const res = await getUserNotificationsApi();
    return res.data;
})

export const getUserProfile = createAsyncThunk("Profile/UserProfile", async () => {
    const res = await getUserProfileDetailsApi();
    return res.data;
})



const INITIAL_STATE = {
    activeorders: [],
    addressList: [],
    orderDetails: [],
    setNewAddress: [],
    orderProductDetails: [],
    completeOrders: [],
    getEmiratesList: [],
    ordercancel: {},
    editedAddressData: false,
    userAddressDelete: false,
    PrecriptionData: {},
    PrecriptionStatus: [],
    PrecriptionInfo: [],
    PrecriptionProductData: [],
    selectedAddress: {},
    interestsList:[],
    updateInterest: {},
    getUserNotificationsData: [],
    newAddresssSuccess: null,
    userProfileList:{}
}

const profileSlice = createSlice({
    name: "profile",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            /**
             * Myorder history
             */
            .addCase(getMyOrder.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(getMyOrder.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.activeorders = action.payload.active_orders;
                    state.completeOrders = action.payload.completed_orders
                    console.log("fulfilled", state.activeorders);
                    console.log("completed", action.payload)
                }
                console.log("action ", action)
            })
            .addCase(getMyOrder.rejected, (state, action) => {
                console.log("error api issue dsfds")
            })
            /**
             * My profile address
             */
            .addCase(getAddressData.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(getAddressData.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    const addressListData = action.payload.result;
                    const sortedAddressList = addressListData.slice().sort((a, b) => {
                        if (a.is_default === "1" && b.is_default !== "1") return -1;
                        if (a.is_default !== "1" && b.is_default === "1") return 1;
                        return 0;
                    });
                    state.addressList = sortedAddressList;
                    state.addressList.push({ id: "new" })
                    state.selectedAddress = state.addressList[0]
                }else{
                    state.addressList = []
                    state.addressList.push({ id: "new" })
                    state.selectedAddress = state.addressList[0]
                }
                console.log(state.addressList);
                console.log("completed")
            })
            .addCase(getAddressData.rejected, (state, action) => {
                console.log("error api issue")
            })
            /**
             * Myorder detail page
             */
            .addCase(getOrderDetails.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.orderDetails = action.payload.order_data;
                    state.orderProductDetails = action.payload.order_data_product;
                    console.log("data return", state.orderDetails);
                    console.log("data product return", state.orderProductDetails);
                }
                console.log("completed")
            })
            .addCase(getOrderDetails.rejected, (state, action) => {
                console.log("error api issue")
            })
            /**
             * my profile setAddressData
             */
            .addCase(setAddressData.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(setAddressData.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.setNewAddress = action.payload;
                    state.newAddresssSuccess = true;
                    console.log(state.setNewAddress);
                }
                console.log("completed")
            })
            .addCase(setAddressData.rejected, (state, action) => {
                console.log("error api issue");
                state.newAddresssSuccess = false;
            })
            /**
             * my profile getEmiratesData
             */
            .addCase(getEmiratesData.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(getEmiratesData.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.getEmiratesList = action.payload.result;
                    console.log(state.getEmiratesList);
                }
                console.log("completed")
            })
            .addCase(getEmiratesData.rejected, (state, action) => {
                console.log("error api issue")
            })

            /**
             * orderCancle 
             */
            .addCase(customerOrderCancel.pending, (state, action) => {
                console.log("pending")
            })
            .addCase(customerOrderCancel.fulfilled, (state, action) => {
                console.log("fulfilled")
            })
            .addCase(customerOrderCancel.rejected, (state, action) => {
                console.log("rejected")
            })

            /**
             * my profile edit address
             */

            .addCase(setEditedAddressData.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(setEditedAddressData.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.editedAddressData = true;
                    console.log("Edited completed")
                }
               
            })
            .addCase(setEditedAddressData.rejected, (state, action) => {
                console.log("error api issue")
            })
            /**
             * my profile delete address
             */

            .addCase(deleteUserAddress.pending, (state, action) => {
                console.log("pendning");
            })
            .addCase(deleteUserAddress.fulfilled, (state, action) => {

                if (action.payload.code === 1) {
                    state.userAddressDelete = true;
                }
            })
            .addCase(deleteUserAddress.rejected, (state, action) => {
                console.log("error api issue")
            })
            /**
             * upload precription
             */
            .addCase(addPrecriptionData.pending, (state, action) => {
                console.log("api pending")
            })
            .addCase(addPrecriptionData.fulfilled, (state, action) => {
                console.log("api fulfilled ", action)
            })
            .addCase(addPrecriptionData.rejected, (state, action) => {
                console.log("rejected ", action)
            })
            /**
             * precription status
             */
            .addCase(getPrecriptionStatus.pending, (state, action) => {
                console.log("pending")
            })
            .addCase(getPrecriptionStatus.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log("psylosd", action.payload.result);
                    state.PrecriptionStatus = action.payload.result;
                    console.log("state.PrecriptionStatus ", state.PrecriptionStatus)
                } else {
                    console.log("fghjkl;");
                    state.PrecriptionStatus = {}
                }

            })
            .addCase(getPrecriptionStatus.rejected, (state, action) => {
                console.log("rejected ", action)
            })

            .addCase(getPrescriptionDetails.pending, (state, action) => {
                console.log("pending ", action)
            })
            .addCase(getPrescriptionDetails.fulfilled, (state, action) => {
                state.PrecriptionInfo = action.payload.prescription_info
                state.PrecriptionProductData = action.payload.result
                console.log("fulfilled ", state.PrecriptionProductData)
            })
            .addCase(getPrescriptionDetails.rejected, (state, action) => {
                console.log("rejected ", action)
            })
            /**
             * update progile
             */
           
            /**
             * List Interests
             */
            .addCase(getUserInteres.pending, (state, action) => {
                console.log("pending")
            })
            .addCase(getUserInteres.fulfilled, (state, action) => {
                if(action.payload.code ===1){
                    state.interestsList = action.payload.result;
                    console.log("InterestList : ", action.payload );
                }else{
                    console.log("Interest Request error");
                }
                
            })
            .addCase(getUserInteres.rejected, (state, action) => {
                console.log("Interest rejected ", action)
            })
            /**
             * Update Interests
             */
            .addCase(updateInterestData.pending, (state, action) => {
                console.log("pending")
            })
            .addCase(updateInterestData.fulfilled, (state, action) => {
                if(action.payload.code === 1){
                    state.updateInterest = action.payload.result;
                }else{
                    console.log("Interest Request error");
                }
                
            })
            .addCase(updateInterestData.rejected, (state, action) => {
                console.log("Interest rejected ", action)
            })

            /**
             * Get User Notifications
             */
            .addCase(getUserNotifications.pending, (state, action) => {
                console.log("User Notifications pending")
            })
            .addCase(getUserNotifications.fulfilled, (state, action) => {
                if(action.payload.code === 1){
                    state.getUserNotificationsData = action.payload.users_notification;
                    console.log("Notification data : ",action.payload);
                }else{
                    console.log("User Notifications error");
                }
                
            })
            .addCase(getUserNotifications.rejected, (state, action) => {
                console.log("User Notifications rejected ", action)
            })


            /**
             * Get User ProfileData
             */
            .addCase(getUserProfile.pending, (state, action) => {
                console.log("User Profile pending")
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                if(action.payload.code === 1){
                    state.userProfileList = action.payload.result[0];
                    console.log("Action data : ",action.payload.result[0]);
                }else{
                    console.log("User Profile error");
                }
                
            })
            .addCase(getUserProfile.rejected, (state, action) => {
                console.log("User Profile rejected ", action)
            })



            


    }
})


export default profileSlice.reducer