import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getMyOrder} from '../../redux/profile';
import {Link} from 'react-router-dom'

function MyOrder() {
    const pathURL = '/myProfile';
    const [togglestate, settogglestate] = useState("delivered");
    const dispatch = useDispatch();
    const {activeorders, completeOrders} = useSelector((state) => state.profile);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getMyOrder())
    }, []);
    const toggleTab = (toggleData) => {
        settogglestate(toggleData)
    }
    return (
        <div className='my-order-details'>
            <div className='grid grid-cols-3 text-center items-center'>
                <button className={togglestate === "delivered" ? "active block-tabs-order" : "block-tabs-order"}
                        onClick={() => toggleTab('delivered')}>Delivered
                </button>
                <button className={togglestate === "confirmed" ? "active block-tabs-order" : "block-tabs-order"}
                        onClick={() => toggleTab('confirmed')}>Confirmed
                </button>
                <button className={togglestate === "cancelled" ? "active block-tabs-order" : "block-tabs-order"}
                        onClick={() => toggleTab('cancelled')}>Cancelled
                </button>
            </div>
            {
                togglestate === "confirmed" ?
                    activeorders !== null &&
                    <div className='ordered-items'>
                        {
                            activeorders.map((data, index) => (
                                <div className='ordered-item' key={index}>
                                    <div className='ordered-product-details'>
                                        <div className='ordered-product-detail-info'>
                                            <p className='text-[25px] font-bold mb-1'><span
                                                className='sr-only'>DOC</span> {data.id}</p>
                                            <p className='text-[18px] font-light mb-2'>Placed on {data.order_date}</p>
                                            <p className='text-[25px] font-light'>{data.order_amount}AED</p>
                                        </div>
                                        {data.order_product_data.map((productData, value) => (
                                            <div key={value}>
                                                {productData.product_images.map((productImageData, productimageindex) => (
                                                    productImageData.file ?
                                                        <div key={productimageindex} className='ordered-product-image'>
                                                            <img src={productImageData.file} alt='' width={80}
                                                                 height={80}/>
                                                        </div> : <div><p>Data not found</p></div>
                                                ))}

                                            </div>

                                        ))}
                                        <Link to={`${pathURL}/order/view/${data.id}`}>
                                            <button>More details</button>
                                        </Link>
                                    </div>
                                </div>

                            ))
                        }
                    </div>

                    : togglestate === "delivered" ?
                        <div className='ordered-items'>
                            {
                                completeOrders.map((ordercompleted, indexkey) => (
                                        <div className='ordered-item' key={indexkey}>
                                            {
                                                ordercompleted.order_status === 'Delivered' &&
                                                <div className='ordered-product-details'>
                                                    <div className='ordered-product-detail-info'>
                                                        <p className='text-[25px] font-bold mb-1'><span
                                                            className='sr-only'>DOC </span> {ordercompleted.id}</p>
                                                        <p className='text-[18px] font-light mb-2'>Placed on {ordercompleted.order_date}</p>
                                                        <p className='text-[25px] font-light'>{ordercompleted.order_amount}AED</p>
                                                    </div>
                                                    {
                                                        ordercompleted.order_product_data.map((completedOrderProductData, keyindex) => (
                                                                <div className='ordered-product-image' key={keyindex}>
                                                                    {
                                                                        completedOrderProductData.product_images.map((completedOrderProductimgData, imgkey) => (
                                                                                <div key={imgkey}>
                                                                                    {
                                                                                        completedOrderProductimgData.file ?
                                                                                            <div>
                                                                                                <img
                                                                                                    src={completedOrderProductimgData.file}
                                                                                                    alt='' width={80} height={80}/>
                                                                                            </div> : <p>Data not found</p>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                    <Link to={`${pathURL}/order/view/${ordercompleted.id}`}>
                                                        <button>More details</button>
                                                    </Link>
                                                </div>
                                            }


                                        </div>
                                    )
                                )

                            }
                        </div>
                        :
                        <div className='ordered-items'>
                            {
                                completeOrders.map((cancledOrders, cancledindex) => (
                                        <div className='ordered-item'>
                                            {
                                                cancledOrders.order_status === 'Canceled' &&
                                                <div className='ordered-product-details'>
                                                    <div className='ordered-product-detail-info'>
                                                        <p className='text-[25px] font-bold mb-1'><span
                                                            className='sr-only'> DOC </span> {cancledOrders.id}</p>
                                                        <p className='text-[18px] font-light mb-2'>Placed on {cancledOrders.order_date}</p>
                                                        <p className='text-[25px] font-light'>{cancledOrders.order_amount}AED</p>
                                                    </div>
                                                    <div className='ordered-product-image-listing'>
                                                        {
                                                            cancledOrders.order_product_data.map((cancledProductData, keyindex) => (
                                                                    <div key={keyindex}>
                                                                        {
                                                                            cancledProductData.product_images.map((cancledOrderProductimgData, imgkey) => (
                                                                                    <div key={imgkey}>
                                                                                        {
                                                                                            cancledOrderProductimgData.file ?
                                                                                                <div
                                                                                                    className='ordered-product-image'>
                                                                                                    <img
                                                                                                        src={cancledOrderProductimgData.file}
                                                                                                        alt='' width={80} height={80}/>
                                                                                                </div> : <p>Data not found</p>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                    <Link to={`${pathURL}/order/view/${cancledOrders.id}`}>
                                                        <button>More details</button>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    )
                                )
                            }
                        </div>
            }
        </div>
    )
}

export default MyOrder