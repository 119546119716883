import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  getAboutUsAPI, getFaqAPI, getPrivacyPolicyAPI, getRefundAndExchangeAPI, getTermsconditionAPI } from "../services/apiCalls";


export const getprivacypolicy = createAsyncThunk("PrivacyPolicy/getPrivacy", async () => {
    const res = await getPrivacyPolicyAPI();
    return res.data;
})
export const getTermscondition = createAsyncThunk("Termscondition/getTermscondition", async () => {
    const res = await getTermsconditionAPI();
    return res.data;
})
export const getRefundAndExchange = createAsyncThunk("RefundAndExchange/getRefundAndExchange", async () => {
    const res = await getRefundAndExchangeAPI();
    return res.data;
})
export const getFaq = createAsyncThunk("Faq/getFaq", async () => {
    const res = await getFaqAPI();
    return res.data;
})

export const getAboutus = createAsyncThunk("Faq/getAboutus", async () => {
    const res = await getAboutUsAPI();
    console.log("res.data ",res.data);
    return res.data;
})

const INITIAL_STATE = {
    Privacy: [],
    Terms :[],
    Refund : [],
    Faq :[],
    AboutUs :[],
    loader: false,
}

const customerservicesSlice = createSlice({
    name: "Privacy",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getprivacypolicy.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getprivacypolicy.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.Privacy = action.payload.result;
                    
                    
                }
                else {
                    state.Privacy = []
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getprivacypolicy.rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue")
            })
            .addCase(getTermscondition .pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getTermscondition .fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.Terms = action.payload.result;
                    console.log(action.payload.result);
                    
                }
                else{
                    state.Terms = []
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getTermscondition .rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue")
            })
            .addCase(getRefundAndExchange .pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getRefundAndExchange .fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.Refund = action.payload.result;
                    console.log(action.payload.result);
                    
                }
                else {
                    state.Refund = []
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getRefundAndExchange .rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue")
            })
            .addCase(getFaq.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getFaq.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log(action.payload);
                    state.Faq = action.payload.result;
                    console.log(action.payload.result);
                    
                    
                }
                else{
                    state.Faq = []
                }
                state.loader = false;
                
            })
            .addCase(getFaq.rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue")
            })

            .addCase(getAboutus.pending, (state, action) => {
                state.loader = false;
                console.log("api pending")
            })
            
            .addCase(getAboutus.fulfilled, (state, action) => {
                if(action.payload.code ===1){
                    state.AboutUs = action.payload.result
                    console.log("about us ",action.payload);
                    console.log("api fulfilled")
                }
                
            })
            
            .addCase(getAboutus.rejected, (state, action) => {
                state.loader = false;
                console.log("api rejected")
            })
            
    }
})

export default customerservicesSlice .reducer;