import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getFilterBrands,
    getFilterPriceRange,
    getFilterOffers,
    getFilterCollections,
    getFilterTags,
    getAllCategoryProducts,
    getAllCollectionProducts,
    getAllFeaturedProducts,
    getAllBrandProducts,
    getAllSimilarProducts,
    getAllSearchProducts,
    getAllOfferProducts
} from "../services/apiCalls";

const INITIAL_STATE = {
    loader: false,
    loadMoreLoader: false,
    featuredProducts: [],
    featuredInfo: [],
    categoryProducts: [],
    categoryInfo: [],
    subCategoryInfo: [],
    collectionProducts: [],
    collectionInfo: [],
    brandProducts: [],
    brandInfo: [],
    similarProducts: [],
    offerProducts: [],
    searchProducts: [],
    selectedFlags: [],
    selectedSortOrder: 1,
    selectedFulfillment: [],
    selectedBrands: [],
    selectedMinPrice: 0,
    selectedMaxPrice: 3999,
    selectedOffers: [],
    selectedCollections: [],
    selectedTags: [],
    minPriceOption: 0,
    maxPriceOption: 3999,
    brandOptions: [],
    offerOptions: [],
    collectionOptions: [],
    tagOptions: [],
    finalFilterData: {},
    subcategories: [],
    loadmore: false,
    noProducts: false,
}

export const getProductListFeaturedThunk = createAsyncThunk("SubCategory/getProductListFeatured", async (requestData) => {
    const res = await getAllFeaturedProducts(requestData);
    return res.data;
});
export const getProductListFeaturedMoreThunk = createAsyncThunk("SubCategory/getProductListFeaturedMore", async (requestData) => {
    const res = await getAllFeaturedProducts(requestData);
    return res.data;
});

export const getProductListSubCategoryThunk = createAsyncThunk("SubCategory/getProductListSubCategory", async (requestData) => {
    const res = await getAllCategoryProducts(requestData);
    return res.data;
});

export const getProductListSubCategoryMoreThunk = createAsyncThunk("SubCategory/getProductListSubCategoryMore", async (requestData) => {
    const res = await getAllCategoryProducts(requestData);
    return res.data;
});

export const getProductListCollectionThunk = createAsyncThunk("SubCategory/getProductListCollection", async (requestData) => {
    const res = await getAllCollectionProducts(requestData);
    return res.data;
});

export const getProductListCollectionMoreThunk = createAsyncThunk("SubCategory/getProductListCollectionMore", async (requestData) => {
    const res = await getAllCollectionProducts(requestData);
    return res.data;
});

export const getProductListBrandThunk = createAsyncThunk("SubCategory/getProductListBrand", async (requestData) => {
    const res = await getAllBrandProducts(requestData);
    return res.data;
});

export const getProductListBrandMoreThunk = createAsyncThunk("SubCategory/getProductListBrandMore", async (requestData) => {
    const res = await getAllBrandProducts(requestData);
    return res.data;
});

export const getProductListSimilarThunk = createAsyncThunk("SubCategory/getProductListSimilar", async (requestData) => {
    const res = await getAllSimilarProducts(requestData);
    return res.data;
});

export const getProductListSimilarMoreThunk = createAsyncThunk("SubCategory/getProductListSimilarMore", async (requestData) => {
    const res = await getAllSimilarProducts(requestData);
    return res.data;
});

export const getProductListOfferThunk = createAsyncThunk("SubCategory/getProductListOffer", async (requestData) => {
    const res = await getAllOfferProducts(requestData);
    return res.data;
});

export const getProductListOfferMoreThunk = createAsyncThunk("SubCategory/getProductListOfferMore", async (requestData) => {
    const res = await getAllOfferProducts(requestData);
    return res.data;
});

export const getProductListSearchThunk = createAsyncThunk("SubCategory/getProductListSearch", async (requestData) => {
    const res = await getAllSearchProducts(requestData);
    return res.data;
});

export const getProductListSearchMoreThunk = createAsyncThunk("SubCategory/getProductListSearchMore", async (requestData) => {
    const res = await getAllSearchProducts(requestData);
    return res.data;
});

export const processFilterBrandsThunk = createAsyncThunk("SubCategory/processFilterBrands", async (postData) => {
    const res = await getFilterBrands(postData);
    return res.data;
});

export const processFilterPriceRangeThunk = createAsyncThunk("SubCategory/processFilterPriceRange", async (postData) => {
    const res = await getFilterPriceRange(postData);
    return res.data;
});

export const processFilterOffersThunk = createAsyncThunk("SubCategory/processFilterOffers", async (postData) => {
    const res = await getFilterOffers(postData);
    return res.data;
});

export const processFilterCollectionsThunk = createAsyncThunk("SubCategory/processFilterCollections", async (postData) => {
    const res = await getFilterCollections(postData);
    return res.data;
});

export const processFilterTagsThunk = createAsyncThunk("SubCategory/processFilterTags", async (postData) => {
    const res = await getFilterTags(postData);
    return res.data;
});

const subCategorySlice = createSlice({
    name: "category",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductListFeaturedThunk.pending, (state, action) => {
                console.log("Product Listing Featured API pending");
                state.loader = true;
            })
            .addCase(getProductListFeaturedThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.featuredInfo = action.payload.featured_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    {
                        state.loadmore = false
                    }
                    state.featuredProducts = action.payload.result;
                    state.noProducts = false
                }
                else {
                    state.featuredInfo = []
                    state.featuredProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }
                state.loader = false;

            })
            .addCase(getProductListFeaturedThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Featured API error api issue : ", action)
            })
            .addCase(getProductListFeaturedMoreThunk.pending, (state, action) => {
                console.log("Product Listing Featured API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListFeaturedMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.featuredInfo = action.payload.featured_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    {
                        state.loadmore = false
                    }
                    state.featuredProducts.push(...action.payload.result);
                }
                else {
                    state.featuredInfo = []
                    if (state.featuredProducts.length === 0) {

                        state.featuredProducts = []

                    }
                    state.loadmore = false
                }
                state.loadMoreLoader = false;

            })
            .addCase(getProductListFeaturedMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Featured API error api issue : ", action)
            })
            .addCase(getProductListSubCategoryThunk.pending, (state, action) => {
                console.log("Product Listing Sub-Category API pending");
                state.loader = true;
            })
            .addCase(getProductListSubCategoryThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.categoryInfo = action.payload.category_info;
                    state.subCategoryInfo = action.payload.sub_category_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.categoryProducts = action.payload.result;
                    state.subcategories = action.payload.sub_categories;
                    state.noProducts = false
                }
                else {
                    state.categoryInfo = []
                    state.subCategoryInfo = []
                    state.categoryProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }

                state.loader = false;
                console.log("Product Listing Sub-Category API completed");
            })
            .addCase(getProductListSubCategoryThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Sub-Category API error api issue : ", action)
            })

            .addCase(getProductListSubCategoryMoreThunk.pending, (state, action) => {
                console.log("Product Listing Sub-Category API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListSubCategoryMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.categoryInfo = action.payload.category_info;
                    state.subCategoryInfo = action.payload.sub_category_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.categoryProducts.push(...action.payload.result)
                    state.subcategories = action.payload.sub_categories;

                }
                else {
                    state.categoryInfo = []
                    state.subCategoryInfo = []
                    if (state.categoryProducts === 0) {
                        state.categoryProducts = []
                    }

                    state.loadmore = false
                }

                state.loadMoreLoader = false;
                console.log("Product Listing Sub-Category API completed");
            })
            .addCase(getProductListSubCategoryMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Sub-Category API error api issue : ", action)
            })

            .addCase(getProductListCollectionThunk.pending, (state, action) => {
                console.log("Product Listing Collection API pending");
                state.loader = true;
            })
            .addCase(getProductListCollectionThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.collectionInfo = action.payload.collections_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.collectionProducts = action.payload.result;
                    state.noProducts = false;
                }
                else {
                    state.collectionInfo = []
                    state.collectionProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }
                state.loader = false;
                console.log("Product Listing Collection API completed");
            })
            .addCase(getProductListCollectionThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Collection API error api issue : ", action)
            })


            .addCase(getProductListCollectionMoreThunk.pending, (state, action) => {
                console.log("Product Listing Collection API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListCollectionMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.collectionProducts.push(...action.payload.result);
                }
                else {
                    if (state.collectionProducts.length === 0) {
                        state.collectionProducts = []

                    }
                    state.loadmore = false
                }
                state.loadMoreLoader = false;
                console.log("Product Listing Collection API completed");
            })
            .addCase(getProductListCollectionMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Collection API error api issue : ", action)
            })

            .addCase(getProductListBrandThunk.pending, (state, action) => {
                console.log("Product Listing Brand API pending");
                state.loader = true;
            })
            .addCase(getProductListBrandThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.brandInfo = action.payload.brand_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.brandProducts = action.payload.result;
                    state.noProducts = false
                } else {
                    state.brandInfo = []
                    state.brandProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }
                state.loader = false;
                console.log("Product Listing Brand API completed");
            })
            .addCase(getProductListBrandThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Brand API error api issue : ", action)
            })

            .addCase(getProductListBrandMoreThunk.pending, (state, action) => {
                console.log("Product Listing Brand API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListBrandMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.brandInfo = action.payload.brand_info;
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.brandProducts.push(...action.payload.result);
                }
                else {
                    state.brandInfo = []
                    if (state.brandProducts === 0) {
                        state.brandProducts = []

                    }
                    state.loadmore = false
                }
                state.loadMoreLoader = false;
                console.log("Product Listing Brand API completed");
            })
            .addCase(getProductListBrandMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Brand API error api issue : ", action)
            })
            .addCase(getProductListSimilarThunk.pending, (state, action) => {
                console.log("Product Listing Similar API pending");
                state.loader = true;
            })
            .addCase(getProductListSimilarThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }

                    state.similarProducts = action.payload.result;
                    state.noProducts = false
                }
                else {
                    state.similarProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }
                state.loader = false;
                console.log("Product Listing Similar API completed");
            })
            .addCase(getProductListSimilarThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Similar API error api issue : ", action)
            })

            .addCase(getProductListSimilarMoreThunk.pending, (state, action) => {
                console.log("Product Listing Similar API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListSimilarMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }

                    state.similarProducts.push(...action.payload.result);
                }
                else {
                    if (state.similarProducts === 0) {
                        state.similarProducts = []

                    }
                    state.loadmore = false
                }
                state.loadMoreLoader = false;
                console.log("Product Listing Similar API completed");
            })
            .addCase(getProductListSimilarMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Similar API error api issue : ", action)
            })
            .addCase(getProductListOfferThunk.pending, (state, action) => {
                console.log("Product Listing Offer API pending");
                state.loader = true;
            })
            .addCase(getProductListOfferThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.offerProducts = action.payload.result;
                    state.noProducts = false
                }
                else {
                    state.offerProducts = []
                    state.loadmore = false
                    state.noProducts = true
                }
                state.loader = false;
                console.log("Product Listing Offer API completed");
            })

            .addCase(getProductListOfferMoreThunk.pending, (state, action) => {
                console.log("Product Listing Offer API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListOfferMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.offerProducts.push(...action.payload.result);

                }
                else {
                    if (state.offerProducts.length === 0) {
                        state.offerProducts = []

                    }
                    state.loadmore = false

                }
                state.loadMoreLoader = false;
                console.log("Product Listing Offer API completed");
            })
            .addCase(getProductListOfferMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Offer API error api issue : ", action)
            })
            .addCase(getProductListOfferThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Offer API error api issue : ", action)
            })
            .addCase(getProductListSearchThunk.pending, (state, action) => {
                console.log("Product Listing Search API pending");
                state.loader = true;
            })
            .addCase(getProductListSearchThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.searchProducts = action.payload.result;
                    state.noProducts = false
                }
                else {
                    state.searchProducts = []
                    state.loadmore = false
                    state.noProducts = true

                }
                state.loader = false;
                console.log("Product Listing Search API completed");
            })
            .addCase(getProductListSearchThunk.rejected, (state, action) => {
                state.loader = false;
                console.log("Product Listing Search API error api issue : ", action)
            })

            .addCase(getProductListSearchMoreThunk.pending, (state, action) => {
                console.log("Product Listing Search API pending");
                state.loadMoreLoader = true;
            })
            .addCase(getProductListSearchMoreThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result.length === 24) {
                        state.loadmore = true
                    }
                    else {
                        state.loadmore = false
                    }
                    state.searchProducts.push(...action.payload.result);

                }
                else {
                    if (state.searchProducts.length === 0) {
                        state.searchProducts = []

                    }
                    state.loadmore = false
                }
                state.loadMoreLoader = false;
                console.log("Product Listing Search API completed");
            })
            .addCase(getProductListSearchMoreThunk.rejected, (state, action) => {
                state.loadMoreLoader = false;
                console.log("Product Listing Search API error api issue : ", action)
            })
            .addCase(processFilterBrandsThunk.pending, (state, action) => {
                console.log("Product Filter Brands API status pending");
            })
            .addCase(processFilterBrandsThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    let tempArray = [];
                    if (action.payload.hasOwnProperty('result') && Array.isArray(action.payload.result) && (action.payload.result.length > 0)) {
                        action.payload.result.forEach((brand, index) => {
                            tempArray.push({
                                label: brand['brand_name'],
                                value: brand['id'],
                                checked: false
                            });
                        });
                    }
                    state.brandOptions = tempArray;
                }
                else {
                    state.brandOptions = []
                }
                console.log("Product Filter Brands API status completed");
            })
            .addCase(processFilterBrandsThunk.rejected, (state, action) => {
                console.log("Product Filter Brands API status error api issue : ", action)
            })
            .addCase(processFilterPriceRangeThunk.pending, (state, action) => {
                console.log("Product Filter Price Range API status pending");
            })
            .addCase(processFilterPriceRangeThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.result[0]['price_min'] !== null) {
                        state.minPriceOption = Math.floor(action.payload.result[0]['price_min']);
                    } else {
                        state.minPriceOption = 0
                    }
                    if (action.payload.result[0]['price_max'] !== null) {
                        state.maxPriceOption = Math.ceil(action.payload.result[0]['price_max']);
                    } else {
                        state.maxPriceOption = 3999
                    }
                }
                else {
                    state.minPriceOption = 0
                    state.maxPriceOption = 3999
                }
                console.log("Product Filter Price Range API status completed");
            })
            .addCase(processFilterPriceRangeThunk.rejected, (state, action) => {
                console.log("Product Filter Price Range API status error api issue : ", action)
            })
            .addCase(processFilterOffersThunk.pending, (state, action) => {
                console.log("Product Filter Offers API status pending");
            })
            .addCase(processFilterOffersThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    let tempArray = [];
                    if (action.payload.hasOwnProperty('result') && Array.isArray(action.payload.result) && (action.payload.result.length > 0)) {
                        action.payload.result.forEach((offer, index) => {
                            tempArray.push({
                                label: offer['code'],
                                value: offer['id'],
                                checked: false
                            });
                        });
                    }
                    state.offerOptions = tempArray;
                }
                else {
                    state.offerOptions = []
                }
                console.log("Product Filter Offers API status completed");
            })
            .addCase(processFilterOffersThunk.rejected, (state, action) => {
                console.log("Product Filter Offers API status error api issue : ", action)
            })
            .addCase(processFilterCollectionsThunk.pending, (state, action) => {
                console.log("Product Filter Collections API status pending");
            })
            .addCase(processFilterCollectionsThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    let tempArray = [];
                    if (action.payload.hasOwnProperty('result') && Array.isArray(action.payload.result) && (action.payload.result.length > 0)) {
                        action.payload.result.forEach((collection, index) => {
                            tempArray.push({
                                label: collection['title'],
                                value: collection['id'],
                                checked: false
                            });
                        });
                    }
                    state.collectionOptions = tempArray;
                }
                else {
                    state.collectionOptions = []
                }
                console.log("Product Filter Collections API status completed");
            })
            .addCase(processFilterCollectionsThunk.rejected, (state, action) => {
                console.log("Product Filter Collections API status error api issue : ", action)
            })
            .addCase(processFilterTagsThunk.pending, (state, action) => {
                console.log("Product Filter Tags API status pending");
            })
            .addCase(processFilterTagsThunk.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    let tempArray = [];
                    if (action.payload.hasOwnProperty('result') && Array.isArray(action.payload.result) && (action.payload.result.length > 0)) {
                        action.payload.result.forEach((tag, index) => {
                            tempArray.push({
                                label: tag['tag_name'],
                                value: tag['id'],
                                checked: false
                            });
                        });
                    }
                    state.tagOptions = tempArray;
                }
                else {
                    state.tagOptions = []
                }
                console.log("Product Filter Tags API status completed");
            })
            .addCase(processFilterTagsThunk.rejected, (state, action) => {
                console.log("Product Filter Tags API status error api issue : ", action)
            })
    }
})

export default subCategorySlice.reducer;