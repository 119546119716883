import React, { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { Button, Container, Modal } from "react-bootstrap";
import { getAddressData, getEmiratesData, setAddressData } from '../../../redux/profile';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getCartPage, getCartPagePrecription } from '../../../redux/cartRedux';
import {
    addAddress,
    applyCoupenApi,
    placeOrderApi,
    postPayOrderCreationApi,
    telrOrderCreationApi
} from '../../../services/apiCalls';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearPaymentDetails, getUser, setPaymentDetails } from '../../../Constants/storedValues';
import { toast, ToastContainer } from 'react-toastify';
import { BiChevronLeft } from 'react-icons/bi';

function Checkout() {
    const user = getUser();
    const { cartpageBilling } = useSelector((state) => state.cart);
    const { cartpageItems, deliverySlot, cartId } = useSelector((state) => state.cart);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            mobile: user.mobile,
        },
        mode: "onChange"
    });
    const [agreed, setAgreed] = useState(false)
    const [enabled, setEnabled] = useState(false);
    const [editable, setEditable] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [coupen, setCoupen] = useState("");
    const dispatch = useDispatch();
    const { addressList } = useSelector((state) => state.profile);
    const { getEmiratesList } = useSelector(state => state.profile);
    const [paymentPopup, setPaymentPopup] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(1);
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAddressData())
        dispatch(getEmiratesData())
        dispatch(getCartPage())
        return
    }, []);

    useEffect(() => {
        if (addressList.length > 1) {
            setSelectedAddress(addressList[0])
            setEditable(false)
        } else {
            setSelectedAddress({});
            setEditable(true)
        }
    }, [addressList]);


    const addressChange = (id) => {
        if (id !== "new") {
            addressList.map((address) => {
                if (address.id === id) {
                    setEditable(false)
                    setSelectedAddress(address);
                }
            })
        } else {
            setEditable(true)
            setSelectedAddress({});
        }

    }

    const onSubmit = async (data) => {
        const addAddressData = await addAddress(data);
        if (addAddressData.data.code === 1) {
            toast.success("Address added successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            dispatch(getAddressData())
            setEditable(false)
        }else{
            toast.error(addAddressData.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    };

    const onApplyCoupenClick = async () => {
        const data = {
            'coupon_code': coupen,
            'flag': "1",
        }
        const applyCoupen = await applyCoupenApi(data);
        if (applyCoupen.data.code === 1) {
            dispatch(getCartPage())
            toast.success("Coupon applied succesfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            toast.error("Coupon not found", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const selectedPaymentMethod = async () => {
        const inputDateString = deliverySlot.date_show
        var inputDate = new Date(inputDateString);

        // Step 2: Format the Date object into the desired output format
        var year = inputDate.getFullYear();
        var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        var day = String(inputDate.getDate()).padStart(2, '0');
        var outputDateString = year + '-' + month + '-' + day;
        setPaymentPopup(false)
        if (selectedAddress.id !== "new") {
            window.dataLayer.push(
                {
                  'event': 'begin_checkout',
                  'ecommerce': {
                    "currency": "AED",
                    "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                    "value": cartpageBilling.total_payable,
                    "items": cartpageItems,
                  }
                }
              );
              window.dataLayer.push(
                {
                  'event': 'add_shipping_info',
                  'ecommerce': {
                    "currency": "AED",
                    "value": cartpageBilling.total_payable,
                    "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                    "shipping_tier": "Ground",
                    "items": cartpageItems,
                  }
                }
              );
              window.dataLayer.push(
                {
                  'event': 'add_payment_info',
                  'ecommerce': {
                    "currency": "AED",
                    "value": cartpageBilling.total_payable,
                    "coupon": cartpageBilling.total_coupon_discount !== undefined ? cartpageBilling.total_coupon_discount : 0,
                    "payment_type": selectedPayment == 1
                        ? "cash"
                        : selectedPayment == "2"
                            ? "card"
                            : selectedPayment == "3"
                                ? "telr"
                                : "postpay",
                    "items": cartpageItems,
                  }
                }
              );
            const reqData = {
                "flag": state.flag,
                "address_id": selectedAddress.id,
                "slot_id": deliverySlot.id,
                "delivery_date": outputDateString,
                "payment_type": selectedPayment,
                "doorstep_delivery": "1"
            }
            const placeOrder = await placeOrderApi(reqData)
            if (placeOrder.data.code === 1) {

                dispatch(getCartPage())
                dispatch(getCartPagePrecription())
                const baseURL = window.location.origin
                const cancelURL = baseURL + "/cancel"
                const declineURL = baseURL + "/decline"
                switch (selectedPayment) {
                    case 1:
                        navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                        break;
                    case "2":
                        navigate('/orderdetails', { state: { orderId: placeOrder.data.order_id } });
                        break;
                    case "3":
                        const telrRedirectURL = baseURL + "/telrPaymentScreen"
                        const telrData = {
                            "order_id": placeOrder.data.order_id,
                            "amount": cartpageBilling.total_payable,
                            "return_auth_url": telrRedirectURL,
                            "return_can_url": cancelURL,
                            "return_decl_url": declineURL,
                        }
                        const telrOrder = await telrOrderCreationApi(telrData)
                        if (telrOrder.data.code === 1) {
                            const orderRef = {
                                "orderId": placeOrder.data.order_id,
                                "paymentCartId": cartId,
                                "orderRef": telrOrder.data.order_ref
                            }
                            clearPaymentDetails();
                            setPaymentDetails(orderRef);
                            window.location.replace(telrOrder.data.order_url)
                        }else{
                            toast.error(telrOrder.data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        break;
                    case "4":
                        const redirectURL = baseURL + "/postPayPaymentScreen"
                        const data = {
                            "order_id": placeOrder.data.order_id,
                            "amount": cartpageBilling.total_payable,
                            "return_auth_url": redirectURL,
                            "return_can_url": cancelURL,
                            "return_decl_url": declineURL,
                        }
                        const postPayOrder = await postPayOrderCreationApi(data)
                        if (postPayOrder.data.code === 1) {
                            const orderRef = {
                                "orderId": placeOrder.data.order_id,
                                "paymentCartId": cartId,
                                "orderRef": postPayOrder.data.order_ref
                            }
                            clearPaymentDetails();
                            setPaymentDetails(orderRef);
                            window.location.replace(postPayOrder.data.order_url)
                        }else{
                            toast.error(postPayOrder.data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        break;
                    default:
                        break;
                }
            }else{
                toast.error(placeOrder.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }

    }

    const handlePaymentChange = (e) => {
        setSelectedPayment(e.target.value);

    }

    const selectPayment = () => {
        setPaymentPopup(!paymentPopup)
    }

    return (
        <>
            <Container>
                <div className='flex pb-6'>
                    <div className='md:w-7/12'>
                        <div className="isolate bg-white pr-5">
                            <div className="flex justify-between max-w-full text-center">
                                <p className="inline-block text-[20px] font-light leading-8 text-black">
                                    Shipping Address
                                </p>
                            </div>

                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                <div className='sm:col-span-2'>
                                    <div className="relative my-2">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            // defaultValue={"select address"}
                                            placeholder='Select Address'
                                            className='appearance-none block w-full h-12 rounded-full border-0 px-7 text-gray-900 sm:text-sm sm:leading-6 bg-[#F8F8F8]'
                                            onChange={(e) => {
                                                addressChange(e.target.value)
                                            }}
                                        >
                                            {
                                                addressList.map((address, index) => {
                                                    if (address.id !== "new") {
                                                        return (
                                                            <option key={index}
                                                                value={address.id}>{address.f_name} {address.l_name}</option>
                                                        );
                                                    } else {
                                                        return (
                                                            <option value={address.id}> <span className='text-2xl'>+</span> Add New Address</option>
                                                        );
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                editable ?
                                    <>
                                        <form className="mx-auto max-w-full mt-[.625rem]"
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div
                                                className="relative flex items-center justify-between overflow-hidden py-6">
                                                <div className='flex gap-3'>
                                                    <div className='flex gap-2 cursor-pointer'>
                                                        <input checked type="radio" className="peer/office text-black"
                                                            id='home'
                                                            value={1}  {...register("address_type", { required: true })} />
                                                        <label htmlFor="home"
                                                            className="peer-checked/home:text-black cursor-pointer">Home</label>
                                                    </div>
                                                    <div className='flex gap-2 cursor-pointer'>
                                                        <input type="radio" id='office'
                                                            value={2}  {...register("address_type", { required: true })} />
                                                        <label htmlFor="office"
                                                            className="peer-checked/office:text-black cursor-pointer">Office</label>
                                                    </div>
                                                    <div className='flex gap-2 cursor-pointer'>
                                                        <input type="radio" className="peer/office text-black"
                                                            id='other'
                                                            value={3}  {...register("address_type", { required: true })} />
                                                        <label htmlFor="other"
                                                            className="peer-checked/other:text-black cursor-pointer">Other</label>
                                                    </div>
                                                </div>
                                                <div className="flex py-1">
                                                    <label
                                                        className="inline-flex relative items-center mr-5 cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            className="sr-only peer"
                                                            checked={enabled}
                                                            readOnly
                                                        />
                                                        <div
                                                            onClick={() => {
                                                                setEnabled(!enabled);
                                                            }}
                                                            className="w-11 h-6 outline outline-1 outline-black border-black rounded-full  peer-focus:ring-black-300  peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-black after:border-black after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-400"
                                                        ></div>
                                                        <span className="ml-2 text-sm font-medium text-gray-900">
                                                            Set as default
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='grid gap-[.625rem] grid-cols-2'>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name="fname"
                                                        id="fname"
                                                        {...register("f_name", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.f_name ? 'error' : ''}`}
                                                        placeholder={`First Name ${errors.f_name ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name="lname"
                                                        id="lname"
                                                        {...register("l_name", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.l_name ? 'error' : ''}`}
                                                        placeholder={`Last Name ${errors.l_name ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className="relative col-span-2">
                                                    <input
                                                        type="text"
                                                        name="buildingname"
                                                        id="buildingname"
                                                        {...register("building_name", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.building_name ? 'error' : ''}`}
                                                        placeholder={`Address ${errors.building_name ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className="relative col-span-2">
                                                    <input
                                                        type="text"
                                                        name="fulladdress"
                                                        id="fulladdress"
                                                        {...register("full_address", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.full_address ? 'error' : ''}`}
                                                        placeholder={`Apartment ${errors.full_address ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        {...register("city", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.city ? 'error' : ''}`}
                                                        placeholder={`City ${errors.city ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <select
                                                        id="country"
                                                        name="country"
                                                        autoComplete="country-name"
                                                        {...register("emirate_master_id", { required: true })}
                                                        className="appearance-none block w-full h-12 rounded-full border-0 px-7 text-gray-900 sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    >
                                                        {
                                                            getEmiratesList.map((emirates, index) => {
                                                                return (
                                                                    <option key={index}
                                                                        value={emirates.id}>{emirates.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="relative col-span-2 flex gap-2">
                                                    <div
                                                        className="pointer-events-none flex items-center h-12 rounded-full border-0 px-7 text-black font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]">
                                                        <span className="text-black sm:text-sm">+971</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="mobile"
                                                        id="mobile"
                                                        {...register("mobile", { required: true })}
                                                        className={`block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8] ${errors.mobile ? 'error' : ''}`}
                                                        placeholder={`Contact ${errors.mobile ? 'Required' : ''}`}
                                                    />
                                                </div>
                                                <div className='flex col-span-2 gap-4 mt-3'>
                                                    <div className='w-1/3'>
                                                        <button
                                                            className="rounded-full w-full font-light text-base bg-transparent text-black flex items-center justify-center gap-x-2 px-3"
                                                            onClick={goBack}>
                                                            <BiChevronLeft className='text-[#41BDDE] text-3xl' />
                                                            Return to cart
                                                        </button>
                                                    </div>
                                                    <div className='w-2/3'>
                                                        <input type="submit"
                                                            className="rounded-full font-light text-base w-full bg-[#41BDDE] h-[50px] text-white"
                                                            value={"Save Address"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </> :
                                    <>
                                        <div className='grid gap-[.625rem] grid-cols-2'>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={false}
                                                    enabled={true}
                                                    value={selectedAddress.f_name !== undefined ? selectedAddress.f_name : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="First Name"
                                                />
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.l_name !== undefined ? selectedAddress.l_name : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="Last Name"
                                                />
                                            </div>
                                            <div className="relative col-span-2">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.building_name !== undefined ? selectedAddress.building_name : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="Address"
                                                />
                                            </div>
                                            <div className="relative col-span-2">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.full_address !== undefined ? selectedAddress.full_address : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="Apartment"
                                                />
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.city !== undefined ? selectedAddress.city : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="City"
                                                />
                                            </div>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.emirate_name !== undefined ? selectedAddress.emirate_name : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="Emirates"
                                                />
                                            </div>
                                            <div className="relative col-span-2 flex gap-2">
                                                <div
                                                    className="pointer-events-none flex items-center h-12 rounded-full border-0 px-7 text-black font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]">
                                                    <span className="text-black sm:text-sm">+971</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    id="price"
                                                    disabled={editable ? true : false}
                                                    value={selectedAddress.mobile !== undefined ? selectedAddress.mobile : ""}
                                                    className="block w-full h-12 rounded-full border-0 px-7 text-gray-900 font-light sm:text-sm sm:leading-6 bg-[#F8F8F8]"
                                                    placeholder="Contact"
                                                />
                                            </div>
                                        </div>
                                    </>
                            }

                            <div className='flex gap-4 mt-4'>
                                {
                                    !editable &&
                                    <div className='w-1/3'>
                                        <button
                                            className="rounded-full w-full font-light text-base bg-transparent text-black flex items-center justify-center gap-x-2 px-3"
                                            onClick={goBack}>
                                            <BiChevronLeft className='text-[#41BDDE] text-3xl' />
                                            Return to cart
                                        </button>
                                    </div>
                                }

                                {
                                    editable ? <div className='w-2/3'>
                                    </div> : <div className='w-2/3'>
                                        <button className="rounded-full font-light text-base w-full"
                                            onClick={selectPayment}>Continue
                                        </button>
                                        {
                                            <Modal className='select-payment' show={paymentPopup} fullscreen={false}
                                                onHide={() => setPaymentPopup(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Select Payment</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='fieldset'>
                                                        <div className='field'>
                                                            <input id='cash-on-delivery' type="radio" value="1"
                                                                name="payment" defaultChecked onChange={(e) => {
                                                                    handlePaymentChange(e)
                                                                }} />
                                                            <label htmlFor='cash-on-delivery'>Cash on
                                                                Delivery</label>
                                                        </div>
                                                        <div className='field'>
                                                            <input id='card-on-delivery' type="radio" value="2"
                                                                name="payment" onChange={(e) => {
                                                                    handlePaymentChange(e)
                                                                }} />
                                                            <label htmlFor='card-on-delivery'> Card on
                                                                Delivery</label>
                                                        </div>
                                                        <div className='field'>
                                                            <input id='pay-by-card' type="radio" value="3"
                                                                name="payment" onChange={(e) => {
                                                                    handlePaymentChange(e)
                                                                }} />
                                                            <label htmlFor='pay-by-card'>Pay by Card</label>
                                                        </div>
                                                        <div className='field'>
                                                            <input id='pay-in-installment' type="radio" value="4"
                                                                name="payment" onChange={(e) => {
                                                                    handlePaymentChange(e)
                                                                }} />
                                                            <label htmlFor='pay-in-installment'>Pay in Installments</label>
                                                        </div>
                                                    </div>
                                                    <div className='actions'>
                                                        <Button onClick={selectedPaymentMethod}>Submit</Button>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='md:w-5/12'>
                        <div
                            className="w-full md:p-8  bg-gray-100 rounded-[30px]"
                            id="scroll">
                            {
                                (cartpageItems !== undefined) && (cartpageItems.length > 0) &&
                                <div>
                                    {
                                        cartpageItems.map((products, index) => (
                                            <div className="md:flex items-center pt-4" key={index}>
                                                <div className="w-1/6 relative">
                                                    {
                                                        products.product_images.map((image, index) => (
                                                            <img
                                                                src={image.file}
                                                                alt
                                                                className="w-full h-full object-center rounded-xl object-cover" />
                                                        ))
                                                    }
                                                    <div
                                                        className='bg-[red] w-7 h-7 rounded-full flex justify-center items-center text-white absolute top-1 right-1'>{products.qty}
                                                    </div>
                                                </div>
                                                <div className="md:pl-6 md:w-5/6 w-full">
                                                    <div className="flex items-center justify-between w-full pt-1">
                                                        <p className="text-[20px] font-light leading-none text-gray-800">{products.name}</p>
                                                    </div>
                                                    <div className="flex items-center justify-start pt-5 pr-6 gap-x-2">
                                                        <p className="text-[25px] font-bold leading-none text-gray-800">AED {products.selling_price}</p>
                                                        {
                                                            parseInt(products.mrp, 10) > parseInt(products.selling_price, 10) &&
                                                            <p className='mrp font-light line-through'>AED {products.mrp}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            }

                            <div className='bg-white p-3 rounded-[15px] mt-4'>
                                <textarea
                                    name="message"
                                    id="message"
                                    rows={2}
                                    className="block w-full rounded-md border-1 border-black p-3 placeholder:font-light"
                                    placeholder='Gift Card / Discount Coupon'
                                    defaultValue={coupen}
                                    onChange={(e) => {
                                        setCoupen(e.target.value)
                                    }}
                                />
                                <button
                                    className='flex items-center justify-center mt-2 ml-auto bg-black border-black font-light text-base gap-x-3 px-8 h-[45px]'
                                    onClick={onApplyCoupenClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21"
                                        viewBox="0 0 24.544 23.499">
                                        <g data-name="Group 609" transform="translate(-3428.879 -1078.603)" fill="none"
                                            stroke="#fff">
                                            <path data-name="Path 214"
                                                d="M3451.057 1091.393v8.329a1.879 1.879 0 0 1-1.878 1.879h-16.11a1.879 1.879 0 0 1-1.879-1.879v-8.329" />
                                            <rect data-name="Rectangle 970" width="23.544" height="5.763" rx="2.881"
                                                transform="translate(3429.379 1085.576)" />
                                            <path data-name="Line 150" d="M3441.165 1101.616v-16.074" />
                                            <path data-name="Path 215"
                                                d="M3444.848 1079.12a2.648 2.648 0 0 1 2.7 2.9c-.247 2.449-2.264 3.457-6.359 3.334a12.683 12.683 0 0 1 .72-4.589 3.114 3.114 0 0 1 2.939-1.645Z" />
                                            <path data-name="Path 216"
                                                d="M3437.517 1079.12a2.648 2.648 0 0 0-2.7 2.9c.247 2.449 2.264 3.457 6.359 3.334a12.683 12.683 0 0 0-.72-4.589 3.114 3.114 0 0 0-2.939-1.645Z" />
                                        </g>
                                    </svg>
                                    Apply
                                </button>
                            </div>
                            <div className='pt-6'>
                                <div className='pb-[20px]'>
                                    <p className="text-[25px] font-medium leading-9 text-gray-800">Order Summary</p>
                                    <div className="flex items-center justify-between pt-6 font-light">
                                        <p className="text-base leading-none text-gray-800">Order Total</p>
                                        <p className="text-base leading-none text-gray-800">AED {cartpageBilling.items_total}</p>
                                    </div>
                                    {
                                        cartpageBilling.hasOwnProperty("total_offer_discount") &&
                                        <div className="flex items-center justify-between pt-[10px] font-light">
                                            <p className="text-base leading-none text-gray-800">Offer Discount</p>
                                            <p className="text-base leading-none text-gray-800">-AED {cartpageBilling.total_offer_discount}</p>
                                        </div>
                                    }
                                    {
                                        cartpageBilling.hasOwnProperty("total_coupon_discount") &&
                                        <div className="flex items-center justify-between pt-[10px] font-light">
                                            <p className="text-base leading-none text-gray-800">Coupon Discount</p>
                                            <p className="text-base leading-none text-gray-800">-AED {cartpageBilling.total_coupon_discount}</p>
                                        </div>
                                    }
                                    {
                                        cartpageBilling.hasOwnProperty("vat_amount") && cartpageBilling.vat_amount > 0 &&
                                        <div className="flex items-center justify-between pt-[10px] font-light">
                                            <p className="text-base leading-none text-gray-800">Estimated VAT %</p>
                                            <p className="text-base leading-none text-gray-800">AED {cartpageBilling.vat_amount}</p>
                                        </div>
                                    }
                                    <div className="flex items-center justify-between pt-[10px] font-light">
                                        <p className="text-base leading-none text-gray-800">Shipping</p>
                                        <p className="text-base leading-none text-gray-800">{cartpageBilling.shipping_charge > 0 ? cartpageBilling.shipping_charge : "FREE"}</p>
                                    </div>
                                </div>
                                <div
                                    className="flex items-center pb-[6px] justify-between pt-[10px] border-t border-b">
                                    <p className="text-[25px] font-light leading-normal text-gray-800">Total Amount</p>
                                    <p className="text-[25px] font-medium leading-normal text-right text-gray-800">AED {cartpageBilling.total_payable}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>)
}

export default Checkout
