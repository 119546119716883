import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getOrderDetails, customerOrderCancel } from '../../redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar } from "react-icons/fa";
import { cancelOrderApiCall, createOrderRetunApi, writeReview } from '../../services/apiCalls';
import Review from '../../Components/Common/Review/Review';
import { getAllReturnReason } from '../../redux/order';
import {MdClear} from "react-icons/md";
import {TbPhotoPlus} from "react-icons/tb";


function MyOrderDetail() {

    const stars = Array(5).fill(0)
    const [showComponent, setShowComponent] = useState(false);
    const [returnPop, setReturnPop] = useState(false);
    const [returnProductList, setReturnProductList] = useState([]);
    const { returnReason } = useSelector((state) => state.order);

    const [formData, setFormData] = useState({
        reviewfirstname: "",
        reviewlastname: "",
        reviewemail: "",
        reviewfeedback: ""
    })

    const params = useParams()
    const id = params.id;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const handlesubmit = async () => {

        if (formData.reviewfirstname === "" || formData.reviewlastname === "" || formData.reviewemail === "" || currentValue == 0) {
            toast.error("Please fill the required details", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            setShow(true)
            return
        } else {
            const data = {
                "product_id": productId,
                "name": formData.reviewfirstname + formData.reviewlastname,
                "email": formData.reviewemail,
                "rating": currentValue,
                "review_body": formData.reviewfeedback,
                "review_title": "test"
            }
            const reviewData = await writeReview(data)
            setShow(false)
            if (reviewData.data.code === 1) {
                toast.success("Review Added successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.error("Review Not added", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }
    }
    const handleSetValues = (id) => {
        <Review id={id} />
        setShow(true)
        setProductId(id)

    }


    const [radio, setRadio] = useState(0);
    const [show, setShow] = useState(false);
    const [productId, setProductId] = useState("");


    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);

    const handleClick = value => {
        setCurrentValue(value)
    }

    const colors = {
        orange: "#FFBA5A",
        grey: "#a9a9a9"

    };

    const handleMouseOver = newHoverValue => {
        setHoverValue(newHoverValue)
    };

    const changedValue = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    }
    const handleMouseLeave = () => {
        setHoverValue(undefined)
    }

    const [modal, setModal] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const [rating, setRating] = useState(0)

    const { orderDetails } = useSelector((state) => state.profile);
    const { orderProductDetails } = useSelector((state) => state.profile);
    const { ordercancel } = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const [currentOrdreId, setcurrentOrdreId] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
        setcurrentOrdreId(id)
        dispatch(getOrderDetails(id))
        dispatch(getAllReturnReason())
    }, []);
    useEffect(() => {
        if (orderProductDetails !== undefined) {
            orderProductDetails.map((orderProductData, productindex) =>{
                setReturnProductList(Array(orderProductData.order_product_data.length).fill({ no: -1 }))
            })
        }
    }, [orderProductDetails])
    const cancelorderButton = async (orderId, flag) => {
        const paramData = {
            "order_id": orderId,
            "flag": flag,
            "from": "order"
        }
        const cancelOrder = await cancelOrderApiCall(paramData)
        if (cancelOrder.data.code === 1) {
            dispatch(getOrderDetails(id))
            toast.success("Order Cancelled Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            toast.error(cancelOrder.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const returnOrderButtonClick = async () => {
        let itemList = []
        returnProductList.map((product) => {
            if (product.no != -1) {
                itemList.push(product)
            }
        })
        const requestData = {
            "order_id": orderDetails.id,
            "items": itemList,
        };
        const createOrderReturn = await createOrderRetunApi(requestData)
        if (createOrderReturn.data.code === 1) {
            toast.success(createOrderReturn.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            dispatch(getOrderDetails(id))
            setReturnProductList([])
            setReturnPop(false)
        } else {
            toast.error(createOrderReturn.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const handleCheckboxChange = (index, value) => {
        const updatedReturnProductList = [...returnProductList];
        if (value === true) {
            updatedReturnProductList[index] = {
                no: index,
                deliveryId: orderProductDetails[0].order_product_data[0].deliveryId || "",
                productId: orderProductDetails[0].order_product_data[index].productId || "",
                reasonId: '1',
                reason: reasonFromId('1'), // Implement reasonFromId
                qty: 1,
            };
        } else {
            updatedReturnProductList[index] = {
                no: -1,
                productId: "",
                reason: null,
                reasonId: null,
                proof: null,
                qty: null,
                deliveryId: null,
            };
        }
        setReturnProductList(updatedReturnProductList);
    };

    const handleReasonChange = (index, reasonId) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            reasonId,
            reason: reasonFromId(reasonId), // Implement reasonFromId
        };
        setReturnProductList(updatedReturnProductList);
    };

    const handleQtyChange = (index, qty) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            qty,
        };
        setReturnProductList(updatedReturnProductList);
    };

    const uploadProductImage = (index, e) => {
        if (e !== null) {
            if (e.target.files && e.target.files.length > 0) {
                const updatedReturnProductList = [...returnProductList];
                updatedReturnProductList[index] = {
                    ...returnProductList[index],
                    proof: e.target.files[0],
                };
                setReturnProductList(updatedReturnProductList);
            }
        } else {
            const updatedReturnProductList = [...returnProductList];
            updatedReturnProductList[index] = {
                ...returnProductList[index],
                proof: e,
            };
            setReturnProductList(updatedReturnProductList);
        }
    }

    const handleCommentChange = (index, value) => {
        const updatedReturnProductList = [...returnProductList];
        updatedReturnProductList[index] = {
            ...returnProductList[index],
            reason: value,
        };
        setReturnProductList(updatedReturnProductList);
    };

    const reasonFromId = (id) => {
        const index = returnReason.findIndex(element => element.id === id);
        return index !== -1 ? returnReason[index].reasonCode : '';
    }

    return (
        <div className='my-order-details-wrapper'>
            {
                currentOrdreId.length != 0 &&
                <div className='my-order-details-container'>
                    {
                        orderDetails.map((orderfullData, fulldataindex) => (
                            orderfullData.delivery_date ?
                                <div key={fulldataindex}>
                                    <div className='order-id-wrapper'>
                                        <div className='order-id'>
                                            <p className='text-[25px] font-black'>DOC{currentOrdreId}</p>
                                            <p className='text-[15px] font-light'>Placed
                                                On {orderfullData.order_date}</p>
                                        </div>
                                    </div>
                                    <div className='order-details'>
                                        <div className='order-details-info'>
                                            <div className='delivery-Address'>
                                                <h3>Delivery Address</h3>
                                                <p>{orderfullData.f_name} {orderfullData.l_name}<br /> {orderfullData.building_name},{orderfullData.full_address}
                                                </p>
                                                <p className='mobile'>{orderfullData.mobile}</p>
                                            </div>
                                            <div className='order-summary'>
                                                <h3>Order Summary </h3>
                                                <div className='fieldset'>
                                                    <div className='field'>
                                                        <span>SubTotal </span>
                                                        <p>AED {orderfullData.items_total}</p>
                                                    </div>
                                                    <div className='field'>
                                                        <span>Shipping </span>
                                                        <p>{orderfullData.shipping_charge}</p>
                                                    </div>
                                                    <div className='field total'>
                                                        <strong>Total </strong>
                                                        <p>AED {orderfullData.total_payable}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-items'>
                                            {
                                                orderProductDetails.map((orderProductData, productindex) => (
                                                    orderProductData.order_product_data.map((productOrdered) => (
                                                        <div className='order-item' key={productindex}>
                                                            <div className='ordered-product-detail'>
                                                                {
                                                                    productOrdered.product_images.map((productImage) => (
                                                                        <div className='ordered-product-image-detail'>
                                                                            <img src={productImage.file} alt=''
                                                                                width={102}
                                                                                height={102} />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className='ordered-product-detail-info'>
                                                                <p className='name'>{productOrdered.product_name}</p>
                                                                <p className='price'>AED {productOrdered.total_price}</p>
                                                            </div>
                                                            <div className='actions'>
                                                                <button
                                                                    className='h-[36px] w-auto px-6 bg-black border-black font-normal text-base'
                                                                    onClick={() => handleSetValues(productOrdered.product_id)}>
                                                                    Write Review
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                ))
                                            }
                                        </div>
                                        {
                                            <Modal className='customer-review' show={show} fullscreen={fullscreen}
                                                onHide={() => setShow(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Customer Review</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <form className='customer-review-form'>
                                                        <label>FirstName : </label>
                                                        <input
                                                            value={formData.reviewfirstname}
                                                            onChange={changedValue}
                                                            type="text"
                                                            id="review-firstname"
                                                            name="reviewfirstname"
                                                            placeholder='FirstName'
                                                            required

                                                        />
                                                        <label>LastName : </label>
                                                        <input
                                                            value={formData.reviewlastname}
                                                            onChange={changedValue}
                                                            type="text"
                                                            id="review-lastname"
                                                            name="reviewlastname"
                                                            placeholder='LastName'
                                                            required

                                                        />
                                                        <label>E-mail : </label>
                                                        <input
                                                            onChange={changedValue}
                                                            value={formData.reviewemail}
                                                            type="text"
                                                            id="review-email"
                                                            name="reviewemail"
                                                            placeholder='Email'
                                                            className='col-span-2'
                                                            required

                                                        />
                                                        <div className='flex star-rating'>
                                                            <span className='label'>Rating</span>
                                                            <div className='star flex gap-2 py-2 px-3 bg-white rounded-full'>
                                                                {stars.map((_, index) => {
                                                                    return (
                                                                        <FaStar
                                                                            key={index}
                                                                            size={24}
                                                                            onClick={() => handleClick(index + 1)}
                                                                            onMouseOver={() => handleMouseOver(index + 1)}
                                                                            onMouseLeave={handleMouseLeave}
                                                                            color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                                                                            style={{
                                                                                marginRight: 0,
                                                                                cursor: "pointer"
                                                                            }}
                                                                            required
                                                                        />
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className='feedback'>
                                                            <span className='label'>FeedBack </span>
                                                            <textarea
                                                                value={formData.reviewfeedback}
                                                                onChange={changedValue}
                                                                name='reviewfeedback'
                                                                placeholder='Write your comments here'
                                                            />
                                                        </div>

                                                    </form>
                                                    <div className='actions text-center'>
                                                        <button onClick={() => {
                                                            handlesubmit()
                                                        }} type='submit' className='h-36px bg-[#41BDDE]'>Submit
                                                        </button>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>
                                        }
                                        {
                                            orderDetails.map((orderstatusData, index) => (
                                                <div className='flex justify-between order-status-bottom' key={index}>
                                                    <p className={orderstatusData.order_status}>{orderstatusData.order_status}</p>
                                                    {
                                                        orderstatusData.can_order_cancel === 1 &&
                                                        <div>
                                                            <a className='cancleorder'
                                                                onClick={() => cancelorderButton(orderstatusData.id, "1")}>cancel
                                                                Order</a>
                                                        </div>
                                                    }
                                                    {
                                                        orderstatusData.is_returnable === 1 &&
                                                        <div>
                                                            <a className='cancleorder'
                                                                onClick={() => setReturnPop(true)}>Return Order</a>
                                                        </div>
                                                    }

                                                </div>
                                            )
                                            )
                                        }
                                    </div>
                                    {
                                        <Modal className='select-return-products' show={returnPop}
                                               fullscreen={false}
                                               onHide={() => setReturnPop(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Return Products</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='fieldset'>
                                                    <ul className='order-items'>
                                                        {
                                                            orderProductDetails.map((orderProductData, productindex) => (
                                                                orderProductData.order_product_data.map((product, i) => (
                                                                <li className='order-item flex flex-column gap-2 items-start first-of-type:pb-3'
                                                                    key={i}>
                                                                    <div className='order-item-info w-full flex gap-2'>
                                                                        <img
                                                                            src={(product.product_images !== undefined && product.product_images.length > 0) ? product.product_images[0].file : ''}
                                                                            alt=""
                                                                            className='w-16 h-16 rounded-xl'
                                                                        />
                                                                        <div className='order-item-details block w-full'>
                                                                            <div
                                                                                className='flex flex-column pr-5 relative gap-2'>
                                                                                <span>{product.product_name}</span>
                                                                                <span
                                                                                    className='font-semibold'>AED {product.total_price || ''}</span>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={returnProductList.some(item => item.no === i)}
                                                                                    onChange={e => handleCheckboxChange(i, e.target.checked)}
                                                                                    className='mt-2 absolute top-0 right-0'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {returnProductList.some(item => item.no === i) && (
                                                                        <div
                                                                            className='w-full'>
                                                                            <div
                                                                                className='flex flex-wrap justify-between'>
                                                                                <select
                                                                                    value={returnProductList[i].reasonId}
                                                                                    onChange={e => handleReasonChange(i, e.target.value)}
                                                                                    className='bg-transparent p-2 border w-full rounded-md text-xs'
                                                                                >
                                                                                    {returnReason.map((reason) => (
                                                                                        <option
                                                                                            className='text-xs'
                                                                                            key={reason.id}
                                                                                            value={reason.id}>
                                                                                            {reason.reason_code}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                <div
                                                                                    className='inline-flex items-center gap-2 mt-3 p-2'>
                                                                                            <span
                                                                                                className='text-xs'>Qty</span>
                                                                                    <select
                                                                                        value={returnProductList[i].qty}
                                                                                        onChange={e => handleQtyChange(i, e.target.value)}
                                                                                        className='w-auto min-w-[80px] bg-transparent px-2 text-xs'
                                                                                    >
                                                                                        {Array.from({length: product.qty}).map((_, indexQty) => (
                                                                                            <option
                                                                                                className='text-xs'
                                                                                                key={indexQty}
                                                                                                value={indexQty + 1}>
                                                                                                {`${indexQty + 1}`}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                                {returnProductList[i].reasonId === '2' || returnProductList[i].reasonId === '3' ? (
                                                                                    returnProductList[i].proof ? (
                                                                                        <div className='inline-flex items-center gap-1 max-md:mt-4'>
                                                                                            <span className='text-xs text-gray-500 max-w-[140px] whitespace-nowrap text-ellipsis overflow-hidden'>{URL.createObjectURL(returnProductList[i].proof)}</span>
                                                                                            <button
                                                                                                className='bg-transparent p-0 border-none focus:border-none text-xl text-gray-400 h-auto hover:border-none'
                                                                                                onClick={() => uploadProductImage(i, null)}>
                                                                                                        <span
                                                                                                            className='sr-only'>Clear</span>
                                                                                                <MdClear/>
                                                                                            </button>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <label
                                                                                            htmlFor='file-upload-product-pic'
                                                                                            className='add-photo w-full h-auto ml-auto relative mt-3 overflow-hidden cursor-pointer bg-transparent border-none text-xl text-gray-400 flex items-center gap-2'>
                                                                                            <span
                                                                                                className='text-xs'>Add a Photo</span>
                                                                                            <TbPhotoPlus/>
                                                                                            <input
                                                                                                type="file"
                                                                                                id="file-upload-product-pic"
                                                                                                name="file_upload_profile_pic"
                                                                                                className='opacity-0 absolute inset-0 z-0 w-full h-full cursor-pointer'
                                                                                                onChange={(e) => {
                                                                                                    uploadProductImage(i, e);
                                                                                                }}
                                                                                            />
                                                                                        </label>
                                                                                    )
                                                                                ) : null}
                                                                                {returnProductList[i].reasonId === '5' && (
                                                                                    <textarea
                                                                                        type="text"
                                                                                        value={returnProductList[i].reason}
                                                                                        onChange={e => handleCommentChange(i, e.target.value)}
                                                                                        placeholder="Comment"
                                                                                        className='comment mt-3 rounded-md text-xs placeholder:text-xs w-full'
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            ))
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <button className='w-full mt-3 bg-black border-black'
                                                        onClick={returnOrderButtonClick}>Return Order
                                                </button>
                                            </Modal.Body>
                                        </Modal>
                                    }
                                </div> : <></>

                        ))
                    }
                    <div>

                    </div>
                </div>
            }
            <Link>
                <button onClick={goBack}>Back</button>
            </Link>
        </div>
    )
}

export default MyOrderDetail