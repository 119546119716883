import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector, connect, useStore } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import MultiRangeSlider from "multi-range-slider-react";
import speedy from '../../../assets/images/speedyIcon.png'
import './ProductFilter.scss';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import {
    ChevronDownIcon,
    ChevronUpIcon,
    FunnelIcon,
    MinusIcon,
    PlusIcon,
    Squares2X2Icon
} from '@heroicons/react/20/solid'
import { checkValidJson } from './../../../services/commonServices';
import {
    processFilterBrandsThunk,
    processFilterPriceRangeThunk,
    processFilterOffersThunk
} from './../../../redux/subCategory';
import { Link, useHref } from 'react-router-dom';

function ProductFilter({ target, selectedFilterValues, onChangeselectedFilterOptions }) {

    const dispatch = useDispatch();
    const store = useStore();
    const [selectFlagsValue, setSelectFlagsValue] = useState([]);
    const [selectBrandsValue, setSelectBrandsValue] = useState([]);
    const [selectFulfilmentValue, setSelectFulfilmentValue] = useState([]);
    const [selectOffersValue, setSelectOffersValue] = useState([]);
    const [minPriceRangeValue, setMinPriceRangeValue] = useState(0);
    const [maxPriceRangeValue, setMaxPriceRangeValue] = useState(3999);
    let selectFlags = useRef([1]);
    let selectSortFlag = useRef(1);
    let selectBrands = useRef([]);
    let selectFulfilment = useRef([]);
    let selectPriceMin = useRef(0);
    let selectPriceMax = useRef(3999);
    let selectOffers = useRef([]);
    let priceRangeChangeCount = 0;

    const { minPriceOption, maxPriceOption} = useSelector((state) => state.subCategory);

    const fullUrl = useHref();
    const urlSplit = fullUrl.split("/");

    useEffect(() => {
        dispatchProductFilterApis()
        setFilterValues()
        return
    }, []);

    useEffect(() => {
        setFilterValues()
        return
    }, [minPriceOption,maxPriceOption]);

    const dispatchProductFilterApis = () => {
        let postData = {};
        if (target.type === 'featured_list') {
            postData['flag'] = 1;
            postData['featured_list_id'] = target.id;
        } else if (target.type === 'sub_categories') {
            postData['flag'] = 2;
            postData['sub_category_id'] = target.id;
            postData['handle_category'] = findCategoryHandle();
        } else if (target.type === 'collections') {
            postData['flag'] = 3;
            postData['collection_mapping_id'] = target.id;
        } else if (target.type === 'similar_products') {
            postData['flag'] = 4;
            postData['product_id'] = target.id;
            if (target.hasOwnProperty('similarFlag')) {
                postData['similar_flag'] = target.similarFlag;
            }
        } else if (target.type === 'brands') {
            postData['flag'] = 5;
            postData['brand_id'] = target.id;
        }
        dispatch(processFilterBrandsThunk(postData));
        dispatch(processFilterPriceRangeThunk(postData));
        dispatch(processFilterOffersThunk(postData));
        console.log('newFiltersSelected Filter : ', selectedFilterValues);
    }

    const setFilterValues = () => {
        selectFlags.current = [1];
        if (selectedFilterValues.hasOwnProperty('filter_flag')) {
            selectFlags.current = JSON.parse(selectedFilterValues['filter_flag']);
        } else if (selectedFilterValues.hasOwnProperty('flag')) {
            selectFlags.current = JSON.parse(selectedFilterValues['flag']);
        }
        sortOptions.forEach((sortEl, indexer) => {
            let currentKey = selectFlags.current.indexOf(sortEl.value);
            if (currentKey >= 0) {
                selectSortFlag.current = sortEl.value;
            }
        });
        selectFulfilment.current = (selectedFilterValues['speedy'] === 1) ? [1] : [];
        selectBrands.current = (
            selectedFilterValues.hasOwnProperty('brand_id')
            && checkValidJson(selectedFilterValues['brand_id'])
            && Array.isArray(JSON.parse(selectedFilterValues['brand_id']))
        ) ? JSON.parse(selectedFilterValues['brand_id']) : [];
        selectOffers.current = (
            selectedFilterValues.hasOwnProperty('offer_id')
            && checkValidJson(selectedFilterValues['offer_id'])
        ) ? JSON.parse(selectedFilterValues['offer_id']) : [];
        selectPriceMin.current = (
            selectedFilterValues.hasOwnProperty('from_price')
            && (parseInt(selectedFilterValues['from_price']) >= store.getState().subCategory.minPriceOption)
        ) ? parseInt(selectedFilterValues['from_price']) : store.getState().subCategory.minPriceOption;
        selectPriceMax.current = (
            selectedFilterValues.hasOwnProperty('to_price')
            && (parseInt(selectedFilterValues['to_price']) <= store.getState().subCategory.maxPriceOption)
        ) ? parseInt(selectedFilterValues['to_price']) : store.getState().subCategory.maxPriceOption;
        setSelectFlagsValue(current => selectFlags.current);
        setSelectBrandsValue(current => selectBrands.current);
        setSelectFulfilmentValue(current => selectFulfilment.current);
        setSelectOffersValue(current => selectOffers.current);
        setMinPriceRangeValue(current => selectPriceMin.current);
        setMaxPriceRangeValue(current => selectPriceMax.current);
    }

    const handlePriceRangeChangeInput = (e) => {
        setMinPriceRangeValue(current => e.minValue);
        setMaxPriceRangeValue(current => e.maxValue);
        selectPriceMin.current = e.minValue;
        selectPriceMax.current = e.maxValue;
        if (priceRangeChangeCount % 2 === 0) {
            passOnSelectedFilterOptopns();
        }
        priceRangeChangeCount++;
    };

    const handleSortOrderCheckChange = (e) => {
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        if (currentValueChecked === true) {
            selectSortFlag.current = currentEventValue;
        }
        let sortFlagArray = selectFlagsValue;
        sortOptions.forEach((sortEl, indexer) => {
            let currentKey = sortFlagArray.indexOf(sortEl.value);
            if (currentKey >= 0) {
                sortFlagArray.splice(currentKey, 1);
            }
        });
        sortFlagArray.push(currentEventValue);
        selectFlags.current = sortFlagArray;
        setSelectFlagsValue(current => sortFlagArray);
        passOnSelectedFilterOptopns();
    };

    const handleFulfillmentCheckChange = (e) => {
        selectFulfilment.current = selectFulfilmentValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectFulfilment.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectFulfilment.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectFulfilment.current.splice(currentKey, 1);
        }
        setSelectFulfilmentValue(current => selectFulfilment.current);
        passOnSelectedFilterOptopns();
    };

    const handleBrandsCheckChange = (e) => {
        selectBrands.current = selectBrandsValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectBrands.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectBrands.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectBrands.current.splice(currentKey, 1);
        }
        setSelectBrandsValue(current => selectBrands.current);
        passOnSelectedFilterOptopns();
    };

    const handleOffersCheckChange = (e) => {
        selectOffers.current = selectOffersValue;
        let currentEventValue = parseInt(e.target.value);
        let currentValueChecked = e.target.checked;
        let currentKey = selectOffers.current.indexOf(currentEventValue);
        if ((currentValueChecked === true) && (currentKey < 0)) {
            selectOffers.current.push(currentEventValue);
        }
        if ((currentValueChecked === false) && (currentKey >= 0)) {
            selectOffers.current.splice(currentKey, 1);
        }
        setSelectOffersValue(current => selectOffers.current);
        passOnSelectedFilterOptopns();
    };

    const passOnSelectedFilterOptopns = () => {
        let passsingFilterOptions = {};
        if (target.type === 'featured_list') {
            passsingFilterOptions['featured_list_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'sub_categories') {
            passsingFilterOptions['sub_category_id'] = target.id;
            passsingFilterOptions['handle_sub_category'] = target.handle;
            passsingFilterOptions['handle_category'] = findCategoryHandle();
        } else if (target.type === 'collections') {
            // passsingFilterOptions['collection_mapping_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'similar_products') {
            passsingFilterOptions['product_id'] = target.id;
        } else if (target.type === 'brands') {
            passsingFilterOptions['brand_id'] = target.id;
            passsingFilterOptions['handle'] = target.handle;
        } else if (target.type === 'search') {
            passsingFilterOptions['search'] = target.handle;
        }
        let flagArray = [];
        if (selectSortFlag.current > 0) {
            flagArray.push(selectSortFlag.current);
        }
        passsingFilterOptions['speedy'] = ((selectFulfilment.current.length > 0) && (selectFulfilment.current.indexOf(1) >= 0)) ? 1 : 0;
        if ((minPriceRangeValue >= 0) && (maxPriceRangeValue >= 0)) {
            flagArray.push(7);
            passsingFilterOptions['from_price'] = selectPriceMin.current;
            passsingFilterOptions['to_price'] = selectPriceMax.current;
        }
        if ((target.type !== 'brands') && (selectBrands.current.length > 0)) {
            flagArray.push(6);
            passsingFilterOptions['brand_id'] = JSON.stringify(selectBrands.current);
        }
        if (selectOffers.current.length > 0) {
            flagArray.push(10);
            passsingFilterOptions['offer_id'] = JSON.stringify(selectOffers.current);
        }
        if (target.type === 'similar_products') {
            passsingFilterOptions['filter_flag'] = JSON.stringify(flagArray);
        } else {
            passsingFilterOptions['flag'] = JSON.stringify(flagArray);
        }
        onChangeselectedFilterOptions(passsingFilterOptions);
    };

    const findCategoryHandle = () => {
        if (urlSplit.length > 2) {
            return urlSplit[1]
        } else {
            return ""
        }
    }

    const sortOptions = [
        { label: 'Price: Low to High', value: 1, checked: true },
        { label: 'Price: High to Low', value: 2, checked: false },
        { label: 'Most Popular', value: 3, checked: false },
        { label: 'Discount', value: 4, checked: false },
        { label: 'Best Rating', value: 5, checked: false },
    ];

    const fulfillmentOptions = [
        { label: <img src={speedy} className='w-2/6' />, value: 1, checked: false },
    ];

    return (
        <form className="mt-4">
            {
                !selectFlagsValue.every(item => item === 1) && <Link className='hover:text-btn-color text-sm underline'>Clear All</Link>
            }
            <Disclosure as="div" key={'sortOrder'}
                className="border-t first-of-type:border-t-0 border-gray-200 block"
                defaultOpen={true}>
                {({ open }) => (
                    <>
                        <h3 className="flow-root">
                            <Disclosure.Button
                                className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                <span className="font-medium text-black">Sort Order</span>
                                <div className="ml-6 flex items-center">
                                    {open ? (
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    )}
                                </div>
                            </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel className="pt-0">
                            <div className="space-y-3 pb-3">
                                {sortOptions.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                        <input
                                            id={`filter-mobile-sortOrder-${optionIdx}`}
                                            name={`sortOrder`}
                                            defaultValue={option.value}
                                            type="radio"
                                            checked={(selectFlagsValue.indexOf(parseInt(option.value)) >= 0)}
                                            onChange={(e) => {
                                                handleSortOrderCheckChange(e);
                                            }}
                                            className="h-4 w-4"
                                        />
                                        <label
                                            htmlFor={`filter-mobile-sortOrder-${optionIdx}`}
                                            className="ml-3 min-w-0 flex-1 font-light text-xs text-gray-500"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure as="div" key={'fulfillment'}
                className="border-t first-of-type:border-t-0 border-gray-200 block"
                defaultOpen={true}>
                {({ open }) => (
                    <>
                        <h3 className="flow-root">
                            <Disclosure.Button
                                className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                <span className="font-medium text-black">Fulfillment</span>
                                <span className="ml-6 flex items-center">
                                    {open ? (
                                        // <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    ) : (
                                        // <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    )}
                                </span>
                            </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel className="pt-0">
                            <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                {fulfillmentOptions.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                        <input
                                            id={`filter-mobile-fulfillment-${optionIdx}`}
                                            name={`fulfillment[]`}
                                            defaultValue={option.value}
                                            type="checkbox"
                                            checked={(selectFulfilmentValue.indexOf(parseInt(option.value)) >= 0)}
                                            onChange={(e) => {
                                                handleFulfillmentCheckChange(e);
                                            }}
                                            className="h-4 w-4 border-2 border-gray-300"
                                        />
                                        <label
                                            htmlFor={`filter-mobile-fulfillment-${optionIdx}`}
                                            className="ml-3 min-w-0 flex-1 font-light text-xs text-gray-500"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <Disclosure as="div" key={'brand'}
                className="border-t first-of-type:border-t-0 border-gray-200 block"
                defaultOpen={true}>
                {({ open }) => (
                    <>

                        <h3 className="flow-root">
                            <Disclosure.Button
                                className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                <span className="font-medium text-black">Brands</span>
                                <span className="ml-6 flex items-center">
                                    {open ? (
                                        // <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    ) : (
                                        // <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    )}
                                </span>
                            </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel className="pt-0">
                            <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                {store.getState().subCategory.brandOptions.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                        <input
                                            id={`filter-mobile-brand-${optionIdx}`}
                                            name={`brand[]`}
                                            defaultValue={option.value}
                                            type="checkbox"
                                            checked={(selectBrandsValue.indexOf(parseInt(option.value)) >= 0)}
                                            onChange={(e) => {
                                                handleBrandsCheckChange(e);
                                            }}
                                            className="h-4 w-4"
                                        />
                                        <label
                                            htmlFor={`filter-mobile-brand-${optionIdx}`}
                                            className="ml-3 min-w-0 flex-1 font-light text-xs text-gray-500"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>

                    </>
                )}
            </Disclosure>

            <Disclosure as="div" key={'productPrices'}
                className="border-t first-of-type:border-t-0 border-gray-200 block"
                defaultOpen={true}>
                {({ open }) => (
                    <>
                        <h3 className="flow-root">
                            <Disclosure.Button
                                className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                <span className="font-medium text-black">Price</span>
                                <span className="ml-6 flex items-center">
                                    {open ? (
                                        // <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    ) : (
                                        // <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                        <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                    )}
                                </span>
                            </Disclosure.Button>
                        </h3>

                        <Disclosure.Panel className="pt-0">
                            <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                <MultiRangeSlider
                                    min={store.getState().subCategory.minPriceOption}
                                    max={store.getState().subCategory.maxPriceOption}
                                    step={5}
                                    ruler={false}
                                    labels={[minPriceRangeValue, maxPriceRangeValue]}
                                    minValue={minPriceRangeValue}
                                    maxValue={maxPriceRangeValue}
                                    /* onInput={(e) => {
                                        handlePriceRangeChangeInput(e);
                                    }}  */
                                    onChange={(e) => {
                                        handlePriceRangeChangeInput(e);
                                    }}
                                />
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {
                store.getState().subCategory.offerOptions.length > 0 &&
                <Disclosure as="div" key={'offers'}
                    className="border-t first-of-type:border-t-0 border-gray-200 block"
                    defaultOpen={true}>
                    {({ open }) => (
                        <>

                            <h3 className="flow-root">
                                <Disclosure.Button
                                    className="flex w-full items-center justify-between border-0 bg-white px-0 py-3 text-gray-400 hover:text-gray-500 text-base">
                                    <span className="font-medium text-black">Offers</span>
                                    <span className="ml-6 flex items-center">
                                        {open ? (
                                            // <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                            <ChevronUpIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                        ) : (
                                            // <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                            <ChevronDownIcon className="-mr-1 h-6 w-6 text-black" aria-hidden="true" />
                                        )}
                                    </span>
                                </Disclosure.Button>
                            </h3>

                            <Disclosure.Panel className="pt-0">
                                <div className="space-y-4 pb-3 max-h-[200px] overflow-auto">
                                    {store.getState().subCategory.offerOptions.map((option, optionIdx) => (
                                        <div key={option.value} className="flex items-center">
                                            <input
                                                id={`filter-mobile-offers-${optionIdx}`}
                                                name={`offers[]`}
                                                defaultValue={option.value}
                                                type="checkbox"
                                                checked={(selectOffersValue.indexOf(parseInt(option.value)) >= 0)}
                                                onChange={(e) => {
                                                    handleOffersCheckChange(e);
                                                }}
                                                className="h-4 w-4"
                                            />
                                            <label
                                                htmlFor={`filter-mobile-offers-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 font-light text-xs text-gray-500"
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </Disclosure.Panel>

                        </>
                    )}
                </Disclosure>
            }

        </form>
    );
}

export default ProductFilter;
