import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHomeData, getSiteMap } from "../services/apiCalls";

export const getHome = createAsyncThunk("Home/getHomeData", async () => {
    const res = await getHomeData();
    await getSiteMap();
    return res.data;
})

const INITIAL_STATE = {
    dashboardList: [],
    masterCategories: [],
    loader: false,
}

const homeSlice = createSlice({
    name: "home",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHome.pending, (state, action) => {
                console.log("pendning");
                state.loader = true;
            })
            .addCase(getHome.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    state.dashboardList = action.payload.home_data_list;
                    state.dashboardList.map((element)=>{
                        if(element.hasOwnProperty("category")){
                            state.masterCategories = element.category
                        }
                    })
                }else{
                    state.dashboardList = []
                    state.masterCategories = []
                }
                state.loader = false;
                console.log("completed");
            })
            .addCase(getHome.rejected, (state, action) => {
                state.loader = false;
                console.log("error api issue : ", action)
            })
    }
})

export default homeSlice.reducer;