import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getprivacypolicy} from '../../redux/customerservices';
import {Container} from 'react-bootstrap';

function PrivacyPolicy() {


    const dispatch = useDispatch();
    const {Privacy} = useSelector((state) => state.customerServices);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getprivacypolicy())
        return
    }, []);
    return (
        <Container>

            <div>
                {Privacy.map((details) => {

                    return (
                        <div dangerouslySetInnerHTML={{__html: details.description}}/>

                    );

                })}

            </div>
        </Container>
    );
}

export default PrivacyPolicy

