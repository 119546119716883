export const customerToken = "lhSVOzt71E9cIrrV1Z5J+g==";
export const basicAuthToken = "UExLVC0sOV9kNjNZR1lJYzg3KF41OlBMS1JuNzJeOFlLcVJpcDh2XmEjfA==";
export const basicAuth = "Basic UExLVC0sOV9kNjNZR1lJYzg3KF41OlBMS1JuNzJeOFlLcVJpcDh2XmEjfA==";
export const BaseUrl = "https://admin-old.docibonline.com/App/V1/";



export const getHome = () => (
    "Home/getHomeData"
)                  

export const getOrderListData = () => (
    "Transaction/getOrderList"
)
export const cancelOrderEndpoint = () => (
    "Transaction/cancelOrder"
)
export const precriptionItemRemoveEndpoint = () => (
    "Transaction/deletePrescribedCartProduct"
)

export const addPrecriptionDataEndpoint = () => (
    "Product/addPrescription"
)

export const getPrecriptionStatusEndpoint = () => (
    "Product/getPrescription"
)
export const getPrescriptionDetailsEndpoint = () => (
    "Product/getPrescriptionDetails"
)

export const getCartApi = () => (
    "Transaction/cartProductList"
)
export const getCartPrecriptionApi = () => (
    "Transaction/prescribedCartProductList"
)
export const getAddressEndpoint = () => (
    "auth/userAddressList"
)

export const getOrderDetailsbyId = () => (
    "Transaction/getOrderDetails"
)
export const getAddAddressEndpoint = () => (
    "auth/addUserAddress"
)

export const getHandleDetailsApiUrl = () => (
    "Product/getHandleDetails"
)

export const getEmiratesEndpoint = () => (
    "auth/getEmirate"
)

export const getCategoryCollectionUrl = () => (
    "Product/getCategoryCollection"
)
export const getAllCategoryProductsUrl = () => (
    "Product/getAllCategoryProducts"
)
export const getAllCollectionProductsUrl = () => (
    "Product/getAllCollectionProducts"
)
export const getAllFeaturedProductsUrl = () => (
    "Product/getAllFeaturedProducts"
)
export const getAllBrandProductsUrl = () => (
    "Product/getAllBrandProducts"
)
export const getAllSimilarProductsUrl = () => (
    "Product/getAllSimilarProducts"
)
export const getAllOfferProductsUrl = () => (
    "Product/getAllOfferProducts"
)
export const getAllSearchProductsUrl = () => (
    "Product/search"
)
export const getAllProductsUrl = () => (
    "Product/getAllProduct"
)
export const getBrandsUrl = () => (
    "Product/getBrands"
)
export const getPriceRangeUrl = () => (
    "Product/getPriceRange"
)
export const getOffersUrl = () => (
    "Product/getOffers"
)
export const getFilterCollectionsUrl = () => (
    "Product/getFilterCollections"
)
export const getTagsUrl = () => (
    "Product/getTags"
)
export const getProductDetailsUrl = () => (
    "Product/getProductDetails"
)
export const getVariantProductDetailsUrl = () => (
    "Product/getVariantProductDetails"
)
export const getProductShareUrl = () => (
    "Product/getProductShareUrl"
)
export const addUserProductReviewUrl = () => (
    "Product/addUserProductReview"
)

export const getEditAddressEndpoint = () => (
    "auth/editUserAddress"
)
export const getDeleteAddressEndpoint = () => (
    "auth/deleteUserAddress"
)
export const addToCartUrl = () => (
    "Transaction/addToCart"
)
export const wishlistUrl = () => (
    "Product/addToWishlist"
)
export const writeReviewUrl = () => (
    "Product/addUserProductReview"
)
export const getUserLoginUrl = () => (
    "Auth/Login"
)
export const getUserRegisterUrl = () => (
    "Auth/userRegistration"
)
export const getUserSendOtpUrl = () => (
    "Auth/resendOTP"
)
export const getUserVerifyOtpUrl = () => (
    "Auth/verifyOTP"
)
export const getUserTokenUpdateUrl = () => (
    "Auth/updateToken"
)
export const getUserProfileUrl = () => (
    "Auth/myProfile"
)
export const getUserGetInterestsUrl = () => (
    "Auth/getInteres"
)
export const getUserUpdateInterestsUrl = () => (
    "Auth/updateInterest"
)
export const getUserLogoutUrl = () => (
    "Auth/logout"
)
export const getWishlistUrl = () => (
    "Product/getUserWishlistProducts"
)
export const getPrivacyPolicyUrl = () => (
    "Auth/getPrivacyPolicy"
)
export const getTermsconditionUrl = () => (
    "Auth/getTermscondition"
)
export const getRefundAndExchangeUrl = () => (
    "Auth/getRefundAndExchangePolicy"
)
export const getFaqUrl = () => (
    "Auth/getFaq"
)
export const getAllCategorysUrl = () => (
    "Product/getAllCategory"
)
export const getEditProfileUrl = () => (
    "Auth/editProfile"
)
export const getOrderDetailsUrl = () => (
    "Transaction/getOrderDetails"
)
export const getInterestsUrl = () => (
    "/Auth/getInteres"
)
export const getUpdateInterestsUrl = () => (
    "/Auth/updateInterest"
)
export const getUserNotificationsUrl = () => (
    "/auth/getNotification"
)

export const getGoogleAutocompleteFullUrl = () => (
    "https://maps.googleapis.com/maps/api/place/autocomplete/json"
)
export const getGooglePlaceDetailsFullUrl = () => (
    "https://maps.googleapis.com/maps/api/place/details/json"
)
export const getGoogleGeocodeFullUrl = () => (
    "https://maps.google.com/maps/api/geocode/json"
)

export const applyCoupenUrl = () => (
    "Transaction/applyCoupon"
)

export const placeOrderUrl = () => (
    "Transaction/placeOrder"
)

export const telrOrderCreationUrl = () => (
    "Transaction/orderCreation"
)

export const telrOrderResultUrl = () => (
    "Transaction/transactionResults"
)

export const postPayOrderCreationUrl = () => (
    "Transaction/orderCreationPostpay"
)

export const postPayOrderResultUrl = () => (
    "Transaction/transactionResultsPostpay"
)

export const updateOrderStatusUrl = () => (
    "Transaction/updateOrderStatus"
)

export const emailSubScribeUrl = () => (
    "Appicon/emailSubscribe"
)
export const getAllReturnReasonUrl = () => (
    "Transaction/getUserReturnReasons"
)
export const getSiteMapUrl = () => (
    "Home/getSitemapXml"
)
export const createOrderReturnUrl = () => (
    "Transaction/createOrderReturn"
)





