import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCart, getCartPrecription, precriptionItemRemoveApiCall } from "../services/apiCalls";

export const getCartPage = createAsyncThunk("cart/getCartData", async () => {
    const res = await getCart();
    return res.data;
})

export const getCartPagePrecription = createAsyncThunk("cart/getCartPrecriptionData", async () => {
    const res = await getCartPrecription();
    return res.data;
})

export const precriptionItemRemove = createAsyncThunk("cart/removeCartPrecriptionData", async (paramData) => {
    const res = await precriptionItemRemoveApiCall(paramData);
    return res.data;
})
const INITIAL_STATE = {
    cartpageBilling: {},
    cartpageItems: [],
    cartproductCount: 0,
    userAddress: {},
    cartPrecriptionBillingData: {},
    cartPrecriptionProductData: [],
    cartPrecriptionProductCount: [],
    removeCartPrecriptionProduct: [],
    deliverySlot: {},
    cartId: 0,
    cartTab: "1",
}

const cartSlice = createSlice({
    name: "cart",
    initialState: INITIAL_STATE,
    reducers: {
        cartTabIndexChange: (state, action) => {
            state.cartTab = action.payload;
         },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getCartPage.pending, (state, action) => {
                console.log("Api pendning");
            })
            .addCase(getCartPage.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    if (action.payload.hasOwnProperty("bill_details")) {
                        state.cartpageBilling = action.payload.bill_details
                    } else {
                        state.cartpageBilling = {}
                    }
                    if (action.payload.hasOwnProperty("cart_product")) {
                        state.cartpageItems = action.payload.cart_product
                    } else {
                        state.cartpageItems = []
                    }
                    if (action.payload.hasOwnProperty("cart_product_count")) {
                        state.cartproductCount = action.payload.cart_product_count
                    } else {
                        state.cartproductCount = 0
                    }
                    if (action.payload.hasOwnProperty("user_address")) {
                        state.userAddress = action.payload.user_address
                    } else {
                        state.userAddress = {}
                    }
                    if (action.payload.hasOwnProperty("delivery_slot")) {
                        state.deliverySlot = action.payload.delivery_slot[0]
                    } else {
                        state.deliverySlot = {}
                    }
                    if (action.payload.hasOwnProperty("cart_product")) {
                        const cartFirstProduct = action.payload.cart_product[0]
                        state.cartId = cartFirstProduct.cart_id
                    } else {
                        state.cartId = 0
                    }

                }
                else {
                    state.cartpageBilling = {}
                    state.cartpageItems = []
                    state.cartproductCount = 0
                    state.userAddress = {}
                }
            })
            .addCase(getCartPage.rejected, (state, action) => {
                console.log("Api rejected");
            })
            .addCase(getCartPagePrecription.pending, (state, action) => {
                console.log("Api pending");
            })
            .addCase(getCartPagePrecription.fulfilled, (state, action) => {
                if (action.payload.code === 1) {
                    console.log("action ", action.payload);
                    state.cartPrecriptionBillingData = action.payload.bill_details
                    state.cartPrecriptionProductData = action.payload.cart_product
                    state.cartPrecriptionProductCount = action.payload.cart_product_count
                }
                else {
                    state.cartPrecriptionBillingData = {}
                    state.cartPrecriptionProductData = []
                    state.cartPrecriptionProductCount = []
                }
            })
            .addCase(getCartPagePrecription.rejected, (state, action) => {
                console.log("Api rejected");
            })
            .addCase(precriptionItemRemove.pending, (state, action) => {
                state.removeCartPrecriptionProduct = action.payload
                console.log("state.removeCartPrecriptionProduct ", action);
                console.log("Api pending");
            })
            .addCase(precriptionItemRemove.fulfilled, (state, action) => {
                console.log("Api fulfilled");
            })
            .addCase(precriptionItemRemove.rejected, (state, action) => {
                console.log("Api rejected");
            })
    }
})

export const { cartTabIndexChange } = cartSlice.actions;

export default cartSlice.reducer;