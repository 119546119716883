import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../redux/category";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function CategoryListingPage() {
    const dispatch = useDispatch();
    const { allCategoryList } = useSelector((state) => state.category);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getAllCategory());

        return;
    }, []);
    return (
        <Container>
            <div>
                {allCategoryList.map((mastercategory, index) => {
                    return (
                        <>
                            <div className="category-accordion-wrapper" key={index}>

                                <Accordion allowZeroExpanded>
                                    <AccordionItem className='mb-4' key={index}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <img src={mastercategory.cat_page_image} />
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <ul className="flex gap-2 text-xs whitespace-nowrap overflow-x-auto">
                                                {mastercategory.sub_categories.map((childcategory) => {
                                                    return (
                                                        <li className="border border-black text-black rounded-full py-2 px-3 flex items-center text-sm font-light">
                                                            <Link to={childcategory.handle}>
                                                                {childcategory.name}
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                                <li className="border border-black text-black rounded-full py-2 px-3 flex items-center text-sm font-light">
                                                    <Link to={mastercategory.handle}>View All</Link>
                                                </li>
                                            </ul>

                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </>
                    );
                })}
            </div>
        </Container>
    );
}

export default CategoryListingPage;
