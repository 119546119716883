import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { getEmiratesData, setEditedAddressData } from '../../redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
function UpdateAddress() {
    const pathURL = '/myProfile';
    const listAddressURL = '/my-address'
    const location = useLocation()
    const existingData = location.state ? location.state.existingData : false;
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            address_type: existingData ? existingData.address_type : 0,
        },
        mode: "onChange"
    });
    const { editedAddressData } = useSelector(state => state.profile);
    const { getEmiratesList } = useSelector(state => state.profile);
    const [buildingName, setBuildingName] = useState(existingData ? existingData.building_name : '');
    const [mobile, setMobile] = useState(existingData ? existingData.mobile : '');
    const [city, setCity] = useState(existingData ? existingData.city : '');
    const [fullAddress, setFullAddress] = useState(existingData ? existingData.full_address : '');
    const [firstName, setFirstName] = useState(existingData ? existingData.f_name : '');
    const [lastName, setLastName] = useState(existingData ? existingData.l_name : '');
    const [isEditAddress, setIsEditAddress] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        const formattedData = { ...data };
        if (data.is_default === true) {
            formattedData.is_default = 1;
        }
        else if (data.is_default === false) {
            formattedData.is_default = 0;
        }
        dispatch(setEditedAddressData(formattedData));
        navigate(pathURL + listAddressURL);
        toast.success("Address Edited Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsEditAddress(editedAddressData);
        if (isEditAddress) {
            navigate(pathURL);
            setIsEditAddress(false);
        }
    }, [editedAddressData]);
    useEffect(() => {
        dispatch(getEmiratesData());
    }, [dispatch]);
    useEffect(() => {
        if (existingData) {
            if (existingData.is_default === "1") {
                setEnabled(true);
            }
            else if (existingData.is_default === "0") {
                setEnabled(false);
            }
        }
        else {
            setEnabled(false);
        }
    }, [existingData ? existingData : ''])
    return (
        <div>
            <h2 className='text-3xl font-medium md:mb-12'>Edit Address</h2>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-between items-center mb-4'>
                        <div className='inline-flex'>
                            <label className='sr-only'>Address Type</label>
                            <div className='flex gap-6 address-type'>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio" value={1} {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Home</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                value={2}  {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Work</span>
                                    </div>
                                </label>
                                <label>
                                    <div>
                                        <span className='input_field'>
                                            <input type="radio"
                                                value={3}  {...register("address_type", { required: true })} />
                                        </span>
                                        <span>Other</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="inline-flex">
                            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                                <input
                                    {...register("is_default")}
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={enabled}
                                    readOnly
                                />
                                <div
                                    onClick={() => {
                                        setEnabled(!enabled);
                                    }}
                                    className="w-11 h-6 outline outline-1 outline-black border-black rounded-full  peer-focus:ring-black-300  peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-black after:border-black after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-400"
                                ></div>
                                <span className="ml-2 text-base font-light text-gray-900">
                                    Set as default
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        <label className='sr-only'>First Name</label>
                        <input placeholder={errors.f_name ? 'First Name Required' : 'First Name'} className={errors.f_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'} defaultValue={firstName}
                            onChange={(e) => setFirstName(e.target.value)} {...register("f_name", { required: true })} />
                        <label className='sr-only'>Last Name</label>
                        <input placeholder={errors.l_name ? 'Last Name Required' : 'Last Name'} className={errors.l_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'} defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)} {...register("l_name", { required: true })} />
                        <label className='sr-only'>Address</label>
                        <input placeholder={errors.full_address ? 'Address Required' : 'Address'} className={errors.full_address ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2'}
                            defaultValue={fullAddress}
                            onChange={(e) => setFullAddress(e.target.value)}  {...register("full_address", { required: true })} />
                        <label className='sr-only'>Apartment</label>
                        <input placeholder={errors.building_name ? 'Apartment Required' : 'Apartment'} className={errors.building_name ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light col-span-2'}
                            defaultValue={buildingName}
                            onChange={(e) => setBuildingName(e.target.value)} {...register("building_name", { required: true })} />
                        <label className='sr-only'>City</label>
                        <input placeholder={errors.city ? 'City Required' : 'City'} className={errors.city ? 'error h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'h-[50px] rounded-[25px] px-4 placeholder:font-light'} defaultValue={city}
                            onChange={(e) => setCity(e.target.value)} {...register("city", { required: true })} />
                        <label className='sr-only'>Emirate ID</label>
                        <select className='h-[50px] rounded-[25px] px-4 placeholder:font-light bg-white'
                            value={existingData ? existingData.emirate_master_id : '1'} {...register("emirate_master_id", { required: true })} >
                            {getEmiratesList.map((data, index) => {
                                return (
                                    <>
                                        <option key={index} value={data.id}>{data.name}</option>
                                    </>
                                );
                            })}
                        </select>
                        <div className='flex w-full col-span-2 gap-3'>
                            <div className='flex w-full gap-2'>
                                <label className='sr-only'>Phone</label>
                                <span
                                    className='h-[50px] rounded-[25px] placeholder:font-light bg-white inline-flex justify-center items-center px-8'>+971</span>
                                <input placeholder={errors.mobile ? 'Contact Required' : 'Contact'} className={errors.mobile ? 'error block w-full h-[50px] rounded-[25px] px-4 placeholder:font-light' : 'block w-full h-[50px] rounded-[25px] px-4 placeholder:font-light'}
                                    defaultValue={mobile}
                                    onChange={(e) => setMobile(e.target.value)} {...register("mobile", { required: true })} />
                                <input hidden {...register("other_address_type_name")} />
                                <input hidden value={existingData.id} {...register("address_id", { required: true })} />
                            </div>
                            <input className='bg-[#41BDDE] rounded-full text-white px-3' value="Save Address"
                                type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateAddress;
