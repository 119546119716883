import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getRefundAndExchange } from '../../redux/customerservices';
import { Container } from 'react-bootstrap';

function RefundandExchange() {


  const dispatch = useDispatch();
  const { Refund } = useSelector((state) => state.customerServices);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRefundAndExchange())
    return
  }, []);


  return (
    <Container>

      <div>
        {Refund.map((details) => {

          return (
            <div dangerouslySetInnerHTML={{ __html: details.description }} />

          );

        })}

      </div>
    </Container>

  )
}

export default RefundandExchange
