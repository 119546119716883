import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { addPrecriptionData, getEmiratesData } from '../../redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container'
import { PhotoIcon } from '@heroicons/react/24/solid'
import icon_call from '../../assets/images/icons/icon_call.png'
import icon_chat from '../../assets/images/icons/icon_chat.png'
import icon_add_document from '../../assets/images/icons/icon_add_document.png'
import icon_close_bgblack from '../../assets/images/icons/icon_close_bgblack.png'
import { toast } from 'react-toastify';
import { addPrecriptionDataApicall } from '../../services/apiCalls';

function PrescriptionForm() {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            emirate_master_id: 3
        },
        mode: "onChange"
    });
    const { getEmiratesList } = useSelector(state => state.profile);
    const [prescription, setPrescription] = useState()
    const [frontemirateId, setfrontemirateId] = useState()
    const [backtemirateId, setbacktemirateId] = useState()
    const [insuranceid, setinsuranceid] = useState()

    const handleClick = () => {
        setPrescription()
        setfrontemirateId()
        setbacktemirateId()
        setinsuranceid()
    }

    const dispatch = useDispatch();
    const onSubmit = async (data) => {
        if (data.emirate_master_id === "3") {
            const precriptionResult = await addPrecriptionDataApicall(data)
            if (precriptionResult.data.code === 1) {
                toast.success("prescription uploded successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                reset()
                setPrescription()
                setfrontemirateId()
                setbacktemirateId()
                setinsuranceid()
            } else {
                toast.error("Something went wrong while uploading prescription", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }

        } else {
            toast.error("Service is only available in Dubai", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }

    };

    useEffect(() => {
        dispatch(getEmiratesData());
        console.log("emirates", getEmiratesList);
    }, []);

    const removeImage = (id) => {

        if (id === "frontemirateId") {
            setfrontemirateId()
        } else if (id === "prescription") {
            setPrescription()
        } else if (id === "backtemirateId") {
            setbacktemirateId()
        } else if (id === "insuranceid") {
            setinsuranceid()
        }
    }

    const prescriptionChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log("Hit1", e);
            setPrescription(e.target.files[0]);
            console.log("test ", e.target.files[0]);
            console.log(URL.createObjectURL(e.target.files[0]));
        }
    }
    const emirateIdFront = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log("Hit2", e);
            setfrontemirateId(e.target.files[0]);
            console.log("test ", e.target.files[0]);
            console.log("fdfdsfdfd", URL.createObjectURL(e.target.files[0]));
        }
    }
    const emirateIdBack = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log("Hit3", e);
            setbacktemirateId(e.target.files[0]);
            console.log("test ", e.target.files[0]);
            console.log(URL.createObjectURL(e.target.files[0]));
        }
    }
    const insuranceIdfront = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            console.log("Hit4", e);
            setinsuranceid(e.target.files[0]);
            console.log("test ", e.target.files[0]);
            console.log(URL.createObjectURL(e.target.files[0]));
        }
    }
    return (
        <>
            <Container
                className='mob bg-light-bg max-lg:bg-white p-6 rounded-[30px] mb-12 max-md:rounded-none max-md:p-4 max-md:px-0'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex items-center w-full flex-wrap'>
                        <div className='w-1/2 max-md:w-full'>
                            <div className='max-md:px-3 pb-4 flex flex-col gap-3'>
                                <div className='flex w-full'>
                                    <select
                                        className='h-12 px-3 rounded-lg bg-white border w-full max-w-[400px] text-sm' {...register("emirate_master_id")}
                                        required
                                    >
                                        <option value="">Select Emirate</option>

                                        {getEmiratesList.map((data, index) => (
                                            <option key={index} value={data.id}>{data.name}</option>))}
                                    </select>
                                </div>
                                <div className='flex w-full'>
                                    <select
                                        className='h-12 px-3 rounded-lg bg-white border w-full max-w-[400px] text-sm' {...register("is_insured")}
                                        required
                                    >
                                        <option value="">Select Insured or Not</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className='w-full pt-4 bg-light-bg max-md:px-3'>
                                <div className='grid grid-cols-2 grid-rows-2 gap-3 upload-precription-items'>
                                    <div className='pb-[100%] relative bg-white rounded-3xl upload-precription-item'>
                                        <label
                                            htmlFor="file-upload-precription"
                                            className="absolute cursor-pointer rounded-[50px] text-sm text-black font-light flex h-full w-full justify-center items-center flex-col gap-12"
                                        >

                                            <span className='w-6 font-thin'>
                                                <img
                                                    src={icon_add_document}
                                                    alt='icon add document'
                                                />
                                            </span>
                                            <input
                                                type="file"
                                                id="file-upload-precription"
                                                name="file-upload-precription"
                                                className="sr-only"
                                                required
                                                {...register("prescription")} onChange={(e) => {
                                                    prescriptionChange(e);
                                                }} />

                                            <span>Prescription</span>

                                            <span className='absolute top-0 left-0 w-full h-full object-cover'>
                                                {prescription && (
                                                    <img className='h-full w-full rounded-[50px] max-md:rounded-2xl'
                                                        src={URL.createObjectURL(prescription)}
                                                        alt='image'></img>)}
                                                <div
                                                    className='close absolute top-[-4px] right-[-4px] left-auto w-auto h-auto z-9 object-cover'>
                                                    <button
                                                        className='bg-transparent w-[36px] h-[36px] border-none text-black p-0'
                                                        type="button" onClick={() => removeImage("prescription")}>
                                                        <img src={icon_close_bgblack} />
                                                    </button>
                                                </div>
                                            </span>
                                        </label>
                                    </div>
                                    <div className='pb-[100%] relative bg-white rounded-3xl upload-precription-item'>
                                        <label
                                            htmlFor="file-upload-emirateidfront"
                                            className="absolute cursor-pointer rounded-md text-sm text-black font-light hover:text-indigo-500 flex h-full w-full justify-center items-center flex-col gap-12"
                                        >

                                            <span className='w-6 font-thin'>
                                                <img
                                                    src={icon_add_document}
                                                    alt='icon add document'
                                                />
                                            </span>
                                            <input
                                                type="file"
                                                id="file-upload-emirateidfront"
                                                name="file-upload-emirateidfront"
                                                className="sr-only"
                                                required
                                                {...register("emirateIdFront")} onChange={(e) => {
                                                    emirateIdFront(e);
                                                }} />

                                            <span>Emirate Id Front</span>

                                            <span className='absolute top-0 left-0 w-full h-full object-cover'>
                                                {frontemirateId && (
                                                    <img className='h-full w-full rounded-[50px] max-md:rounded-2xl'
                                                        src={URL.createObjectURL(frontemirateId)}
                                                        alt='image'></img>)}
                                                <div
                                                    className='close absolute top-[-15px] right-[-15px] w-full h-full z-9 object-cover'>
                                                    <button type="button" onClick={() => removeImage("frontemirateId")}>
                                                        <img src={icon_close_bgblack} />
                                                    </button>
                                                </div>
                                            </span>
                                        </label>
                                    </div>
                                    <div className='pb-[100%] relative bg-white rounded-3xl upload-precription-item'>
                                        <label
                                            htmlFor="file-upload-emirateidback"
                                            className="absolute cursor-pointer rounded-md text-sm text-black font-light hover:text-indigo-500 flex h-full w-full justify-center items-center flex-col gap-12"
                                        >

                                            <span className='w-6 font-thin'>
                                                <img
                                                    src={icon_add_document}
                                                    alt='icon add document'
                                                />
                                            </span>
                                            <input
                                                type="file"
                                                id="file-upload-emirateidback"
                                                name="file-upload-emirateidback"
                                                className="sr-only"
                                                required
                                                {...register("emirateIdBack")} onChange={(e) => {
                                                    emirateIdBack(e);
                                                }}
                                            />

                                            <span>Emirate Id Back</span>

                                            <span className='absolute top-0 left-0 w-full h-full object-cover'>
                                                {backtemirateId && (
                                                    <img className='h-full w-full rounded-[50px] max-md:rounded-2xl'
                                                        src={URL.createObjectURL(backtemirateId)}
                                                        alt='image'></img>)}
                                                <div
                                                    className='close absolute top-[-15px] right-[-15px] w-full h-full z-9 object-cover'>
                                                    <button type="button" onClick={() => removeImage("backtemirateId")}>
                                                        <img src={icon_close_bgblack} />
                                                    </button>
                                                </div>

                                            </span>
                                        </label>
                                    </div>
                                    <div className='pb-[100%] relative bg-white rounded-3xl upload-precription-item'>
                                        <label
                                            htmlFor="file-upload-insuranceid"
                                            className="absolute cursor-pointer rounded-md text-sm text-black font-light hover:text-indigo-500 flex h-full w-full justify-center items-center flex-col gap-12"
                                        >

                                            <span className='w-6 font-thin'>
                                                <img
                                                    src={icon_add_document}
                                                    alt='icon add document'
                                                />
                                            </span>
                                            <input
                                                type="file"
                                                id="file-upload-insuranceid"
                                                name="file-upload-insuranceid"
                                                className="sr-only"
                                                {...register("insuranceIdfront")} onChange={(e) => {
                                                    insuranceIdfront(e);
                                                }} />

                                            <span>Insurance Front (optional)</span>

                                            <span className='absolute top-0 left-0 w-full h-full object-cover'>
                                                {insuranceid && (
                                                    <img className='h-full w-full rounded-[50px] max-md:rounded-2xl'
                                                        src={URL.createObjectURL(insuranceid)}
                                                        alt='image'></img>)}
                                                <div
                                                    className='close absolute top-[-15px] right-[-15px] w-full h-full z-9 object-cover'>
                                                    <button type="button" onClick={() => removeImage("insuranceid")}>
                                                        <img src={icon_close_bgblack} />
                                                    </button>
                                                </div>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='w-1/2 flex flex-wrap justify-center items-center h-full md:px-4 max-md:w-full bg-light-bg max-md:px-3 max-md:pb-4'>
                            <p className='text-sm text-center mb-4 max-md:sr-only'>
                                This service is only available in Dubai
                            </p>
                            <div className='w-full mb-8 mt-6'>
                                <label className='fw-medium mb-2'>Notes</label>
                                <input type='text' className='bg-white border rounded-xl px-3 w-full'
                                    {...register("notes")}
                                />
                            </div>
                            <div className='flex w-full justify-center gap-6'>
                                <a className='shadow-custom bg-white h-12 w-1/3 inline-flex items-center justify-start rounded-3xl gap-2 px-3 text-xl max-md:rounded-xl'>
                                    <span className='bg-black p-[6px] rounded-md w-8 flex'>
                                        <img src={icon_chat} alt='icon_call' />
                                    </span>
                                    Chat
                                </a>
                                <a
                                    href='tel:800DOCIB'
                                    className='shadow-custom bg-white h-12 w-1/3 inline-flex items-center justify-start rounded-3xl gap-2 px-3 text-xl max-md:rounded-xl'>
                                    <span className='bg-black p-[6px] rounded-md w-8 flex'>
                                        <img src={icon_call} alt='icon_call' />
                                    </span>
                                    Call
                                </a>
                            </div>

                            <input
                                className='h-12 bg-[#41BDDE] rounded-full px-4 mt-16 font-medium text-base text-white min-w-[160px] max-md:w-full max-md:mt-16'
                                type="submit" />

                            {errors.is_insured && <p>{errors.is_insured.message}</p>}
                        </div>
                    </div>

                </form>
            </Container>
        </>
    )
        ;
}

export default PrescriptionForm;
