import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getCategory, getCategoryProduct, getCategoryProductMore } from "../../redux/category";

import "./CategoryPage.scss";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../Common/Loaders/MainLoader";
import BannerSlider from "../Common/BannerSlider/BannerSlider";
import CategoryListing from "../Common/CategoryListing/CategoryListing";
import UserEngagements from "../Common/UserEngagements/UserEngagements";
import SingleBanner from "../Common/SingleBanner/SingleBanner";
import FeaturedListPromotionalBanner from "../Common/FeaturedListPromotionalBanner/FeaturedListPromotionalBanner";
import FeaturedList from "../Common/FeaturedList/FeaturedList";
import TopOffers from "../Common/TopOffers/TopOffers";
import DualBanner from "../Common/DualBanner/DualBanner";
import { Link, useHref } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Product from "../Product/Product";

function CategoryPage({ category }) {
    const dispatch = useDispatch();
    let [page, setPage] = useState(1);
    const fullUrl = useHref();
    const urlSplit = fullUrl.split("/");
    const { categoryData, categoryBannerImageData, loader, loadmore, categoryProducts } =
        useSelector((state) => state.category);
    useEffect(() => {
        dispatch(getCategory(category));
        const requestData = {
            handle_category: category.handle,
            page: page,
            speedy: 0,
            from_price: 0,
            to_price: 3999,
            tag_id: JSON.stringify([]),
            flag: JSON.stringify([]),
            offer_id: JSON.stringify([]),
        };
        dispatch(getCategoryProduct(requestData));
        window.scrollTo(0, 0);
        return;
    }, [category]);

    const breadCrumbFormate = (handle) => {
        const words = handle.split("-");
        const formattedString = words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        return formattedString;
    };
    const loadmoreClick = () => {
        setPage(++page);
        const requestData = {
            category_id: category.id,
            handle_category: category.handle,
            page: page,
            speedy: 0,
            from_price: 0,
            to_price: 3999,
            tag_id: JSON.stringify([]),
            flag: JSON.stringify([]),
            offer_id: JSON.stringify([]),
        };
        dispatch(getCategoryProductMore(requestData));
    };

    return (
        <>
            {loader ? (
                <MainLoader size={50} />
            ) : (
                <Container className="flex flex-col">
                    <Row>
                        <Col className="max-lg:hidden ">
                            <ul className="flex gap-2 py-2 text-xs">
                                {urlSplit.map((split, index) => {
                                    if (index === 0) {
                                        return (
                                            <li>
                                                <Link to={"/"}>
                                                    Home{" "}
                                                    <span>
                                                        <FontAwesomeIcon size="md" icon={faAngleRight} />
                                                    </span>
                                                </Link>
                                            </li>
                                        );
                                    } else if (urlSplit.length - 1 === index) {
                                        return (
                                            <li className="opacity-50">{breadCrumbFormate(split)}</li>
                                        );
                                    } else {
                                        return (
                                            <li>
                                                <Link to={split}>
                                                    {breadCrumbFormate(split)}{" "}
                                                    <span>
                                                        <FontAwesomeIcon size="md" icon={faAngleRight} />
                                                    </span>
                                                </Link>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {categoryBannerImageData.map((element, index) => {
                                return (
                                    <div className="sub_category_title" key={index}>
                                        <span>{element.name}</span>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                    <Row className="gap-y-8">
                        {categoryData.map((element, index) => {
                            if (element.hasOwnProperty("whats_trending") && element.whats_trending.length > 0) {
                                return (
                                    <BannerSlider
                                        sliderData={element.whats_trending}
                                        key={index}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("sub_categories") && element.sub_categories.length > 0) {
                                return (
                                    <CategoryListing
                                        categoryData={element.sub_categories}
                                        key={index}
                                        fromCategory={true}
                                        handle={categoryBannerImageData[0].handle}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("user_engagement") && element.user_engagement.length > 0) {
                                return (
                                    <UserEngagements
                                        userEngagementData={element.user_engagement}
                                        key={index}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("promotional_banner") && element.promotional_banner.length > 0) {
                                return (
                                    <SingleBanner
                                        bannerData={element.promotional_banner}
                                        key={index}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("featured_list_promotional_banner") && element.featured_list_promotional_banner.length > 0) {
                                return (
                                    <FeaturedListPromotionalBanner
                                        data={element.featured_list_promotional_banner}
                                        key={index}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("category_featured_list") && element.category_featured_list.length > 0) {
                                return (
                                    <FeaturedList
                                        featuredListData={element.category_featured_list}
                                        key={index}
                                    />
                                );
                            }
                            if (element.hasOwnProperty("top_collections") && element.top_collections.length > 0) {
                                return <TopOffers collectionData={element.top_collections} />;
                            }
                            if (element.hasOwnProperty("dual_banners") && element.dual_banners.length > 0) {
                                if (element.dual_banners.length > 1) {
                                    return <DualBanner dualBannerData={element.dual_banners} />;
                                }
                                if (element.dual_banners.length === 1) {
                                    return (
                                        <SingleBanner
                                            bannerData={element.dual_banners}
                                            key={index}
                                        />
                                    );
                                } else {
                                    return;
                                }
                            }
                        })}
                        {/* <PharmacyForYou /> */}
                    </Row>
                    <Row>
                        <h1 className="mt-10 p-0 text-center text-base font-medium md:text-[25px] md:font-bold my-6">
                            All Products
                        </h1>
                    </Row>
                    <Row>
                        <Container fluid>
                            <Container className={'categoryproductlisting'}>
                                <Row>
                                    <Col xs={12} md={12} className='product_grid'>
                                        <Row className={'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6'}>
                                            {categoryProducts.map((product, i) => {
                                                return (
                                                    <Col xs={12} md={12} key={i} className='px-0'>
                                                        <Product productData={product} handle={category.handle} />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Row>
                    {loadmore && (
                        <div className="flex mx-auto mt-2 h-auto pt-2 text-xs">
                            <button onClick={loadmoreClick}>Load More</button>
                        </div>
                    )}
                </Container>
            )}
        </>
    );
}

export default CategoryPage;
